//コンポーネントID : o43

import React, { useContext, useEffect, useState } from "react";
import { ContainerSettings } from "../../page/layout/ContainerLayout";
import { ReactComponent as Error} from "../../../assets/error.svg";
import ReSendButton from "../../molecules/ReSendButton";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import account from "../../../reducers/accountSlice";
import axios from "../../../lib/axios";
import { AppSettings } from "../../../App";

export default function RegisterSendAuthError() {

	const {
		setPopWindow,
	} = useContext(AppSettings);

	const {
		setTitle,
		setImage,
		setImageAlt,
		setProgressBarSize,
	} = useContext(ContainerSettings);

    const accountData = useSelector(account.state);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [time, setTime] = useState(null);

	useEffect(() => {
		setProgressBarSize(null);
		setTitle("メールアドレスの認証");
		setImage(<Error className="o43_error_image"/>);
		setImageAlt("メールアドレスの認証");
		if (!accountData.loading) {
			if (accountData.data != null) {
				setTime(accountData.data.email_verify_last_request);
			} else {
				navigate("/login");
			}
		}
	}, [setTitle, setImage, setImageAlt, accountData, navigate,setProgressBarSize]);

    async function onSubmit(e) {
		e.preventDefault();
		try {
			const response = await axios.get("/auth/email/authentication");
            dispatch(account.slice.actions.setData(response.data));
		} catch (error) {
			if (error.response == null) {
				setPopWindow("サーバーから応答がありません。", true);
			} else {
				setPopWindow(error.response.data.error, true);
			}
		}
        dispatch(account.initData());
	}

	return (
		<section className="o33_container">
			<p>
				メールアドレス認証に失敗しました
			</p>
            <ReSendButton
				time={time}
				onClick={onSubmit}
				enableMessage="再送信する場合はこちら"
				disableMessage={(t)=>{return t+"後に再送信できます。"}}
				reSend
			/>
		</section>
	);
}
