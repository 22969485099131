//コンポーネントID : p19
// マークアップ担当者：鶴岡

//ご利用ガイド

import React from 'react';
import Accordion from "react-bootstrap/Accordion";
import ListGroup from 'react-bootstrap/ListGroup';
import Tab from 'react-bootstrap/Tab';
import { Helmet } from 'react-helmet-async';

export default function Guide() {
    // 現在未実装のものについての利用ガイドはコメントアウトしてます

    const guideItems = {
        account: [
            {
                title: "登録情報の確認",
                description: "ページ右上のアイコン > アカウント設定 > 各種設定 から登録情報の確認ができます。",
            },
            {
                title: "登録情報の変更",
                description: "ページ右上のアイコン > アカウント設定 > 各種設定 から登録情報の変更ができます。",
            },
            {
                title: "アイコンの変更",
                description: "ページ右上のアイコン > アカウント設定 > 各種設定 からアイコンをクリックし、変更する画像を選択することでアイコンを変更することができます。初期化したい場合はアイコンの右にある削除ボタンをクリックしてください。",
            },
            {
                title: "ニックネーム・肩書きの変更",
                description: "ページ右上のアイコン > アカウント設定 > 各種設定からニックネームと肩書きを変更できます。ニックネームと肩書きはFaro上でのみ公開されます。",
            },
            // {
            //     title: "パスワード変更",
            //     description: "ページ右上のアイコン > アカウント設定 > 各種設定から登録されたパスワードの変更ができます。登録したメールアドレスにパスワード変更用リンクをお送りしますので、そちらからパスワード変更を行ってください。",
            // },
            // {
            //     title: "メールアドレス変更",
            //     description: "ページ右上のアイコン > アカウント設定 > 各種設定から登録されたメールアドレスの変更ができます。現在登録されているメールアドレスにメールアドレス変更用のリンクをお送りしますので、そちらからメールアドレス変更を行ってください。",
            // },
            {
                title: "SNS連携",
                description: "ページ右上のアイコン > アカウント設定 > 各種設定から各種SNS連携をすることができます。またWebにはデプロイ先のリンクなどを入力します。設定したSNSはマイページに表示されるアイコンをクリックすることでURLに飛ぶことができます。",
            },
            // {
            //     title: "スカウト機能",
            //     description: "メールアドレスの認証をすると利用でき、Faroと提携している企業からスカウトが届くようになる機能です。",
            // },
            // {
            //     title: "スカウト受信設定",
            //     description: "ページ右上のアイコン > アカウント設定 > スカウト設定からスカウトの受信可否を設定できます。",
            // },
            {
                title: "ログアウト",
                description: "ページ右上のアイコン > ログアウトを選択するか、 アカウント設定 > アカウントからログアウトすることができます。",
            },
            // {
            //     title: "アカウント削除",
            //     description: "ページ右上のアイコン > アカウント設定 > アカウントからアカウントを削除できます。またアカウントを一度削除した場合復元することができません。",
            // },
        ],
        project: [
            {
                title: "プロジェクトの作成",
                description: "プロジェクトを作成したい場合は、ヘッダーのプロジェクト作成をクリックするか、マイページにて作成・追加ボタンをクリックしてください。プロジェクトには『詳細』『概要』『設定』があり、各必須項目を入力して作成ボタンをクリックするとプロジェクトが作成・保存されます。",
            },
            {
                title: "プロジェクトの編集",
                description: "プロジェクトの詳細画面の右上にある編集ボタンから、プロジェクトを編集することができます。",
            },
            {
                title: "プロジェクトの削除",
                description: "プロジェクトの編集ページからページ下部にある削除ボタンから削除することができます。またプロジェクトを削除した場合、復元することはできず、ポートフォリオからも削除されます。",
            },
            {
                title: "プロジェクトの詳細",
                description: "プロジェクトの使用言語や技術、制作開始日など詳細な情報を確認できます。",
            },
        ],
        portfolio: [
            {
                title: "ポートフォリオ",
                description: "Faroでは作成したプロジェクトをポートフォリオに追加することができます。プロジェクト以外にも、自己PRや資格・使用言語など、ポートフォリオに必要な情報を追加することが可能です。",
            },
            {
                title: "ポートフォリオの編集",
                description: "自己PRや資格情報などを編集したい場合は、右上の編集ボタンをクリックしてください。また、編集時にプロジェクトを削除するとプロジェクト一覧からは削除されずに、ポートフォリオから削除することができます。",
            },
            {
                title: "プロジェクトの追加方法",
                description: "プロジェクト作成時にポートフォリオの公開設定をONにして作成すれば自動的にポートフォリオに追加されます。もしくは点線のプロジェクトからポートフォリオにプロジェクトから選択して追加か新たに作成できます。",
            },
            {
                title: "公開設定",
                description: "作成したプロジェクトの設定から「ポートフォリオに公開する」を公開する設定にしたプロジェクトが、マイページのポートフォリオに追加されます。公開をしない設定にするとポートフォリオには追加されません。",
            },
        ],
        other: [
            {
                title: "Faro",
                description: "Faroはユーザーが過去制作してきたプロジェクトをまとめ、手軽にポートフォリオにすることができるプロジェクト・ポートフォリオ共有アプリケーションです。",
            },
            {
                title: "お問い合わせ",
                description: "ヘッダーのお問い合わせより、必要事項をご記入の上送信してください。",
            },
            {
                title: "利用規約",
                description: "ヘッダーの利用規約より、ご確認ください。",
            },
            {
                title: "プライバシーポリシー",
                description: "ヘッダーのプライバシーポリシーより、ご確認ください。",
            },
        ],
    }

    return (
        <>
            <Helmet>
                <title>ご利用ガイド｜Faro</title>
            </Helmet>
            <h1 className="p19_title w-100 mb-3 fw-bold text-center">ご利用ガイド</h1>
            <Tab.Container defaultActiveKey="#account">
                <div className='p19_wrapper d-flex flex-column flex-md-row justify-content-center'>
                    <ListGroup className='mb-4 mb-md-0' as="ul">
                        <ListGroup.Item className='fw-bold p-0' as="li">目次</ListGroup.Item>
                        <div className='gap-3 d-flex flex-column flex-sm-row flex-md-column text-left text-sm-center text-md-start'>
                            <ListGroup.Item className='fw-bold p-0' as="li" action href="#account">アカウントの管理</ListGroup.Item>
                            <ListGroup.Item className='fw-bold p-0' as="li" action href="#project">プロジェクト</ListGroup.Item>
                            <ListGroup.Item className='fw-bold p-0' as="li" action href="#portfolio">ポートフォリオ</ListGroup.Item>
                            <ListGroup.Item className='fw-bold p-0' as="li" action href="#other">その他</ListGroup.Item>
                        </div>
                    </ListGroup>
                    <Tab.Content className='p19_tab_content'>
                        <Tab.Pane eventKey="#account">
                            <Accordion className="p19_accordion_wrapper bg-transparent">
                                {guideItems.account.map((item, index) => (
                                    <Accordion.Item className="bg-transparent" key={index} eventKey={index.toString()}>
                                        <Accordion.Header>{item.title}</Accordion.Header>
                                        <Accordion.Body>{item.description}</Accordion.Body>
                                    </Accordion.Item>
                                ))}
                            </Accordion>
                        </Tab.Pane>
                        <Tab.Pane eventKey="#project">
                            <Accordion className="p19_accordion_wrapper bg-transparent">
                                {guideItems.project.map((item, index) => (
                                    <Accordion.Item className="bg-transparent" key={index} eventKey={index.toString()}>
                                        <Accordion.Header>{item.title}</Accordion.Header>
                                        <Accordion.Body>{item.description}</Accordion.Body>
                                    </Accordion.Item>
                                ))}
                            </Accordion>
                        </Tab.Pane>
                        <Tab.Pane eventKey="#portfolio">
                            <Accordion className="p19_accordion_wrapper bg-transparent">
                                {guideItems.portfolio.map((item, index) => (
                                    <Accordion.Item className="bg-transparent" key={index} eventKey={index.toString()}>
                                        <Accordion.Header>{item.title}</Accordion.Header>
                                        <Accordion.Body>{item.description}</Accordion.Body>
                                    </Accordion.Item>
                                ))}
                            </Accordion>
                        </Tab.Pane>
                        <Tab.Pane eventKey="#other">
                            <Accordion className="p19_accordion_wrapper bg-transparent">
                                {guideItems.other.map((item, index) => (
                                    <Accordion.Item className="bg-transparent" key={index} eventKey={index.toString()}>
                                        <Accordion.Header>{item.title}</Accordion.Header>
                                        <Accordion.Body>{item.description}</Accordion.Body>
                                    </Accordion.Item>
                                ))}
                            </Accordion>
                        </Tab.Pane>
                    </Tab.Content>
                </div>
            </Tab.Container>
        </>
    )
}
