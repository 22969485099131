//コンポーネントID : o5
// マークアップ担当者：鶴岡

import React, { useContext, useEffect } from "react";
import Mail from "../../assets/mail.svg";
import { ContainerSettings } from "../page/layout/ContainerLayout";
import SingleMenu from "../atom/template/SingleMenu";
import { Button } from "react-bootstrap";
import axios from "../../lib/axios";
import { AppSettings } from "../../App";
import { useDispatch } from "react-redux";
import account from "../../reducers/accountSlice";

export default function EmailUpdate({email, setEmail, nextPage}) {
    const dispatch = useDispatch();
    const {
		setPopWindow,
	} = useContext(AppSettings);
    const { setTitle, setImage, setImageAlt, setProgressBarSize } =
        useContext(ContainerSettings);
    useEffect(() => {
        setTitle("メールアドレス変更");
        setImage(Mail);
        setImageAlt("メールアイコン");
        setProgressBarSize(null);
    }, [setTitle, setImage, setImageAlt, setProgressBarSize]);
    async function mailSend(e) {
        e.preventDefault();
        try {
            const response = await axios.post("/auth/email/reset", {
                "email" : email
            });
            dispatch(account.slice.actions.setData(response.data));
            nextPage();
        } catch (error) {
            if (error.response == null) {
				setPopWindow("サーバーから応答がありません。", true);
			} else {
				setPopWindow(error.response.data.error, true);
			}
        }
    }
    return (
        <form onSubmit={mailSend}>
            <p className="o5_p text-left mb-0">
                新しいメールアドレスに認証メールを送信します。以下に必要事項を記入し送信してください
            </p>
            <SingleMenu required text="新しいメールアドレス" />
            <input className="o5_input" type="email" name="" id="" onChange={(e) => setEmail(e.target.value)} />
            <Button className="o5_button" type="submit">
                送信
            </Button>
        </form>
    );
}
