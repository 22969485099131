import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import listing from "../reducers/listingSlice";
import account from "../reducers/accountSlice";

export default function Init({ loading, setLoading }) {
    const dispatch = useDispatch();
    // const navigate = useNavigate();

    const loadMethod = useMemo(() => [listing, account], []);
    const listingData = useSelector(listing.state);
    const accountData = useSelector(account.state);
    const datas = useMemo(() =>
        [listingData, accountData],
        [listingData, accountData]
    );

    useEffect(() => {
        for (const slice of loadMethod) {
            dispatch(slice.initData());
        }
    }, [dispatch, loadMethod]);

    useEffect(() => {
        function checkInitError() {
            let load = false;
            let error = false;
            for (const reduxData of datas) {
                if ((typeof reduxData.loading) !== "boolean") continue;
                if (reduxData.loading) {
                    load = true;
                }
                else if (reduxData.error !== null) {
                    error = true;
                }
            }
            if (!load) {
                if (error) {
                    // navigate("/error");
                }
                setLoading(load);
            }
        }

        if (loading) {
            checkInitError();
        }
    }, [loading, datas, setLoading]);

    return <></>;
}