//コンポーネントID : m4
//マークアップ担当
import { Spinner } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Form from "react-bootstrap/Form";
import { useSelector } from "react-redux";
import listing from "../../reducers/listingSlice";
import SingleMenu from "../atom/template/SingleMenu";
import DropImage from "./DropImage";
import RadioButton from "./radio/RadioButton";
import SingleSelect from "./select/SingleSelect";
import StackSelect from "./select/StackSelect";
import { useState } from "react";
import DeleteProjectModal from "../organisms/modal/DeleteProjectModal";

export default function CreateProject({ body, setBody, onNextTab, img, onSend, isEdit }) {

    const listingData = useSelector(listing.state).data;
    const [openModal, setOpenModal] = useState(false);

    function handleSelectChange(name, value) {
        if (name === 'developers' && value < 0) {
            setBody({
                ...body,
                [name]: 1
            });
        } else if (name === 'created_at_project' && value === "T00:00:00") {
            setBody({
                ...body,
                [name]: null
            });
        } else {
            setBody({
                ...body,
                [name]: value
            });
        }
    };

    function scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    function getOptions(list) {
        return list.map(item => ({
            id: item.id,
            name: item.name,
            value: item.name,
            label: item.name
        }));
    }

    if (!listingData) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <Spinner animation="border" />
            </div>
        );
    }

    function handleFocus(name, value) {
        console.log("out if:", name);
        if (value === '0' || value === '') {
            setBody({
                ...body,
                [name]: 1
            });
        }
    }

    return (
        <Form
            className="m4_wrapper"
            onSubmit={(e) => { e.preventDefault() }}
        >
            <Form.Group className="m4_input_label_wrapper flex-lg-row" >
                <div className="m4_input_label mb-lg-0" >
                    <SingleMenu text="サムネイル" />
                </div>
                <DropImage
                    image={img.thumbnail}
                    setImage={img.setThumbnail}
                />
            </Form.Group>
            <Form.Group className="m4_input_label_wrapper flex-sm-row" >
                <div className="m4_input_label mb-sm-0" >
                    <SingleMenu
                        required
                        text="プロジェクト名"
                    />
                </div>
                <Form.Control
                    name="project_name"
                    className={(body.project_name != null && body.project_name === "" ? " error" : "")}
                    defaultValue={body["project_name"]}
                    type="text"
                    placeholder="プロジェクト名を入力"
                    onChange={(e) => handleSelectChange(e.target.name, e.target.value)}
                    required
                />
            </Form.Group>
            <Form.Group className="m4_input_label_wrapper flex-sm-row">
                <div className="m4_input_label mb-sm-0">
                    <SingleMenu text="ジャンル" />
                </div>
                <SingleSelect
                    name="genre_name"
                    options={getOptions(listingData.genres)}
                    defaultValue={body["genre_name"]}
                    onChange={(value) => handleSelectChange("genre_name", value)}
                />
            </Form.Group>
            <Form.Group className="m4_input_label_wrapper flex-sm-row">
                <div className="m4_input_label mb-sm-0">
                    <SingleMenu text="進捗状況" />
                </div>
                <div>
                    <RadioButton
                        options={listingData.project_progresses}
                        name="progress"
                        onChange={(e, index) => handleSelectChange("progress", index)}
                        defaultValue={body["progress"]}
                    />
                </div>

            </Form.Group>
            <Form.Group className="m4_input_label_wrapper flex-sm-row">
                <div className="m4_input_label mb-sm-0">
                    <SingleMenu text="使用言語" />
                </div>
                <StackSelect
                    name="languages"
                    options={getOptions(listingData.languages)}
                    defaultValue={body["languages"]}
                    onChange={(value) => handleSelectChange("languages", value)}
                />
            </Form.Group>
            <Form.Group className="m4_input_label_wrapper flex-sm-row">
                <div className="m4_input_label mb-sm-0">
                    <SingleMenu text="担当箇所" />
                </div>
                <Form.Control
                    name="responsibility"
                    defaultValue={body["responsibility"]}
                    type="text"
                    onChange={(e) => handleSelectChange("responsibility", e.target.value)}
                />
            </Form.Group>
            <Form.Group className="m4_input_label_wrapper flex-sm-row">
                <div className="m4_input_label mb-sm-0">
                    <SingleMenu text="使用技術" />
                </div>
                <StackSelect
                    name="technologies"
                    onChange={(value) => handleSelectChange("technologies", value)}
                    defaultValue={body["technologies"]}
                    options={getOptions(listingData.technologies)}
                />
            </Form.Group>
            {/* <Form.Group className="m4_input_label_wrapper d-flex m-auto">
                <div className="m4_input_label">
                    <SingleMenu text="Github URL" />
                </div>
                <Form.Control
                    name="github_link"
                    defaultValue={body["github_link"]}
                    type="url"
                    placeholder="例) https://github.com/user/example-repository"
                    onChange={(e) => handleSelectChange(e.target.name, e.target.value)}
                />
            </Form.Group> */}
            {/* <Form.Group className="m4_input_label_wrapper d-flex m-auto">
                <div className="m4_input_label">
                    <SingleMenu text="アプリ URL" />
                </div>
                <Form.Control
                    name="deploy_link"
                    defaultValue={body["deploy_link"]}
                    type="url"
                    placeholder="例) https://example.com"
                    onChange={(e) => handleSelectChange(e.target.name, e.target.value)}
                />
            </Form.Group> */}
            <Form.Group className="m4_input_label_wrapper flex-sm-row">
                <div className="m4_input_label mb-sm-0">
                    <SingleMenu text="開発人数" />
                </div>
                <div className="m4_developers_wrapper">
                    <Form.Control
                        name="developers"
                        value={body["developers"]}
                        type="number"
                        min="1"
                        className="m4_input_number_field"
                        onChange={(e) => handleSelectChange(e.target.name, e.target.value)}
                        onBlur={(e) => handleFocus(e.target.name, e.target.value)}
                    />
                    <p className="m4_input_number_value">人</p>
                </div>

            </Form.Group>
            <Form.Group className="m4_input_label_wrapper flex-sm-row">
                <div className="m4_input_label mb-sm-0">
                    <SingleMenu text="制作開始日" />
                </div>
                <Form.Control
                    name="created_at_project"
                    defaultValue={body["created_at_project"]?.split("T00:00:00")[0]}
                    type="date"
                    className="m4_input_date_field"
                    onChange={(e) => handleSelectChange(e.target.name, e.target.value + "T00:00:00")}
                />
            </Form.Group>
            <div className={isEdit ? "d-flex justify-content-evenly" : ""}>
                {isEdit ?
                    <>
                        <Button variant="outline-danger" className="m4_button_edit m4_button_del" onClick={() => setOpenModal(true)}>削除</Button>
                        <DeleteProjectModal open={openModal} setOpen={setOpenModal} onSend={onSend} />
                    </>
                    : null}
                <Button className={isEdit ? "m4_button m4_button_edit" : "m4_button"} onClick={() => { onNextTab(1); scrollToTop(); }}>次へ</Button>
            </div>
        </Form>
    );
}
