//コンポーネントID : m24
// マークアップ作成者：鶴岡

import React, { useContext, useState } from "react";
import SingleMenu from "../atom/template/SingleMenu";
import { ReactComponent as Delete } from "../../assets/delete.svg";
import { Button, InputGroup, ListGroup, Form } from "react-bootstrap";
import { UserPageItems } from "../page/UserPage";
import EditableList from "./EditableList";
import { PortfolioItems } from "../organisms/userpage/Portfolio";

export default function UserKnowledge() {
    const { isEdit } = useContext(UserPageItems);
    const { updateMyPageData, setUpdateMyPageData } = useContext(PortfolioItems);
    //newが付くStateは新しく追加する項目が入る
    const [newQualificationName, setNewQualificationName] = useState("");
    const [newLanguageName, setNewLanguageName] = useState("");
    const [newTechnologyName, setNewTechnologyName] = useState("");
    // 特定の項目を削除する関数
    const handleDelete = (index, skill, skillType) => {
        const deleteItems = skill.filter((_, i) => i !== index);
        setUpdateMyPageData({ ...updateMyPageData, [skillType]: deleteItems });
    };

    // 特定の項目を更新する関数
    const handleUpdate = (index, newName, skill, skillType) => {
        const updatedItems = skill.map((item, i) =>
            i === index ? { ...item, name: newName } : item
        );
        setUpdateMyPageData({ ...updateMyPageData, [skillType]: updatedItems });
    };

    // 新しい項目を追加する関数
    function handleAddItemName(e, newName, setNewName, items, key) {
        const isEnterKey = e.type === 'keydown' && (e.keyCode === 13 || e.key === "Enter");
        const isClickEvent = e.type === 'click';
        if ((isEnterKey || isClickEvent) && newName.trim() !== "") {
            setUpdateMyPageData(prevData => ({
                ...prevData,
                [key]: [...items, { name: newName.trim() }]
            }));
            setNewName(""); // inputをクリア
        }
    }

    return (
        <ListGroup variant="flush" className="m24_listGroup">
            {/* 資格 */}
            <ListGroup.Item>
                <SingleMenu text="資格" />
                <div className="m24_status_box">
                    {updateMyPageData.qualifications.map((q, index) => (
                        isEdit ? (
                            <InputGroup key={index} className="mb-1">
                                <Form.Control
                                    type="search"
                                    value={q.name}
                                    onChange={(e) =>
                                        handleUpdate(
                                            index,
                                            e.target.value,
                                            updateMyPageData.qualifications,
                                            "qualifications"
                                        )
                                    }
                                />
                                <Button
                                    onClick={() =>
                                        handleDelete(index, updateMyPageData.qualifications,
                                            "qualifications")
                                    }>
                                    <Delete />
                                </Button>
                            </InputGroup>
                        ) : (
                            <p key={index} className="mb-1">
                                {q.name}
                            </p>
                        )
                    ))}

                    {isEdit && ( //新しい項目の追加
                        <InputGroup className="mb-1">
                            <Form.Control
                                type="text"
                                value={newQualificationName}
                                onKeyDown={(e) => handleAddItemName(e, newQualificationName, setNewQualificationName, updateMyPageData.qualifications, "qualifications")}
                                onChange={(e) => setNewQualificationName(e.target.value)}
                                placeholder="新しい項目"
                            />
                            <Button
                                onClick={(e) => handleAddItemName(e, newQualificationName, setNewQualificationName, updateMyPageData.qualifications, "qualifications")}
                                className="m24_new_button">
                                {"+"}
                            </Button>
                        </InputGroup>
                    )}
                </div>
            </ListGroup.Item>
            {/* 言語 */}
            <ListGroup.Item>
                <SingleMenu text="言語" />
                <EditableList
                    items={updateMyPageData.languages}
                    newItemName={newLanguageName}
                    setNewItemName={setNewLanguageName}
                    isEdit={isEdit}
                    handleAddItemName={(e) => handleAddItemName(e, newLanguageName, setNewLanguageName, updateMyPageData.languages, "languages")}
                    handleUpdate={(index, value) =>
                        handleUpdate(index, value, updateMyPageData.languages, "languages")
                    }
                    handleDelete={(index) =>
                        handleDelete(index, updateMyPageData.languages, "languages")
                    }
                />
            </ListGroup.Item>
            {/* 技術 */}
            <ListGroup.Item>
                <SingleMenu text="得意技術" />
                <EditableList
                    items={updateMyPageData.technologies}
                    newItemName={newTechnologyName}
                    setNewItemName={setNewTechnologyName}
                    isEdit={isEdit}
                    handleAddItemName={(e) => handleAddItemName(e, newTechnologyName, setNewTechnologyName, updateMyPageData.technologies, "technologies")}
                    handleUpdate={(index, value) =>
                        handleUpdate(index, value, updateMyPageData.technologies, "technologies")
                    }
                    handleDelete={(index) =>
                        handleDelete(index, updateMyPageData.technologies, "technologies")
                    }
                />
            </ListGroup.Item>
        </ListGroup>
    );
}
