//コンポーネントID : m42
//単体のセレクト
//マークアップ：長谷川

import Select from "react-select";

export default function SingleSelect({ name, options, defaultValue, onChange, noSelect }) {
    const formattedOptions = options.map(option => ({
        value: option.name,
        label: option.name
    }));
    if (noSelect) {
        options = [{ value: "", label: "選択しない" }, ...options];
    }
    function onUpdated(e) {
        onChange(e.value);
    }
    return (
        <Select
            name={name}
            className="m42_select_wrapper"
            options={formattedOptions}
            placeholder=""
            components={{
                IndicatorSeparator: () => null,
            }}
            defaultValue={defaultValue ? [defaultValue].map(option => ({ value: option, label: option })) : null}
            isSearchable={false}
            onChange={onUpdated}
        />
    );
}
