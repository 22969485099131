//コンポーネントID : o16

import { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
// import { ReactComponent as ArrowRightTriangle } from '../../assets/arrow_right_triangle.svg';
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import axios from "../../lib/axios.js";
import listing from "../../reducers/listingSlice.jsx";
import SwiperGenreProjectCardList from "../molecules/list/SwiperGenreProjectCardList.jsx";
import SwiperHeroProjectCardList from "../molecules/list/SwiperHeroProjectCardList.jsx";
import SwiperProfileList from "../molecules/list/SwiperProfileList.jsx";
import SwiperProjectCardList from "../molecules/list/SwiperProjectCardList.jsx";

export default function MainContent() {
    const [heroProjectDatum, setHeroProjectDatum] = useState([])
    const [userDatum, setUserDatum] = useState([])
    const [newProjectDatum, setNewProjectDatum] = useState([])
    const [genre, setGenre] = useState([])
    const [genreProjectDatum, setGenreProjectDatum] = useState([])
    const genreDatum = useSelector(listing.state);

    useEffect(() => {
        async function fetchData() {
            try {
                //データ取得
                const mainDatumLayer = await axios.get("/api/main")
                const mainDatum = mainDatumLayer.data

                //メインビュープロジェクト取得
                const heroProject = await axios.post("/api/projects", mainDatum.projectByTopFavorite.projects)
                setHeroProjectDatum(heroProject.data)

                //ユーザー取得
                const userIdDatum = await axios.post("/api/users", mainDatum.userList.users)
                setUserDatum(userIdDatum.data)

                //新着プロジェクト取得
                const toNewProject = await axios.post("/api/projects", mainDatum.projectByNew.projects)
                const toNewProjectTag = await axios.post("/api/projects/tags", mainDatum.projectByNew.projects)
                toNewProject.data.forEach(project => {
                    const tagData = toNewProjectTag.data.find(tag => tag.id === project.id)
                    if (tagData) {
                        project.languages = tagData.languages
                        project.technologies = tagData.technologies
                    }
                })
                setNewProjectDatum(toNewProject.data)

                //ジャンルごとのプロジェクト取得
                const flattenedIds = Object.values(mainDatum.projectByGenre).filter(value => Array.isArray(value)).flatMap(value => value.map(item => item.id));
                const genreProjectTag = await axios.post("/api/projects/tags", flattenedIds);
                const toTopGenreProjectWithTags = Object.keys(mainDatum.projectByGenre).reduce((acc, key) => {
                    const projects = [].concat(mainDatum.projectByGenre[key]);
                    acc[key] = projects.map(project => {
                        const tagData = genreProjectTag.data.find(tag => tag.id === project.id);
                        if (tagData) {
                            project.technologies = tagData.technologies;
                            project.languages = tagData.languages;
                        }
                        return project;
                    });
                    return acc;
                }, {});
                setGenreProjectDatum(toTopGenreProjectWithTags)

            } catch (error) {
                console.error(error)
            }
        }
        fetchData()
    }, [])

    useEffect(() => {
        if (genreDatum.data != null && genreProjectDatum != null) {
            const filteredGenres = genreDatum.data.genres.filter(genre => genreProjectDatum.hasOwnProperty(`genre_id:${genre.id}`));
            setGenre(filteredGenres)
        }
    }, [genreDatum.data, genreProjectDatum])

    return (
        <>
            <Helmet>
                <title>Faro｜ホーム</title>
            </Helmet>
            <SwiperHeroProjectCardList projectDatum={heroProjectDatum} />
            <SwiperProfileList userDatum={userDatum} />
            <section className="p7_new_project_wrapper w-100 m-auto overflow-hidden">
                {newProjectDatum.length > 0 && <p className="text-center mb-4">新着プロジェクト</p>}
                <SwiperProjectCardList projectDatum={newProjectDatum} newProject />
                {/* <Link className="p7_more_btn px-4 py-2 rounded-pill d-flex align-items-center">もっと見る<ArrowRightTriangle /></Link> */}
            </section>
            {/* <img src="" alt="広告バナー" className="p7_advert_image m-auto"></img> */}
            <section className="p7_category_project_wrapper w-100">
                <SwiperGenreProjectCardList projectDatum={genreProjectDatum} genreDatum={genre} />
            </section>
        </>
    );
}
