//コンポーネントID : p2
// マークアップ担当者：鶴岡

//パスワードの再設定用URLを送るページの管理

import React, { useContext, useEffect, useState } from "react";
import SendPasswordURL from "../organisms/password/SendPasswordURL";
import { ContainerSettings } from "./layout/ContainerLayout";
import RegisterSendEmail from "../organisms/register/RegisterSendEmail";
import { Helmet } from "react-helmet-async";

export default function ForgetPassword() {
    const { page, setPage, setProgress, setProgressBarSize } =
        useContext(ContainerSettings);

    useEffect(() => {
        setProgressBarSize(null);
        setProgress(0);
    }, []);

    const [email, setEmail] = useState("");
    const [time, setTime] = useState(null);
    const [isAutoSend, setIsAutoSend] = useState(null);

    useEffect(() => {
        console.log(page);
    }, [page])

    function getPage() {
        switch (page) {
            // クリックごとにページ移動
            case 0: //パスワード再設定用メールの送信ページ
                return <SendPasswordURL isSend={false} email={email} setEmail={setEmail} time={time} setTime={setTime} isAutoSend={isAutoSend} setIsAutoSend={setIsAutoSend} />;
            case 1: //メール送信完了
                return <SendPasswordURL isSend={true} email={email} setEmail={setEmail} time={time} setTime={setTime} isAutoSend={isAutoSend} setIsAutoSend={setIsAutoSend} />;
            case 2: //メール再送信
                // return <RegisterSendEmail />;
            default:
                return null;
        }
    }
    return (
        <>
            <Helmet>
                <title>パスワード再登録｜Faro</title>
            </Helmet>
            {getPage()}
        </>
    );
}
