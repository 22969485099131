//コンポーネントID : m47
import React from 'react'
import { Button, Col, Modal } from 'react-bootstrap'
import { ReactComponent as ProjectList } from "../../../assets/projectcard_list.svg";
import { ReactComponent as ProjectCard } from "../../../assets/projectcard.svg";
import { ReactComponent as Arrow } from "../../../assets/arrowright.svg";
import { ReactComponent as AddProject } from "../../../assets/add_project.svg";

export default function SelectAddTypeModalBody({ setCreateModal, Listopen }) {
    return (
        <Modal.Body className="m47_modal_body d-flex row">
            <Col className="m47_create text-center">
                <div className="m47_svg">
                    <AddProject width={120} height={120} />
                </div>
                <Button className="m47_link btn" onClick={() => setCreateModal(true)}>プロジェクト作成</Button>
            </Col>
            <div className="m47_line"></div>
            <Col className="m47_add text-center">
                <div className="m47_svg">
                    <ProjectList width={120} height={120} />
                    <Arrow width={60} height={60} />
                    <ProjectCard width={75} height={120} />
                </div>
                <Button className="m47_btn" onClick={Listopen}>プロジェクトリストから追加</Button>
            </Col>
        </Modal.Body>
    )
}
