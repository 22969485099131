//コンポーネントID : m49
import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import ProjectCardList from "../list/ProjectCardList";
import axios from "../../../lib/axios";
import { ReactComponent as Artifactdelete } from "../../../assets/scan_delete.svg";
import { AppSettings } from "../../../App";
import { PortfolioItems } from "../../organisms/userpage/Portfolio";
import { UserPageItems } from "../../page/UserPage";

export default function ProjectListModal() {
    // 特定の項目を更新する関数
    const { projectList, updateMyPageData, setUpdateMyPageData } = useContext(PortfolioItems);
    const { setUserPageData } = useContext(UserPageItems);
    const { setPopWindow } = useContext(AppSettings);

    const [addProjectList, setAddProjectList] = useState();

    useEffect(() => {
        //projectListからすでにportfolioにあるものを抜いた配列を作る
        const filterProjects = projectList.filter((project) => project.position_index === -1 &&
            !updateMyPageData.projects.some((portfolioProject) => portfolioProject.id === project.id)).sort((a, b) => b.id - a.id);
        setAddProjectList(filterProjects);

    }, [updateMyPageData.projects, projectList]);

    //portfolioにプロジェクトを追加するメソッド
    async function onClickProject(addProject) {
        try {
            await axios.post("/api/mypage/edit", { "project_ids": [addProject, ...updateMyPageData.projects].map(item => item.id) })
        } catch (error) {
            setPopWindow(error.response.data.error, true);
            return false;
        }
        //追加するプロジェクトのposition_index
        const positionIndex = 0;
        const updatedPositionIndex = projectList.map((project) => {
            if (project.id === addProject.id) {
                return { ...addProject, position_index: positionIndex };
            }
            return project;
        });
        setUserPageData(userPageData => ({
            ...userPageData,
            projects: [ ...updatedPositionIndex ]
        }));
        const newProjectList = [...updateMyPageData.projects, addProject];
        setUpdateMyPageData({
            ...updateMyPageData,
            projects: newProjectList,
        });

        const filterProjects = projectList.filter((p) => p !== addProject).sort((a, b) => b.id - a.id);
        setAddProjectList(filterProjects);
    }

    return (
        <Modal.Body className={`m49_modal_body ${!addProjectList?.length ? "d-flex justify-content-center" : ""}`}>
            {!addProjectList?.length ? (
                <div className="text-center my-auto">
                    <div className="m49_svg">
                        <Artifactdelete width={120} height={120} />
                    </div>
                    <h5>追加するプロジェクトがありません</h5>
                </div>
            ) : (
                <ProjectCardList projects={addProjectList} onClickProject={onClickProject} />
            )}
        </Modal.Body>
    )
}
