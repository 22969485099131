//コンポーネントID : p25
// マークアップ担当者：鶴岡

//プロジェクト詳細画面

import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ReactComponent as Settings } from "../../../assets/settings.svg"
// import { ReactComponent as Like } from "../../../assets/like.svg"
// import { ReactComponent as BookMark } from "../../../assets/bookmark.svg"
// import { ReactComponent as Share } from "../../../assets/share.svg"
// import ShareModal from "../../organisms/modal/ShareModal";
// import { ReactComponent as Deploy } from "../../../assets/deploy.svg"
// import { ReactComponent as Github } from "../../../assets/github.svg"

import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { TRANSFORMERS } from "@lexical/markdown";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { ImageNode } from "../../../components/molecules/editor/ImageNode";
import Calendar from "../../../assets/calendar.svg";
import Responsibility from "../../../assets/responsibility.svg";
import Tag from "../../../assets/tag.svg";
import Users from "../../../assets/users.svg";
import axios from "../../../lib/axios";
import { Col, ListGroup, Spinner } from "react-bootstrap";
import SingleMenu from "../../atom/template/SingleMenu";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import CodeHighlightPlugin from "../../molecules/editor/CodeHighlightPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import AutoLinkPlugin from "../../molecules/editor/AutoLinkPlugin";
import ListMaxIndentLevelPlugin from "../../molecules/editor/ListMaxIndentLevelPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import ImageInsertPlugin from "../../molecules/editor/ImageInsertPlugin";
import { ImageRegister } from "../../molecules/editor/ImageRegister";
import MoveSelectedNodes from "../../molecules/editor/MoveSelectedNodes";
import { useSelector } from "react-redux";
import account from "../../../reducers/accountSlice";
import { Helmet } from "react-helmet-async";

export default function Project() {

    const { id } = useParams();
    const accountData = useSelector(account.state);
    // const [openModal, setOpenModal] = useState(false);
    // const [liked, setLiked] = useState(false);
    // const handleLike = () => {
    //     setLiked(!liked)
    // }
    // const [bookmarked, setBookmarked] = useState(false);
    // const handleBookmark = () => {
    //     setBookmarked(!bookmarked)
    // }

    const [projectData, setProjectData] = useState(null);
    const [projectCreatedUser, setProjectCreatedUser] = useState(null);

    useEffect(() => {
        async function getProject() {
            try {
                const respProjectData = await axios.get(`/api/project/${id}`);
                const user_id = respProjectData.data.user_id;
                const respUserData = await axios.get(`/api/user/${user_id}`);
                setProjectCreatedUser(respUserData.data);
                const projectTagsData = await axios.post(`/api/projects/tags`, [id]);
                const tagData = projectTagsData.data.find(tag => tag.id == id);
                if (tagData) {
                    respProjectData.data.languages = tagData.languages;
                    respProjectData.data.technologies = tagData.technologies;
                }
                setProjectData(respProjectData.data);
            } catch (error) {

            }
        }

        getProject();
    }, [id]);

    function formatDate(dateString) {
        const date = new Date(dateString);
        return date.toLocaleDateString('ja-JP', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        });
    }

    const exampleTheme = {
        ltr: "ltr",
        rtl: "rtl",
        placeholder: "editor-placeholder",
        paragraph: "editor-paragraph",
        quote: "editor-quote",
        heading: {
            h1: "editor-heading-h1",
            h2: "editor-heading-h2",
            h3: "editor-heading-h3",
            h4: "editor-heading-h4",
            h5: "editor-heading-h5"
        },
        list: {
            nested: {
                listitem: "editor-nested-listitem"
            },
            ol: "editor-list-ol",
            ul: "editor-list-ul",
            listitem: "editor-listitem"
        },
        image: "editor-image",
        link: "editor-link",
        text: {
            bold: "editor-text-bold",
            italic: "editor-text-italic",
            overflowed: "editor-text-overflowed",
            hashtag: "editor-text-hashtag",
            underline: "editor-text-underline",
            strikethrough: "editor-text-strikethrough",
            underlineStrikethrough: "editor-text-underlineStrikethrough",
            code: "editor-text-code"
        },
        code: "editor-code",
        codeHighlight: {
            atrule: "editor-tokenAttr",
            attr: "editor-tokenAttr",
            boolean: "editor-tokenProperty",
            builtin: "editor-tokenSelector",
            cdata: "editor-tokenComment",
            char: "editor-tokenSelector",
            class: "editor-tokenFunction",
            "class-name": "editor-tokenFunction",
            comment: "editor-tokenComment",
            constant: "editor-tokenProperty",
            deleted: "editor-tokenProperty",
            doctype: "editor-tokenComment",
            entity: "editor-tokenOperator",
            function: "editor-tokenFunction",
            important: "editor-tokenVariable",
            inserted: "editor-tokenSelector",
            keyword: "editor-tokenAttr",
            namespace: "editor-tokenVariable",
            number: "editor-tokenProperty",
            operator: "editor-tokenOperator",
            prolog: "editor-tokenComment",
            property: "editor-tokenProperty",
            punctuation: "editor-tokenPunctuation",
            regex: "editor-tokenVariable",
            selector: "editor-tokenSelector",
            string: "editor-tokenSelector",
            symbol: "editor-tokenProperty",
            tag: "editor-tokenProperty",
            url: "editor-tokenOperator",
            variable: "editor-tokenVariable"
        }
    };

    const editorConfig = {
        editorState: projectData?.overview,
        theme: exampleTheme,
        editable: false,
        onError(error) {
            throw error;
        },
        nodes: [
            HeadingNode,
            ListNode,
            ListItemNode,
            QuoteNode,
            CodeNode,
            CodeHighlightNode,
            TableNode,
            TableCellNode,
            TableRowNode,
            AutoLinkNode,
            LinkNode,
            ImageNode,
        ]
    };

    if (!projectData || !projectCreatedUser || accountData.Loading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <Spinner animation="border" />
            </div>
        );
    };

    return (
        <>
            <Helmet>
                <title>{projectData?.project_name}｜Faro</title>
            </Helmet>
            <div className="p25_head_content w-100 d-flex justify-content-between align-items-center">
                <Link to={`/user/${projectData?.user_id}/portfolio`}>
                <figure className="d-flex align-items-center mb-0 gap-3">
                    <img src={projectCreatedUser?.icon == null ? "/DefaultImage/DefaultProfile.png" : process.env.REACT_APP_PUBLIC_BACKEND_URL + projectCreatedUser.icon} alt="プロフィール写真" className="p25_user_icon rounded-circle" />
                    <div>
                        <p className="p25_user_title mb-0">{projectCreatedUser?.tag_name}</p>
                        <p className="p25_user_name mb-0 mb-0 fw-bold">{projectCreatedUser?.nickname}</p>
                    </div>
                    </figure>
                </Link>
                {accountData.data?.id === projectData?.user_id ?
                    <Link to={`/project/${id}/edit`} className="p25_edit_btn d-flex align-items-center gap-1 px-3 py-2">
                        <Settings />
                        <p className="mb-0">編集</p>
                    </Link>
                    : null
                }
            </div>
            <h1 className="p25_project_title w-100 mb-4 text-center fw-bold">{projectData?.project_name}</h1>
            <section className="d-flex flex-column flex-md-row justify-content-between align-items-center align-items-md-start mb-5">
                <div className="p25_project_thumbnail_wrapper position-relative">
                    <img src={projectData?.thumbnail == null ? "/DefaultImage/DefaultProjectThumbnail.png" : process.env.REACT_APP_PUBLIC_BACKEND_URL + projectData.thumbnail} alt="プロジェクト画像" className="p25_project_thumbnail w-100 h-100 object-fit-contain" />
                    {/* <span className="p25_project_status position-absolute px-3 py-1 fw-bold">{projectData?.progress}</span> */}
                </div>
                <div className="p25_information_wrapper d-flex flex-column mt-3 mt-md-0">
                    <ListGroup variant="flush" className="m13_listGroup gap-2">
                        <ListGroup.Item className="d-flex p-0 bg-transparent">
                            <Col xs={5}>
                                <SingleMenu
                                    imagePath={Tag}
                                    imageAlt="アイコン"
                                    text={"ジャンル"}
                                />
                            </Col>
                            <Col xs={4}>
                                <span>{projectData?.genre_name}</span>
                            </Col>
                        </ListGroup.Item>
                        <ListGroup.Item className="d-flex p-0 bg-transparent">
                            <Col xs={5}>
                                <SingleMenu
                                    imagePath={Calendar}
                                    imageAlt="アイコン"
                                    text="制作開始日"
                                />
                            </Col>
                            <Col xs={4}>
                                <span>{projectData?.created_at_project == null ? null : formatDate(projectData?.created_at_project)}</span>
                            </Col>
                        </ListGroup.Item>
                        <ListGroup.Item className="d-flex p-0 bg-transparent">
                            <Col xs={5}>
                                <SingleMenu imagePath={Users} imageAlt="アイコン" text="制作人数" />
                            </Col>
                            <Col xs={2}>
                                <span>{`${projectData?.developers}人`}</span>
                            </Col>
                        </ListGroup.Item>
                        <ListGroup.Item className="d-flex p-0 bg-transparent">
                            <Col xs={5}>
                                <SingleMenu
                                    imagePath={Responsibility}
                                    imageAlt="アイコン"
                                    text="担当箇所"
                                />
                            </Col>
                            <p className="m13_responsibility">
                                {projectData?.responsibility}
                            </p>
                        </ListGroup.Item>
                    </ListGroup>
                    <div className="p25_interaction_wrapper d-flex gap-2">
                        {/* <button onClick={handleLike} className={`p25_like_btn ${liked ? "liked" : ""} d-flex justify-content-center align-items-center position-relative overflow-hidden`}>
                            <Like />
                            <span>299</span>
                        </button> */}
                        {/* <button onClick={handleBookmark} className={`p25_bookmark_btn ${bookmarked ? "bookmarked" : ""} d-flex justify-content-center align-items-center position-relative overflow-hidden`}>
                            <BookMark />
                        </button> */}
                        {/* <button onClick={() => setOpenModal(true)} className="p25_share_btn d-flex justify-content-center align-items-center position-relative overflow-hidden">
                            <Share />
                        </button> */}
                        {/* <ShareModal
                            open={openModal}
                            setOpen={setOpenModal}
                        /> */}
                        {/* <a href="#deploy" className="p25_deploy_btn d-flex justify-content-center align-items-center position-relative overflow-hidden">
                            <Deploy />
                        </a> */}
                        {/* <a href="#github" className="p25_github_btn d-flex justify-content-center align-items-center position-relative overflow-hidden">
                            <Github />
                        </a> */}
                    </div>
                    <div className="p25_lang_wrapper">
                        <p className="p25_lang_title mb-1">使用言語</p>
                        <div className="d-flex gap-1 flex-wrap">
                            {projectData?.languages.map((language, index) => (
                                <span key={`${language.name}_${index}`} className="p25_lang_item px-3 py-1">{language.name}</span>
                            ))}
                        </div>
                    </div>
                    <div className="p25_tech_wrapper mt-3">
                        <p className="p25_tech_title mb-1">使用技術</p>
                        <div className="d-flex gap-1 flex-wrap">
                            {projectData?.technologies.map((technology, index) => (
                                <span key={`${technology.name}_${index}`} className="p25_tech_item px-3 py-1">{technology.name}</span>
                            ))}

                        </div>
                    </div>
                </div>
            </section>
            {/*lexicalの動画 */}
            <LexicalComposer initialConfig={editorConfig}>
                <div className="editor-container m14_editor_container p25_container d-flex flex-column aline-item-center">
                    <RichTextPlugin
                        contentEditable={<ContentEditable className="editor-input" />}
                        ErrorBoundary={LexicalErrorBoundary}
                    />
                    <AutoFocusPlugin />
                    <HistoryPlugin />
                    <CodeHighlightPlugin />
                    <ListPlugin />
                    <LinkPlugin />
                    <AutoLinkPlugin />
                    <ListMaxIndentLevelPlugin maxDepth={7} />
                    <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
                    <ImageInsertPlugin />
                    <ImageRegister />
                    <MoveSelectedNodes />
                </div>
            </LexicalComposer>
        </>
    );
}
