//コンポーネントID : o36
//パスワード再設定用画面
//メールで送られてきたリンクをクリックした後に表示される
import React, { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { ReactComponent as LockReset } from "../../../assets/lock_reset.svg";
import SingleMenu from "../../atom/template/SingleMenu";
import { ContainerSettings } from "../../page/layout/ContainerLayout";
import { Link, useParams } from "react-router-dom";
import CompleteAnimation from "../CompleteAnimation";
import axios from "../../../lib/axios";
import Validation from "../../molecules/Validation";
import { AppSettings } from "../../../App";
import { Helmet } from "react-helmet-async";

export default function ResetPassword() {

	const { token } = useParams();

	const { setTitle, setImage, setImageAlt, setProgressBarSize } = useContext(ContainerSettings);
	const { setPopWindow } = useContext(AppSettings);

	const [success, setSuccess] = useState(false);
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");

	const validation0 = /[A-Z]/;
	const validation1 = /[a-z]/;
	const validation2 = /[0-9]/;
	const validation3 = /.{8,}/;

	useEffect(() => {
		if (success) {
			setTitle("パスワード設定を完了しました");
			setImage(<CompleteAnimation />);
			setImageAlt("パスワード設定を完了しました");
		} else {
			setTitle("パスワード変更");
			setImage(<LockReset />);
			setImageAlt("メールアイコン");
		}
		setProgressBarSize(null);
	}, [success, setTitle, setImage, setImageAlt]);

	//パスワード変更処理
	async function resetPassword(e) {
		e.preventDefault();
		if(password !== confirmPassword) {
			setPopWindow("パスワードと確認用パスワードが一致していません。",true);
			return;
		}
		try {
			const response = await axios.post("/auth/password/reset", {
				"token": token,
				"password": password
			});
			//正しく変更が行われた場合
			setSuccess(!success);
		} catch (error) {
			if (error.response == null) {
				setPopWindow("サーバーから応答がありません。", true);
			} else {
				setPopWindow(error.response.data.error, true);
			}
		}
	}

	return (
		<>
			<Helmet>
				<title>パスワード変更｜Faro</title>
			</Helmet>
			{success ? (
				<section>
					<Link className="mx-auto mt-1 o36_link" to={"/login"}>ログインページへ</Link>
				</section>
			) : (
				<form className="o36_form" onSubmit={resetPassword}>
					<SingleMenu required text="新しいパスワード" />
					<input type="password" onChange={(e) => setPassword(e.target.value)} />
					<div className="validation">
						<Validation text="大文字1文字以上" check={validation0.test(password)} />
						<Validation text="小文字1文字以上" check={validation1.test(password)} />
						<Validation text="数字1文字以上" check={validation2.test(password)} />
						<Validation text="8文字以上" check={validation3.test(password)} />
					</div>
					<SingleMenu required text="新しいパスワード(確認用)" />
					<input type="password" onChange={(e) => setConfirmPassword(e.target.value)} />
					<Button type="onsubmit">変更</Button>
				</form>
			)}
		</>
	);
}
