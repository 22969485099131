import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $createParagraphNode, $getNodeByKey, $getSelection, $isRangeSelection, COMMAND_PRIORITY_NORMAL, DRAGSTART_COMMAND, DROP_COMMAND } from "lexical";
import { useEffect } from "react";
import { mergeRegister } from '@lexical/utils';


export default function MoveSelectedNodes() {

    const [editor] = useLexicalComposerContext();
    useEffect(() => {
        const unregister = mergeRegister(
            editor.registerCommand(
                DRAGSTART_COMMAND,
                (event: DragEvent) => {
                    const selection = $getSelection();
                    if ($isRangeSelection(selection)) {
                        const selectedNodes = selection.getNodes();
                        // ノードの配列をJSON文字列に変換
                        const nodeDataString = JSON.stringify(selectedNodes.map(node => ({
                            key: node.getKey(),
                            type: node.getType(),
                            // 必要に応じて他のプロパティもここに追加
                        })));
                        // JSON文字列をデータ転送オブジェクトにセット
                        event.dataTransfer?.setData('application/json', nodeDataString);
                    }
                    return true;
                },
                COMMAND_PRIORITY_NORMAL
            ),
            editor.registerCommand(
                DROP_COMMAND,
                (event: DragEvent) => {
                    event.preventDefault();
                    const x = event.clientX;
                    const y = event.clientY;
                    const elementAtDropPosition = document.elementFromPoint(x, y);

                    // 要素からLexicalノードのキーを取得
                    const nodeKey = elementAtDropPosition?.getAttribute('data-lexical-key');
                    if (nodeKey) {
                        editor.update(() => {
                            const nodeAtDropPosition = $getNodeByKey(nodeKey);
                            if (nodeAtDropPosition) {
                                // 新しいノードを作成（例: ParagraphNode）
                                const newNode = $createParagraphNode();
                                // ノードを適切な位置に挿入
                                nodeAtDropPosition.insertAfter(newNode);
                                // または: nodeAtDropPosition.insertAfter(newNode);
                            }
                        });
                    }
                    return true;
                },
                COMMAND_PRIORITY_NORMAL
            ),
        );
        return () => {
            unregister();
        };
    }, [editor]);
}