//コンポーネントID : m12
// マークアップ作成者：橋本

import React, { useState } from "react";
import { ReactComponent as Artifactdelete } from "../../assets/scan_delete.svg";
import { Button, Container, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import account from "../../reducers/accountSlice";
import ModalTemplate from "../organisms/modal/ModalTemplate";
import ProjectCreateForm from "../page/project/ProjectCreateForm";

export default function ItemNone({ isProject, id, activeModal }) {
    const accountData = useSelector(account.state);
    const [createModal, setCreateModal] = useState(false);
    function showButtons() {
        if (!accountData.Loading && accountData.data != null && accountData.data.id === parseInt(id)) {
            return <>{
                isProject ? (
                    <>
                        <p className="m12_title mt-1">
                            プロジェクトがありません。作成してみましょう！
                        </p>
                        <Button size="lg" onClick={() => setCreateModal(true)}>作成</Button>
                        <ModalTemplate
                            size="xl"
                            open={createModal}
                            setOpen={setCreateModal}
                            backdrop>
                            <Modal.Body >
                                <ProjectCreateForm />
                            </Modal.Body>
                        </ModalTemplate>
                    </>
                ) : (
                    <>
                        <p className="m12_title mt-1">
                            成果物がありません。作成してみましょう！
                        </p>
                        <Button size="lg" onClick={() => activeModal()}>作成</Button>
                    </>
                )
            }</>
        } else {
           return <p className="m12_title mt-1">プロジェクトがありません。</p>
        }
    }
    return (
        <Container className="text-center m12_container">
            <Artifactdelete />
            {showButtons()}
        </Container>
    );
}
