import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $createParagraphNode, $getSelection, $isRangeSelection, $isRootNode, COMMAND_PRIORITY_NORMAL, DRAGSTART_COMMAND, DROP_COMMAND } from "lexical";
import { useEffect } from "react";
import { $createImageNode } from "./ImageNode";
import axios from "../../../lib/axios";

export default function ImageInsertPlugin() {
    const [editor] = useLexicalComposerContext();
    useEffect(() => {
        editor.registerCommand(DRAGSTART_COMMAND, (e: DragEvent) => {
            return false;
        },
            COMMAND_PRIORITY_NORMAL);
        editor.registerCommand(DROP_COMMAND,
            (e: DragEvent) => {
                e.preventDefault();
                const files = e.dataTransfer ? e.dataTransfer.files : null;
                if (files && files.length > 0) {
                    insertImageFromFile(editor,files[0]);
                    return true;
                }
                return false;
            },
            COMMAND_PRIORITY_NORMAL);
        return () => {
            // document.getElementById("root")?.removeEventListener("drop", eventCancel);
        };
    }, [editor]);
}
export async function insertImageFromFile(editor, file) {
    if (file && file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onload = async function () { // onload 内も非同期処理に対応
            const result = reader.result;
            if (typeof result === 'string') {
                try {
                    const imagePath = await insertImageApi(file); // 画像アップロードの結果を待つ
                    if (imagePath) { // imagePathがnullでないことを確認
                        editor.update(() => {
                            const selection = $getSelection();
                            if (!$isRangeSelection(selection)) {
                                console.log("RangeSelection Error");
                                return;
                            }
                            const focusNode = selection.focus.getNode();
                            const imageNode = $createImageNode({
                                altText: "",
                                src: imagePath, // APIから取得した画像パスを使用
                                maxWidth: "100%",
                                width: "100%",
                                height: "auto"
                            });
                            const paragraphNode = $createParagraphNode();
                            paragraphNode.append(imageNode);
                            if ($isRootNode(focusNode)) {
                                focusNode.append(paragraphNode);
                            } else {
                                focusNode.getTopLevelElementOrThrow().insertAfter(paragraphNode);
                            }
                            
                            editor.focus();
                        });
                    }
                } catch (error) {
                    console.error("Error uploading image:", error);
                }
            }
        };
        reader.readAsDataURL(file);
    }
}

async function insertImageApi(imageData) {
    try {
        const formData = new FormData();
        formData.append("image", imageData);
        const response = await axios.post("/api/image/save", formData); // awaitを使用して応答を待つ
        if (response.data) {
            const imagePath = process.env.REACT_APP_PUBLIC_BACKEND_URL + response.data.path;
            return imagePath;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error in insertImageApi:", error);
        return null;
    }
}
