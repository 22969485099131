import { createSlice } from '@reduxjs/toolkit';
import axios from '../lib/axios';

const name = 'accountSlice';

export const slice = createSlice({
	name: name,
	initialState: {
		data: null,
		loading: true,
		error: null,
	},
	reducers: {
		setData: (state, action) => {
			state.data = action.payload;
			state.error = null;
			state.loading = false;
		},
		setLoading: (state, action) => {
			state.loading = action.payload;
		},
		setError: (state, action) => {
			state.error = action.payload;
			state.loading = false;
		},
	},
});

const initData = () => async (dispatch) => {
	try {
		const response = await axios.get("/auth/user");
		console.log(response.data);
		dispatch(slice.actions.setData(response.data));
	} catch (error) {
		dispatch(slice.actions.setError(error.code));
	}
};


const logout = (removeCookie) => async (dispatch) => {
	await axios.get("/auth/logout");
	dispatch(account.slice.actions.setData(null));
	removeCookie("auth_token");
};

export const account = {
	name: name,
	slice: slice,
	initData: initData,
	logout: logout,
	state: (state) => state.account,
}
export default account;