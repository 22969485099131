import React, { useId } from "react";
import { Form, InputGroup } from "react-bootstrap";

export default function SettingSNSGroup({ name, snsIcon, snsURLPrefix, userAccount, placeholder, isPublic, onPublicChange, onChange }) {

    const id = useId();

    return (
        <div className="a6_form">
            <div className="icon">
                {
                    snsIcon && <img src={snsIcon.icon} alt={snsIcon.alt} />
                }
            </div>
            <div className="col_form">
                {
                    snsIcon &&
                    <div htmlFor="basic-url" className="form_label">
                        {name}
                    </div>
                }

                <InputGroup className="input_group">
                    {snsURLPrefix !== null && snsURLPrefix.length !== 0 &&
                        <InputGroup.Text
                            className="input_label"
                            id="basic-addon3"
                            onChange={(e) => onChange(e)}
                        >
                            {snsURLPrefix}
                        </InputGroup.Text>
                    }
                    <Form.Control
                        className="form_control"
                        id="basic-url"
                        aria-describedby="basic-addon3"
                        placeholder={placeholder}
                        value={userAccount}
                        onChange={(e) => {
                            if (userAccount === "") {
                                isPublic = false;
                            }
                            onChange(e)
                        }}
                    />
                </InputGroup>
            </div>
            <Form.Check
                checked={isPublic}
                className="check_label"
                label="公開する"
                id={`a6_id_${id}`}
                onChange={(e) => {
                    if (userAccount !== "") {
                        onPublicChange(e);
                    } else {
                        document.getElementById(`a6_id_${id}`).checked = false;
                        e.preventDefault();
                    }
                }}
            />
        </div>
    );
}
