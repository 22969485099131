//コンポーネントID : p30

import React from "react";
import Layout from "./Layout";
import { Outlet } from "react-router-dom";

export default function FullSizeLayout() {
	return (
		<Layout>
			<Outlet />
		</Layout>
	);
}