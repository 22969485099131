import React from "react";
import { ReactComponent as ValidationIcon } from "../../assets/validation.svg";
import { ReactComponent as ValidationCheckedIcon } from "../../assets/validation_checked.svg";

export default function Validation({ text, check }) {
	return (
		<div className="m45">
			<p className={check ? "checked" : ""}>{text}</p>
			{check ? <ValidationCheckedIcon /> : <ValidationIcon />}
		</div>
	);
}
