import React from 'react'
import ModalTemplate from './ModalTemplate'
import { Button, Modal, Stack } from 'react-bootstrap'
import { ReactComponent as Attention } from '../../../assets/attention.svg'

export default function DeleteProjectModal({ open, setOpen, onSend }) {
    return (
        <ModalTemplate
            open={open}
            setOpen={setOpen}
        >
            <Modal.Body className="o45_modal_body">
                <div className='d-flex justify-content-center align-items-center o45_attention_content'>
                    <Attention type="error" />
                    <p>プロジェクトを削除しますか？</p>
                </div>
                <Stack gap={4} direction="horizontal" className="mx-auto o45_stack justify-content-evenly">
                    <Button variant="outline-danger" onClick={() => onSend(true)}>
                        削除する
                    </Button>
                    <Button variant="outline-secondary" onClick={() => setOpen(false)}>
                        キャンセル
                    </Button>
                </Stack>
            </Modal.Body>
        </ModalTemplate>
    )
}
