//コンポーネントID : o39
import React from "react";
import { Modal, Button, Stack } from "react-bootstrap";
import ModalTemplate from "./ModalTemplate";
import { useDispatch } from "react-redux";
import account from "../../../reducers/accountSlice";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

export default function LogoutModal({ open, setOpen }) {

	const dispatch = useDispatch();
	const removeCookie = useCookies(['auth_token'])[2];
	const navigate = useNavigate();
	async function logout() {
		dispatch(account.logout(removeCookie));
		navigate("/login");
        setOpen(false);
	}

    return (
        <ModalTemplate
            open={open}
            setOpen={setOpen}
        >
            <Modal.Body className="o39_modal_body">
                <p className="o39_title mx-auto">アカウントからログアウトしますか？</p>
                <Stack gap={4} direction="horizontal" className="mx-auto o39_stack">
                    <Button variant="outline-danger" onClick={() => logout()}>
                        ログアウトする
                    </Button>
                    <Button variant="outline-secondary" onClick={() => setOpen(false)}>
                        キャンセル
                    </Button>
                </Stack>
            </Modal.Body>
        </ModalTemplate>
    );
};
