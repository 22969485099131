//コンポーネントID : p18
// マークアップ担当者：鶴岡

//お問い合わせ

import React, { useContext, useRef, useState } from "react";
import SingleMenu from "../../atom/template/SingleMenu";
import RadioButton from "../../molecules/radio/RadioButton";
import { Button, Form } from "react-bootstrap";
import listing from "../../../reducers/listingSlice";
import { useSelector } from "react-redux";
import axios from "../../../lib/axios";
import { AppSettings } from "../../../App";
import { Helmet } from "react-helmet-async";

export default function Contact() {

    const listingData = useSelector(listing.state).data;
    const [inquiryData, setInquiryData] = useState({});
    function handleChange(e, value) {
        setInquiryData((prevData) => ({
            ...prevData,
            [e.target.name]: value,
        }));
    }
    const ref = useRef(null);

    const {
        setPopWindow,
    } = useContext(AppSettings);

    async function onSubmit(e) {
        e.preventDefault();
        setInquiryData(status => ({
            "name": "",
            "email": "",
            "contact_type": "",
            "content": "",
            ...status,
        }));
        setTimeout(async () => {
            if (hasErrorClass()) {
                setPopWindow("正しく入力して再試行してください。", true);
                return;
            }
            try {
                await axios.post("/api/contact", inquiryData);
                setPopWindow("送信しました。");
            } catch (error) {
                setPopWindow(error.response.data.error, true);
            }
        }, 0);
    }

    function hasErrorClass() {
        const children = ref.current.querySelectorAll("*");
        for (let i = 0; i < children.length; i++) {
            const child = children[i];
            if (child.classList.contains("error")) {
                return true;
            }
        }
        return false;
    }

    return (
        <>
            <Helmet>
                <title>お問い合わせ｜Faro</title>
            </Helmet>
            <h1 className="p18_title w-100 mb-3 fw-bold text-center">お問い合わせ</h1>
            <p className="p18_preamble w-75 text-center m-auto mb-4">
                下記に必要事項を記入の上、お問い合わせください
            </p>
            <div className="w-75 m-auto" ref={ref}>
                {
                    listingData != null &&
                    <Form className="p18_form_wrapper" onSubmit={onSubmit}>
                        <Form.Group>
                            <SingleMenu text="氏名" />
                            <Form.Control
                                type="text"
                                name="name"
                                id=""
                                placeholder="例）山田太郎"
                                className={"w-100" + (inquiryData.name != null && inquiryData.name === "" ? " error" : "")}
                                onChange={(e) => {
                                    handleChange(e, e.target.value);
                                }}
                            />
                        </Form.Group>
                        <Form.Group>
                            <SingleMenu text="メールアドレス" />
                            <Form.Control
                                type="email"
                                name="email"
                                id=""
                                placeholder="例）faro@example.com"
                                className={"w-100" + (inquiryData.email != null && inquiryData.email === "" ? " error" : "")}
                                onChange={(e) => {
                                    handleChange(e, e.target.value);
                                }}
                            />
                        </Form.Group>
                        <Form.Group>
                            <SingleMenu text="お問い合わせの種類" />
                            <RadioButton
                                options={listingData.contact_types}
                                name="contact_type"
                                error={inquiryData.contact_type != null && inquiryData.contact_type === ""}
                                onChange={(e, id) => {
                                    handleChange(e, id);
                                }}
                            />
                        </Form.Group>
                        <Form.Group>
                            <SingleMenu text="お問い合わせの内容" />
                            <Form.Control
                                as="textarea"
                                name="content"
                                className={"w-100" + (inquiryData.content != null && inquiryData.content === "" ? " error" : "")}
                                rows="5"
                                onChange={(e) => {
                                    handleChange(e, e.target.value);
                                }}
                            />
                        </Form.Group>
                        <Button type="submit">確認</Button>
                    </Form>
                }
            </div>
        </>
    );
}
