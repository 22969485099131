//コンポーネントID : a1
// マークアップ作成者：鶴岡

import React, { useEffect, useRef, useState } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import Popover from "react-bootstrap/Popover";
import { Link } from "react-router-dom";
import { ReactComponent as Article } from "../../assets/article.svg";
import { ReactComponent as AddProject } from "../../assets/add_project_fill.svg";
import { ReactComponent as PortfolioEdit } from "../../assets/portfolio_edit_fill.svg";
// import { ReactComponent as Like } from "../../assets/like_fill.svg";
// import { ReactComponent as Bookmark } from "../../assets/bookmark_fill.svg";
import { ReactComponent as Setting } from "../../assets/setting_fill.svg";
import { ReactComponent as LogoutImage } from "../../assets/logout.svg";
import { Button, Overlay } from "react-bootstrap";
import LogoutModal from "../organisms/modal/LogoutModal";
import { useSelector } from "react-redux";
import account from "../../reducers/accountSlice";

export default function UserIcon() {
    const [openModal, setOpenModal] = useState(false);
    const [show, setShow] = useState(false);
    const [mobile, setMobile] = useState(false);
    const accountData = useSelector(account.state);
    const ref = useRef(null);
    const target = useRef(null);
    const popover = useRef(null);

    useEffect(() => {
        const resize = () => {
            setMobile(window.innerWidth < 767);
        }
        window.addEventListener('resize', resize);
        resize();

        return () => {
            window.removeEventListener('resize', resize);
        };
    }, []);

    useEffect(() => {
        if (!openModal) {
            document.addEventListener("mousedown", handleOutClick);
            return () => document.removeEventListener("mousedown", handleOutClick);
        }
    }, [openModal]);

    const handleClick = () => {
        setShow(!show);
    };

    const handleOutClick = (e) => {
        if (!ref.current.contains(e.target)) {
            setShow(false);
        }
    }

    const Lists = (
        <ListGroup className="a1_lists">
            <div className="a1_profile_wrapper">
                <figure>
                    <img
                        className="a1_list_bg_usericon"
                        src={accountData.data.icon != null ? process.env.REACT_APP_PUBLIC_BACKEND_URL + accountData.data.icon : "/DefaultImage/DefaultProfile.png"}
                        alt="ユーザーアイコン"
                    />
                </figure>
                <img
                    className="a1_list_usericon"
                    src={accountData.data.icon != null ? process.env.REACT_APP_PUBLIC_BACKEND_URL + accountData.data.icon : "/DefaultImage/DefaultProfile.png"}
                    alt="ユーザーアイコン"
                />
                <p>{accountData.data.nickname}</p>
                <p>{accountData.data.tag_name}</p>
            </div>
            <ListGroup.Item>
                <Link to={`/user/` + accountData.data.id} onClick={() => handleClick()}>
                    <Article />
                    マイページ
                </Link>
            </ListGroup.Item>
            <ListGroup.Item>
                <Link to={"/project/create"} onClick={() => handleClick()}>
                    <AddProject />
                    プロジェクト新規作成
                </Link>
            </ListGroup.Item>
            <ListGroup.Item>
                <Link to={`/user/` + accountData.data.id + `/portfolio`} onClick={() => handleClick()}>
                    <PortfolioEdit />
                    ポートフォリオ
                </Link>
            </ListGroup.Item>
            {/* <ListGroup.Item>
                        <Link to={`/likes/` + accountData.data.id }>
                            <Like />
                            いいね一覧
                        </Link>
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <Link to={`/bookmarks/` + accountData.data.id }>
                            <Bookmark />
                            ブックマーク一覧
                        </Link>
                    </ListGroup.Item> */}
            <ListGroup.Item>
                <Link to={"/settings"} onClick={() => handleClick()}>
                    <Setting />
                    アカウント設定
                </Link>
            </ListGroup.Item>
            <ListGroup.Item>
                <Button variant="primary" className="logout" onClick={() => setOpenModal(true)}>
                    <LogoutImage className="a1_list_img" />
                    ログアウト
                </Button >
                <LogoutModal
                    open={openModal}
                    setOpen={setOpenModal}
                />
            </ListGroup.Item>
        </ListGroup>
    )

    return (
        <div ref={ref} className="nav-link a1_usermenu">
            {mobile ? (
                <>
                    {Lists}
                </>
            ) : (
                <>
                    <button
                        ref={target}
                        onClick={() => handleClick()}
                        className="a1_usericon">
                        <img className="a1_img" src={accountData.data.icon != null ? process.env.REACT_APP_PUBLIC_BACKEND_URL + accountData.data.icon : "/DefaultImage/DefaultProfile.png"} alt="ユーザーアイコン" />
                    </button>
                    <Overlay
                        show={show}
                        onHide={false}
                        target={target.current}
                        placement="bottom"
                        container={ref}
                        containerPadding={20}
                        ref={popover}>
                        <Popover id="popover-contained">
                            {Lists}
                        </Popover>
                    </Overlay>
                </>
            )}
        </div>
    );
}
