//コンポーネントID : o34

import React, { useContext, useEffect } from "react";
import { ReactComponent as UserBadge } from "../../../assets/user_badge.svg";
import { ContainerSettings } from "../../page/layout/ContainerLayout";
import { Link } from "react-router-dom";

export default function RegisterSuccess() {
	const {
		setTitle,
		setImage,
		setImageAlt,
	} = useContext(ContainerSettings);

	useEffect(() => {
		// setProgressBarSize();
		setTitle("アカウントの登録が完了しました");
		setImage(<UserBadge />);
		setImageAlt("アカウントの登録が完了しました");
	}, [setTitle, setImage, setImageAlt]);

	return (
		<section className="o34_container">
			<Link to="/">メインページへ</Link>
		</section>
    );
}
