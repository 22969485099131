import { configureStore } from '@reduxjs/toolkit';
import account from '../reducers/accountSlice';
import listing from '../reducers/listingSlice';

const store = configureStore({
    reducer: {
        account: account.slice.reducer,
        listing: listing.slice.reducer,
    },
});

export default store;