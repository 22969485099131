//コンポーネントID : m41
//使用言語と使用技術のセレクト

import { useState } from "react";
import CreatableSelect from "react-select/creatable";

export default function StackSelect({ onChange, name, options, defaultValue }) {

    const [createLabel, setCreateLabel] = useState("検索候補がありません 新しくテキストを追加(入力)することが可能です");

    const formatCreateLabel = () => {
        return createLabel;
    };
    const formattedOptions = options.map(option => ({
        value: option.name,
        label: option.name
    }));
    const [values, setValues] = useState([]);

    function onUpdated(e) {
        const selectedLabels = e.map((option) => option.label);
        setValues(selectedLabels);
        onChange(selectedLabels);
    }

    function onInputChange(e) {
        const lowerValues = values.map((value) => value.toLowerCase());
        const lowerInputValue = e.toLowerCase();

        if (lowerValues.includes(lowerInputValue)) {
            setCreateLabel("既に選択されています");
        } else if (e.length) {
            setCreateLabel(`新しく${e}を追加する`);
        } else {
            setCreateLabel("検索候補がありません 新しくテキストを追加(入力)することが可能です");
        }
    }

    return (
        <CreatableSelect
            name={name}
            className="m41_select_wrapper"
            options={formattedOptions}
            closeMenuOnSelect={false}
            isMulti={true}
            isClearable={true}
            components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
            }}
            placeholder={"選択または新しく追加"}
            noOptionsMessage={() => createLabel}
            formatCreateLabel={formatCreateLabel}
            onInputChange={onInputChange}
            onChange={onUpdated}
            defaultValue={defaultValue ? [
                ...formattedOptions.filter(option => defaultValue.includes(option.label)),
                ...defaultValue.filter(val => !formattedOptions.some(opt => opt.label === val)).map(option => ({ value: option, label: option }))
            ] : null}
        />
    );
}
