//コンポーネントID : o2
//マークアップ作成者：アミン

import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AppSettings } from "../../../App";
import axios from "../../../lib/axios";
import CreateProject from "../../molecules/CreateProject";
import ProjectExplain from "../../molecules/ProjectExplain";
import Tabs from "../../molecules/Tabs";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import account from "../../../reducers/accountSlice";
import { Helmet } from "react-helmet-async";

export default function ProjectEditForm() {
    const navigate = useNavigate();
    const accountData = useSelector(account.state);
    const [activeTab, setActiveTab] = useState(0);//表示する子コンポーネントを管理する
    const [thumbnail, setThumbnail] = useState(null);
    const [body, setBody] = useState(null);
    const { setPopWindow } = useContext(AppSettings);
    const { id } = useParams();

    useEffect(() => {
        // プロジェクト詳細のAPIに関する関数
        async function fetchData() {
            try {
                const prevRespProjectData = await axios.get(`/api/project/${id}`);
                const prevProjectData = prevRespProjectData.data;
                const projectTagsData = await axios.post(`/api/projects/tags`, [id]);
                const tagData = projectTagsData.data.find(tag => tag.id == id);
                if (tagData) {
                    prevProjectData.languages = tagData.languages.map(language => language.name);
                    prevProjectData.technologies = tagData.technologies.map(technology => technology.name);
                }
                const isPortfolio = prevProjectData.position_index >= 0 ? true : false;
                const reorderedData = {
                    created_at_project: prevProjectData.created_at_project,
                    deploy_link: prevProjectData.deploy_link,
                    favorite_count: prevProjectData.favorite_count,
                    genre_id: prevProjectData.genre_id,
                    genre_name: prevProjectData.genre_name,
                    github_link: prevProjectData.github_link,
                    icon: prevProjectData.icon,
                    id: prevProjectData.id,
                    is_bookmark: prevProjectData.is_bookmark,
                    is_favorite: prevProjectData.is_favorite,
                    is_project: prevProjectData.is_project,
                    is_published: prevProjectData.is_public,
                    is_portfolio: isPortfolio,
                    languages: prevProjectData.languages,
                    nickname: prevProjectData.nickname,
                    developers: prevProjectData.developers,
                    overview: prevProjectData.overview,
                    position_index: prevProjectData.position_index,
                    progress: prevProjectData.progress,
                    project_name: prevProjectData.project_name,
                    responsibility: prevProjectData.responsibility,
                    technologies: prevProjectData.technologies,
                    user_id: prevProjectData.user_id
                };
                setThumbnail(prevProjectData.thumbnail == null ? null : process.env.REACT_APP_PUBLIC_BACKEND_URL + prevProjectData.thumbnail)
                setBody(reorderedData);
            } catch (error) {
                setPopWindow("読み込みエラー", true);
            }
        }
        fetchData()
    }, [id, setPopWindow])

    function handleNextTab(nextTab) {
        if (body.project_name === null || body.project_name.trim() === "") {
            setBody({ ...body, project_name: "" })
        }
        setActiveTab(nextTab);
    }

    const dataSend = async (...isDel) => {
        if (isDel[0]) {
            try {
                await axios.delete(`/api/project/delete/${id}`);
                navigate(`/user/${body.user_id}`);
                setPopWindow(`[ ${body.project_name} ]を削除しました。`);
            } catch (error) {
                setPopWindow("削除できませんでした。", true);
            }
        } else {
            if (body.project_name === null || body.project_name.trim() === "") {
                setBody({ ...body, project_name: "" })
                setPopWindow("プロジェクトの名前を記入してください。", true);
            } else {
                //APIを叩く
                const editedProjectData = new FormData();
                if (thumbnail != null) {
                    if (typeof thumbnail === "string" || thumbnail instanceof String) {
                        editedProjectData.append("thumbnail", thumbnail === "" ? null : thumbnail.replace(process.env.REACT_APP_PUBLIC_BACKEND_URL, ""));
                    } else {
                        editedProjectData.append("thumbnail", thumbnail === "" ? null : thumbnail);
                    }
                }
                editedProjectData.append("body", JSON.stringify(body));
                try {
                    const response = await axios.post(`/api/project/edit/${id}`, editedProjectData);
                    const editedProjectId = response.data.projectId;
                    navigate(`/project/${editedProjectId}`);
                    setPopWindow("保存しました。");
                } catch (error) {
                    setPopWindow("保存できませんでした。", true);
                }
            }
        }
    }

    const tabs = ["詳細", "概要"];

    if (!body && !thumbnail) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <Spinner animation="border" />
            </div>
        );
    }

    if (accountData.data?.id !== body?.user_id) {
        setPopWindow("編集権限がありません。", true);
        navigate(`project/${body.id}`);
    }
    return (
        <>
            <Helmet>
                <title>プロジェクト編集｜Faro</title>
            </Helmet>
            <h1 className="o2_title">プロジェクト編集</h1>
            <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
            <div className="o2_content">
                {activeTab === 0 && <CreateProject body={body} setBody={setBody} img={{ thumbnail, setThumbnail }} onNextTab={handleNextTab} onSend={dataSend} isEdit />}
                {activeTab === 1 && <ProjectExplain body={body} setBody={setBody} onSend={dataSend} isEdit />}
                {/* {activeTab === 2 && <ProjectPublish body={body} setBody={setBody} onSend={dataSend} />} */}
            </div>
        </>
    );
}