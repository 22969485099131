//コンポーネントID : a2
// マークアップ担当者：鶴岡

import React from "react";

export default function BackImage() {
    return (
        <div className="a2_wrapper w-100 h-100 top-0 start-0 position-fixed overflow-hidden">
            <div className="a2_bubble_wrapper_xl position-absolute">
                <svg className="a2_ball" viewBox="0 0 300 300">
                    <defs>
                        <radialGradient
                            id="a2_gradient_xl"
                            cx="49.88%"
                            cy="50.12%"
                            r="45.34%"
                            fx="49.88%"
                            fy="50.12%"
                        >
                            <stop offset="55.21%" stopColor="rgba(165, 184, 251, 0.2)" />
                            <stop offset="100%" stopColor="rgba(255,255,255, 0.6)" />
                        </radialGradient>
                    </defs>
                    <circle
                        className="a2_circle"
                        cx="0"
                        cy="0"
                        r="125"
                        style={{ fill: "url(#a2_gradient_xl)" }}
                    >
                        <animateMotion
                            path="M162.9,150c6.8-0.2,12.1-5.7,12.1-12.5c0-6.9-5.6-12.5-12.5-12.5c-6.8,0-12.3,5.4-12.5,12.2v25.7 c-0.2,6.8-5.7,12.2-12.5,12.2c-6.9,0-12.5-5.6-12.5-12.5c0-6.8,5.4-12.3,12.1-12.5L162.9,150z"
                            dur="30s"
                            repeatCount="indefinite"
                            calcMode="linear"
                            fill="freeze"
                        ></animateMotion>
                    </circle>
                </svg>
            </div>
            <div className="a2_bubble_wrapper_lg position-absolute">
                <svg className="a2_ball" viewBox="0 0 300 300">
                    <defs>
                        <radialGradient
                            id="a2_gradient_lg"
                            cx="49.88%"
                            cy="50.12%"
                            r="45.34%"
                            fx="49.88%"
                            fy="50.12%"
                        >
                            <stop offset="55.21%" stopColor="rgba(165, 184, 251, 0.3)" />
                            <stop offset="100%" stopColor="rgba(255,255,255, 0.6)" />
                        </radialGradient>
                    </defs>
                    <circle
                        className="a2_circle"
                        cx="0"
                        cy="0"
                        r="125"
                        style={{ fill: "url(#a2_gradient_lg)" }}
                    >
                        <animateMotion
                            path="M162.9,150c6.8-0.2,12.1-5.7,12.1-12.5c0-6.9-5.6-12.5-12.5-12.5c-6.8,0-12.3,5.4-12.5,12.2v25.7 c-0.2,6.8-5.7,12.2-12.5,12.2c-6.9,0-12.5-5.6-12.5-12.5c0-6.8,5.4-12.3,12.1-12.5L162.9,150z"
                            dur="23s"
                            repeatCount="indefinite"
                            calcMode="linear"
                            fill="freeze"
                        ></animateMotion>
                    </circle>
                </svg>
            </div>
            <div className="a2_bubble_wrapper_md position-absolute">
                <svg className="a2_ball" viewBox="0 0 300 300">
                    <defs>
                        <radialGradient
                            id="a2_gradient_md"
                            cx="49.88%"
                            cy="50.12%"
                            r="45.34%"
                            fx="49.88%"
                            fy="50.12%"
                        >
                            <stop offset="55.21%" stopColor="rgba(255, 220, 255, 0.2)" />
                            <stop offset="100%" stopColor="rgba(255,255,255, 0.7)" />
                        </radialGradient>
                    </defs>
                    <circle
                        className="a2_circle"
                        cx="0"
                        cy="0"
                        r="125"
                        style={{ fill: "url(#a2_gradient_md)" }}
                    >
                        <animateMotion
                            path="M162.9,150c6.8-0.2,12.1-5.7,12.1-12.5c0-6.9-5.6-12.5-12.5-12.5c-6.8,0-12.3,5.4-12.5,12.2v25.7 c-0.2,6.8-5.7,12.2-12.5,12.2c-6.9,0-12.5-5.6-12.5-12.5c0-6.8,5.4-12.3,12.1-12.5L162.9,150z"
                            dur="18s"
                            repeatCount="indefinite"
                            calcMode="linear"
                            fill="freeze"
                        ></animateMotion>
                    </circle>
                </svg>
            </div>
            <div className="a2_bubble_wrapper_sm position-absolute">
                <svg className="a2_ball" viewBox="0 0 300 300">
                    <defs>
                        <radialGradient
                            id="a2_gradient_sm"
                            cx="49.88%"
                            cy="50.12%"
                            r="45.34%"
                            fx="49.88%"
                            fy="50.12%"
                        >
                            <stop offset="55.21%" stopColor="rgba(255, 200, 251, 0.3)" />
                            <stop offset="100%" stopColor="rgba(255,255,255, 0.8)" />
                        </radialGradient>
                    </defs>
                    <circle
                        className="a2_circle"
                        cx="0"
                        cy="0"
                        r="125"
                        style={{ fill: "url(#a2_gradient_sm)" }}
                    >
                        <animateMotion
                            path="M162.9,150c6.8-0.2,12.1-5.7,12.1-12.5c0-6.9-5.6-12.5-12.5-12.5c-6.8,0-12.3,5.4-12.5,12.2v25.7 c-0.2,6.8-5.7,12.2-12.5,12.2c-6.9,0-12.5-5.6-12.5-12.5c0-6.8,5.4-12.3,12.1-12.5L162.9,150z"
                            dur="13s"
                            repeatCount="indefinite"
                            calcMode="linear"
                            fill="freeze"
                        ></animateMotion>
                    </circle>
                </svg>
            </div>
            <div className="a2_bubble_wrapper_xs position-absolute">
                <svg className="a2_ball" viewBox="0 0 300 300">
                    <defs>
                        <radialGradient
                            id="a2_gradient_xs"
                            cx="49.88%"
                            cy="50.12%"
                            r="45.34%"
                            fx="49.88%"
                            fy="50.12%"
                        >
                            <stop offset="55.21%" stopColor="rgba(165, 184, 251, 0.2)" />
                            <stop offset="100%" stopColor="rgba(255,255,255, 0.9)" />
                        </radialGradient>
                    </defs>
                    <circle
                        className="a2_circle"
                        cx="0"
                        cy="0"
                        r="125"
                        style={{ fill: "url(#a2_gradient_xs)" }}
                    >
                        <animateMotion
                            path="M162.9,150c6.8-0.2,12.1-5.7,12.1-12.5c0-6.9-5.6-12.5-12.5-12.5c-6.8,0-12.3,5.4-12.5,12.2v25.7 c-0.2,6.8-5.7,12.2-12.5,12.2c-6.9,0-12.5-5.6-12.5-12.5c0-6.8,5.4-12.3,12.1-12.5L162.9,150z"
                            dur="10s"
                            repeatCount="indefinite"
                            calcMode="linear"
                            fill="freeze"
                        ></animateMotion>
                    </circle>
                </svg>
            </div>
        </div>
    );
}
