//コンポーネントID : o13
import React, { useEffect } from "react";
import { ReactComponent as Task } from "../../assets/task_alt_fill.svg";
import { ReactComponent as Cancel } from "../../assets/cancel.svg";

export default function PopWindow({ id, message, isError, onClose }) {
    useEffect(() => {
        setTimeout(() => {
            onClose(id);
        }, 8000);
    }, [id, onClose]);

    return (
        <div className={`o13_pop_window d-flex`} id={id}>
            <div className={"left_color" + (isError ? " red" : "")} />
            <div className="o13_check d-flex">
                {isError ? (
                    <Cancel className="red" />
                ) : (
                    <Task />
                )}
            </div>
            <p>{message}</p>
        </div>
    );
}
