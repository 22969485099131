//コンポーネントID : m5
//マークアップ作成者：アミン

import React, { useCallback, useContext, useEffect, useState } from "react";
import { ReactComponent as Photo } from "../../assets/add_photo.svg";
import { Image } from "react-bootstrap";
import { AppSettings } from "../../App";

const imageType = /.(jpg|tiff|bmp|gif|png|tif|wbmp|jpeg)$/i;

export default function DropImage({ image, setImage, type }) {

    const [img, setImg] = useState();
    const [error, setError] = useState();

    const {
        setPopWindow,
    } = useContext(AppSettings);

    const fileType = "image/JPG, image/jpg, image/tiff, image/bmp, image/BMP, image/gif, image/GIF, image/WBMP, image/png, image/PNG, image/JPEG, image/tif, image/TIF, image/TIFF, image/wbmp, image/jpeg";

    function onFileChange(event, canceled) {
        if (canceled) {
            event.preventDefault();
        }
        const file = event.target.files ? event.target.files[0] : null;
        if (!file || (file && file.type.match(imageType))) {
            setImage(file);
            if (file) {
                processFile(file);
            }
            setError(false);
        } else {
            setImage(null);
            setPopWindow("画像ファイルを挿入してください", true);
            setError(true);
            event.target.value = null;
        }
    }

    const processFile = useCallback((file) =>{
        if (file && file.type.match(imageType)) {
            const reader = new FileReader();
            reader.onload = (evt) => {
                setImg(evt.target?.result);
            };
            reader.readAsDataURL(file);
        }
    },[]);

    useEffect(() => {
        if (image && image.type && image.type.match(imageType)) {
            processFile(image);
        }
        setImg(image);
    }, [image, processFile]);

    return (
        <>
            {type === "icon" ? (
                // アイコンの変更用
                <div className="m5_change_icon">
                    <input type="file" accept={fileType} onChange={(e) => onFileChange(e, false)} className="icon_field" />
                    {
                        img ?
                            <Image
                                src={img}
                                alt="選択した画像"
                                className="icon"
                            />
                            :
                            // 変更前のアイコン
                            <Image
                                className="icon"
                                src="/DefaultImage/DefaultProfile.png"
                                alt="アイコン"
                                roundedCircle
                            />

                    }

                </div>
            ) : (
                // サムネイルなどの変更用
                <div onDrop={(e) => onFileChange(e, true)} className={"m5_drop_wrapper d-flex position-relative align-items-center " + (error ? "image_error" : "")}>
                    <input type="file" accept={fileType} onChange={(e) => onFileChange(e, false)} className="m5_drop_field w-100 h-100 position-absolute opacity-0" />
                    {
                        img ?
                            <img src={img} alt="選択した画像" className="m5_dropped_img" />
                            :
                            <div className="m5_svg_wrapper d-flex justify-content-center align-items-center">
                                <Photo className="m5_svg" />
                            </div>
                    }
                    <p className="m5_drop_text text-center">
                        ここにサムネイル用ファイルをドロップ<br />
                        または<br />
                        クリックしてアップロード<br />
                        (画像サイズの比率5:7推奨)
                    </p>
                </div>
            )
            }
        </>
    );
}
