//コンポーネントID : p6
// マークアップ担当者：鶴岡

//ログイン画面
//メールアドレスとパスワード
//データベースに登録がなければエラー表示させてログインさせない

import React, { useContext, useEffect, useState } from "react";
import { ReactComponent as ShieldLock } from "../../assets/shield_lock.svg";
import SingleMenu from "../atom/template/SingleMenu";
// import Provider from "../molecules/Provider";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { ContainerSettings } from "./layout/ContainerLayout";
import axios from "../../lib/axios";
import { AppSettings } from "../../App";
import { useDispatch, useSelector } from "react-redux";
import account from "../../reducers/accountSlice";
import { Helmet } from "react-helmet-async";

export default function Login() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const accountData = useSelector(account.state).data;

    const {
        setPopWindow,
    } = useContext(AppSettings);

    const {
        setProgressBarSize,
        setTitle,
        setImage,
        setImageAlt,
    } = useContext(ContainerSettings);

    const [email, setEmail] = useState();
    const [password, setPassword] = useState();

    const redirectPath = new URLSearchParams(useLocation().search).get('redirect');

    useEffect(() => {
        if (accountData != null) {
            if (redirectPath == null) {
                navigate("/");
            } else {
                navigate(redirectPath);
            }
        }
    }, [accountData, redirectPath, navigate]);

    useEffect(() => {
        setProgressBarSize(null);
        setTitle("ログイン");
        setImage(<ShieldLock />);
        setImageAlt("ログイン");
    }, [setTitle, setImage, setImageAlt, setProgressBarSize]);

    async function onSubmit(e) {
        e.preventDefault();
        try {
            const response = await axios.post("/auth/login", {
                "email": email,
                "password": password,
            });
            dispatch(account.slice.actions.setData(response.data));
            if (redirectPath == null) {
                navigate("/");
            } else {
                navigate(redirectPath);
            }
        } catch (error) {
            if (error.response == null) {
                setPopWindow("サーバーから応答がありません。", true);
            } else {
                setPopWindow(error.response.data.error, true);
            }
        }
    }

    return (
        <>
            <Helmet>
                <title>ログイン ｜ Faro</title>
            </Helmet>
            <form className="p6_container" onSubmit={onSubmit} action="#">
                <SingleMenu required text="メールアドレス" />
                <input type="email" onChange={(e) => setEmail(e.target.value)} />
                <SingleMenu required text="パスワード" />
                <input type="password" onChange={(e) => setPassword(e.target.value)} />
                <Link to="/password/forget">パスワードを忘れた方はこちら{">>"}</Link>
                <Button type="submit">ログイン</Button>
                {/* <Provider
                    login
                    orBar
                /> */}
                <Link to="/register" className="create_account">アカウント新規作成の方はこちら{">>"}</Link>
            </form>
        </>
    );
}
