//コンポーネントID : o14
// マークアップ担当者：鶴岡

//ユーザーのメールアドレスに認証用メール送信完了
//再送信できるようにする(回数制限あり)

import React, { useContext, useEffect, useState } from "react";
import { ReactComponent as ContactMail } from "../../../assets/contact_mail.svg";
import { ContainerSettings } from "../../page/layout/ContainerLayout";
import { useLocation, useNavigate } from "react-router-dom";
import { AppSettings } from "../../../App";
import axios from "../../../lib/axios";
import account from "../../../reducers/accountSlice";
import { useDispatch, useSelector } from "react-redux";
import ReSendButton from "../../molecules/ReSendButton";

export default function RegisterSendEmail({ isReset, email }) {
	const accountData = useSelector(account.state);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const { setTitle, setImage, setImageAlt } = useContext(ContainerSettings);

	const [isAutoSend, setIsAutoSend] = useState(null);
	const [time, setTime] = useState(null);

	useEffect(() => {
		setTitle("メールを送信しました");
		setImage(<ContactMail />);
		setImageAlt("メールを送信しました");

		if (!accountData.loading) {
			if (accountData.data != null) {
				setIsAutoSend(accountData.data.email_verify_is_auto_send);
				setTime(accountData.data.email_verify_last_request);
			} else {
                navigate(`/login?redirect=${encodeURIComponent(location.pathname)}`);
			}
		}
	}, [setTitle, setImage, setImageAlt, accountData, navigate, location]);

	const {
		setPopWindow,
	} = useContext(AppSettings);

	async function onSubmit(e) {
		e.preventDefault();
		try {
            if (isReset) {
                const response = await axios.post("/auth/email/reset", {
                    "email" : email
                });
                dispatch(account.slice.actions.setData(response.data));
            } else {
                const response = await axios.get("/auth/email/authentication");
                dispatch(account.slice.actions.setData(response.data));
            }
		} catch (error) {
			if (error.response == null) {
				setPopWindow("サーバーから応答がありません。", true);
			} else {
				setPopWindow(error.response.data.error, true);
			}
		}
        dispatch(account.initData());
	}

	return (
		<section className="o14_container">
			<p>認証用のメールを</p>
			{isReset ?
                <p>({email})</p>
                : <p>({accountData.data != null ? accountData.data.email : "error"})</p>
            }
			<p>に送信しました</p>
			<div>
				<h2>メールが届かない場合</h2>
				<p>認証確認メールが届かない場合、以下をご確認ください</p>
				<ul>
					<li>
						<p>
							認証メールは迷惑メールフォルダに振り分けられていたり、フィルター等設定により受信ボックス以外の場所に保管されていないかご確認ください
						</p>
					</li>
					<li>
						<p>お使いのメールアドレスが正しいことを確認してください</p>
					</li>
					<li>
						<p>
							メールの配信に時間がかかる場合がございます、数分程度待った上で再度ご確認ください
						</p>
					</li>
				</ul>
			</div>
			<ReSendButton
				time={time}
				onClick={onSubmit}
				enableMessage="再送信する場合はこちら"
				disableMessage={(t) => { return t + "後に再送信できます。" }}
				reSend
				isAutoSend={isAutoSend}
			/>
		</section>
	);
}
