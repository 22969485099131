import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useLexicalNodeSelection } from '@lexical/react/useLexicalNodeSelection';
import { mergeRegister } from '@lexical/utils';
import { $isListItemNode } from '@lexical/list';
import { $createLineBreakNode, $createParagraphNode, $createRangeSelection, $createTextNode, $getNodeByKey, $getSelection, $isElementNode, $isNodeSelection, $isRangeSelection, $isTextNode, $setSelection, BaseSelection, COMMAND_PRIORITY_LOW, COMMAND_PRIORITY_NORMAL, CONTROLLED_TEXT_INSERTION_COMMAND, KEY_BACKSPACE_COMMAND, KEY_DELETE_COMMAND, KEY_ENTER_COMMAND, NodeKey } from 'lexical';
import { MouseEvent as ReactMouseEvent, Suspense, useCallback, useEffect, useRef, useState, type FC } from 'react';
import { $isImageNode, ImageNode } from './ImageNode';
import ImageResizer from './ImageResizer';

type Props = {
    alt: string;
    height: undefined | number | string;
    src: string;
    width: undefined | number | string;
    nodeKey: NodeKey;
    resizable: boolean;
    maxWidth: string;
}
const imageCache = new Set();
function useSuspenseImage(src: string) {
    if (!imageCache.has(src)) {
        throw new Promise((resolve) => {
            const img = new Image();
            img.src = src;
            img.onload = () => {
                imageCache.add(src);
                resolve(null);
            };
        });
    }
}
function LazyImage({
    altText,
    className,
    imageRef,
    src,
    width,
    height,
    maxWidth,
    onClick,
}: {
    altText: string;
    className: string | null;
    height?: 'inherit' | number | string;
    imageRef: { current: null | HTMLImageElement };
    maxWidth: string;
    src: string;
    width?: 'inherit' | number | string;
    onClick: (event: ReactMouseEvent<HTMLImageElement>) => void;
}): JSX.Element {
    useSuspenseImage(src);
    return (
        <img
            className={className || undefined}
            src={src}
            alt={altText}
            ref={imageRef}
            style={{
                height,
                maxWidth,
                width,
            }}
            onClick={onClick}
        />
    );
}
const ImagePreview: FC<Props> = ({ nodeKey, alt, width, height, src, resizable, maxWidth }) => {
    const [editor] = useLexicalComposerContext();
    const [isResizing, setIsResizing] = useState<boolean>(false);
    const [isSelected, setSelected, clearSelection] = useLexicalNodeSelection(nodeKey);
    const imageRef = useRef<null | HTMLImageElement>(null);
    const [selection, setSelection] = useState<BaseSelection | null>(null);

    const clickEvent = useCallback((event) => {
        event.stopPropagation();
        if (isResizing) {
            return true;
        }
        if (event.target === imageRef.current) {
            if (event.shiftKey) {
                setSelected(!isSelected);
            } else {
                clearSelection();
                setSelected(true);
            }
            return true;
        }

        return false;
    }, [isResizing, isSelected, setSelected, clearSelection],);

    const onResizeEnd = (
        nextWidth?: 'inherit' | number | string,
        nextHeight?: 'inherit' | number | string,
    ) => {
        // Delay hiding the resize bars for click case
        setTimeout(() => {
            setIsResizing(false);
        }, 200);

        editor.update(() => {
            const node = $getNodeByKey(nodeKey);
            if ($isImageNode(node)) {
                node.setWidthAndHeight(nextWidth, nextHeight);
            }
        });
    };

    const onResizeStart = () => {
        setIsResizing(!isResizing);
    };

    const onDelete = useCallback(
        (payload: KeyboardEvent) => {
            const selection = $getSelection();
            editor.update(() => {
                if (selection !== null) {
                    if ($isNodeSelection(selection)) {
                        const node = $getNodeByKey(nodeKey);
                        if (node instanceof ImageNode) {
                            node?.remove();
                            return true;
                        }
                    }
                    if (!$isRangeSelection(selection)) {
                        console.log("RangeSelection Error");
                        return false;
                    }
                    // 現在のフォーカスノードを取得
                    const focusNode = selection.focus.getNode();
                    const focusOffset = selection.focus.offset;

                    if ($isTextNode(focusNode) && focusOffset === 0) {
                        //テキストノードの直前に画像ノードがある場合
                        const previousSibling = focusNode.getTopLevelElement()?.getPreviousSibling();

                        if (previousSibling && $isElementNode(previousSibling)) {
                            const imageNode = previousSibling.getFirstDescendant();

                            if ($isImageNode(imageNode)) {
                                payload.preventDefault(); // デフォルトのDelete動作を防止
                                previousSibling.remove();
                                return true;
                            }
                        }
                    }
                    //ImageNodeを持つParagraphNodeの先頭にキャレットがあるときの処理
                    if ($isElementNode(focusNode) && ($isImageNode(focusNode?.getChildren()[0])) && focusOffset === 0) {

                        const prevNode = focusNode?.getPreviousSibling();
                        if (prevNode && $isElementNode(prevNode)) {
                            const childrenNode = prevNode?.getChildren();
                            if (childrenNode && !$isImageNode(prevNode.getFirstDescendant())) {

                                const childrenLength = childrenNode.length - 1;
                                if (childrenLength >= 0) {
                                    const lastChildNode = childrenNode[childrenLength];

                                    if (lastChildNode && $isTextNode(lastChildNode)) {
                                        payload.preventDefault();
                                        const newSelection = $createRangeSelection();
                                        const textLength = lastChildNode.getTextContent().length;
                                        newSelection.setTextNodeRange(lastChildNode, textLength, lastChildNode, textLength);
                                        $setSelection(newSelection);
                                        return true;
                                    } else if (lastChildNode && $isListItemNode(lastChildNode)) {
                                        payload.preventDefault();
                                        const newSelection = $createRangeSelection();
                                        const textLength = lastChildNode.getTextContent().length;
                                        const listItemChildren = lastChildNode.getChildren()[0];
                                        if ($isTextNode(listItemChildren)) {
                                            newSelection.setTextNodeRange(listItemChildren, textLength, listItemChildren, textLength);
                                        }
                                        $setSelection(newSelection);
                                        return true;
                                    }
                                } else {
                                    prevNode.remove();
                                    return true;
                                }
                            }
                        }
                        return false;
                    }
                    return false;
                }
            });
            return true;
        },
        [editor, nodeKey],
    );

    const onEnter = useCallback(
        (event: KeyboardEvent) => {
            const selection = $getSelection();
            if (!$isRangeSelection(selection) || event == null) {
                return false;
            }

            editor.update(() => {
                if (selection !== null) {

                    const focusNode = selection.focus.getNode();
                    const offset = selection.focus.offset;
                    const node = selection.getNodes()[0];

                    if (event.shiftKey && !$isImageNode(node)) {
                        event.preventDefault();
                        // Shift + Enterの場合、改行ノードを挿入
                        const breakNode = $createLineBreakNode();
                        selection.insertNodes([breakNode]);
                        return true;
                    } else if ($isImageNode(node)) {
                        // テキストノード以外でEnterが押された場合、新しいパラグラフを挿入
                        const newParagraphNode = $createParagraphNode();
                        const newTextNode = $createTextNode();
                        newParagraphNode.append(newTextNode);
                        if (focusNode !== null) {
                            event.preventDefault();
                            const parentNode = focusNode.getParent();
                            if (parentNode !== null && offset === 0) {
                                // フォーカスがあるノードの先頭でEnterが押された場合、そのノードの前に挿入
                                focusNode.getTopLevelElementOrThrow().insertBefore(newParagraphNode);
                            } else {
                                // それ以外の場合は、フォーカスノードの後に挿入
                                focusNode.getTopLevelElementOrThrow().insertAfter(newParagraphNode);
                            }
                            // キャレットを新しいパラグラフノードに移動
                            const newSelection = $createRangeSelection();
                            newSelection.setTextNodeRange(newTextNode, 0, newTextNode, 0);
                            $setSelection(newSelection);
                            return true;
                        }
                    }
                    return false;
                }
            });

            return true;
        }, [editor]
    );


    useEffect(() => {
        let isMounted = true;
        
        const unregister = mergeRegister(
            editor.registerUpdateListener(({ editorState }) => {
                if (isMounted) {
                    setSelection(editorState.read(() => $getSelection()));
                }
            }),
            editor.registerCommand(
                CONTROLLED_TEXT_INSERTION_COMMAND,
                () => {
                    const selection = $getSelection();
                    if (selection === null) return true;
                    if (selection.getNodes().length === 1 && selection.getNodes()[0] instanceof ImageNode) {
                        return true;
                    }
                    return false;
                },
                COMMAND_PRIORITY_NORMAL
            ),
            editor.registerCommand(
                KEY_ENTER_COMMAND,
                onEnter,
                COMMAND_PRIORITY_LOW
            ),
            editor.registerCommand(
                KEY_DELETE_COMMAND,
                onDelete,
                COMMAND_PRIORITY_NORMAL,
            ),
            editor.registerCommand(
                KEY_BACKSPACE_COMMAND,
                onDelete,
                COMMAND_PRIORITY_NORMAL,
            ),
        );
        return () => {
            isMounted = false;
            unregister();
        };
    }, [editor, clickEvent, onDelete, onEnter]);

    const draggable = isSelected && $isNodeSelection(selection) && !isResizing;
    const isFocused = isSelected || isResizing;

    return (
        <>
            <Suspense fallback={null}>
                <>
                    <div className='image-resizer-container' draggable={draggable}>
                        <LazyImage
                            className={
                                isFocused && editor._editable
                                    ? `focused draggable`
                                    : null
                            }
                            src={src}
                            altText=""
                            imageRef={imageRef}
                            width={width}
                            height={height}
                            maxWidth={maxWidth}
                            onClick={clickEvent}
                        />
                    </div>
                    {editor._editable && resizable && isFocused && $isNodeSelection(selection) && (
                        <ImageResizer
                            editor={editor}
                            imageRef={imageRef}
                            maxWidth={maxWidth}
                            onResizeStart={onResizeStart}
                            onResizeEnd={onResizeEnd}
                        />
                    )}
                </>
            </Suspense>
        </>
    );
};
export default ImagePreview;