//コンポーネントID : m28
//マークアップ作成者：アミン

import React, { useEffect, useRef, useState } from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
// import { ReactComponent as Bookmark } from "../../../assets/bookmark.svg";
import { ReactComponent as Close } from "../../../assets/close.svg";
// import { ReactComponent as Like } from "../../../assets/like.svg";
// import { ReactComponent as ShareIcon } from "../../../assets/share.svg";

export default function ProjectCard({ onPortfolioDelete, isEdit, project, onClickProject }) {

    const thumbnail = project.thumbnail !== null ? process.env.REACT_APP_PUBLIC_BACKEND_URL + project.thumbnail : "/DefaultImage/DefaultProjectThumbnail.png";
    const title = project.project_name;
    const technologies = project.technologies.map(t => t.name);
    const languages = project.languages.map(l => l.name);
    const tools = technologies.concat(languages);
    const userIcon = project.icon !== null ? process.env.REACT_APP_PUBLIC_BACKEND_URL + project.icon : "/DefaultImage/DefaultProfile.png";
    const userName = project.nickname;
    const userUrl = `/user/${project.user_id}`;
    // const progress = project.progress;
    // const liked = project.favorite_count;
    const [tiles, setTiles] = useState([]);
    const toolRef = useRef();
    const toolSpace = 4;
    const [otherTool, setOtherTool] = useState(-1);

    useEffect(() => {
        const newTiles = tools.map((tool, index) => (
            <div {...(!isEdit ? { as: Link, to: "#" } : {})} key={index} className="tool_tile">{tool}</div>
        ));

        // 現在のtiles状態と異なる場合のみ更新
        if (JSON.stringify(tiles) !== JSON.stringify(newTiles)) {
            setTiles(newTiles);
        }
    }, [tools, isEdit, tiles]);

    useEffect(() => {
        let w = 0 - toolSpace;
        let del = false;
        let delC = 0;
        let deleteChild = [];
        for (const child of toolRef.current.children) {
            if (!del) {
                w += toolSpace + child.getBoundingClientRect().width;
                if (w > 171.500) {
                    del = true;
                    deleteChild.push(child);
                }
            } else {
                deleteChild.push(child);
            }
        }
        if (del) {
            for (const child of deleteChild) {
                child.remove();
                delC++;
            }
            setOtherTool(delC);
        }
    }, [tiles, toolRef]);

    // function likedToString() {
    //     if (liked < 1000) {
    //         return liked;
    //     } else if (liked < 1000000) {
    //         return (liked / 1000).toFixed(0) + "k";
    //     } else {
    //         return (liked / 1000000).toFixed(1) + "m";
    //     }
    // }

    return (
        <div className="m28_card" onClick={onClickProject ? () => onClickProject(project) : undefined}>
            <Card>
                {!isEdit && !onClickProject && <Link to={`/project/${project.id}`}></Link>}
                {onPortfolioDelete && <button type="button" className="delete_button" onClick={onPortfolioDelete} data-dndkit-disabled-dnd-flag="true"><Close /></button>}
                <Card.Img variant="top" src={thumbnail} className="thumbnail_image" />
                <Card.Body>
                    <h1>{title}</h1>
                    <div>
                        <div className="tools" ref={toolRef}>
                            {tiles.map((tile, index) => (
                                <React.Fragment key={index}>{tile}</React.Fragment>
                            ))}
                            {
                                otherTool <= 0 ? null :
                                    <p>{"+" + otherTool}</p>
                            }
                        </div>

                        {!isEdit ?
                            <Link to={userUrl} className="user">
                                <img src={userIcon} alt="userIcon" />
                                <p>{userName}</p>
                            </Link> :
                            <div className="user">
                                <img src={userIcon} alt="userIcon" />
                                <p>{userName}</p>
                            </div>
                        }
                        {/* <div className="other">
                        <div className={"project_progress" + (progress ? "" : " end")}>
                            <p>{progress ? "進行中" : "終了"}</p>
                        </div>
                        <div className="right_menu">
                            <button type="button" onClick={null} data-dndkit-disabled-dnd-flag="true">
                                <ShareIcon />
                            </button>
                            <button type="button" onClick={null} data-dndkit-disabled-dnd-flag="true">
                                <Bookmark />
                            </button>
                            <button type="button" onClick={null} className="like" data-dndkit-disabled-dnd-flag="true">
                                <div>
                                    <Like />
                                </div>
                                <p>{likedToString(liked)}</p>
                            </button>
                        </div>
                    </div> */}
                    </div>
                </Card.Body>
            </Card>
        </div >
    )
};
