//コンポーネントID : o7
// マークアップ担当者：鶴岡
//ヘッダー

import React, { useEffect, useRef, useState } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
// import SearchBox from "../atom/SearchBox.jsx";
// import { ReactComponent as Bell } from "../../assets/bell.svg";
// import { ReactComponent as Mail } from "../../assets/mail.svg";
import { ReactComponent as AddProject } from "../../assets/add_project.svg";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/faro_logo.svg";
import account from "../../reducers/accountSlice.jsx";
import { useSelector } from "react-redux";
import UserIcon from "../atom/UserIcon";
import { Button } from "react-bootstrap";
import GuestCreateProjectModal from "./modal/GuestCreateProjectModal";

export default function Header() {
    const accountData = useSelector(account.state);
    const [show, setShow] = useState(false);
    const ref = useRef(null);
    const [openModal, setOpenModal] = useState(false);


    const handleClick = () => {
        setShow(!show);
    };

    const deleteClick = (e) => {
        if (!ref.current.contains(e.target)) {
            const toggle = document.getElementsByClassName("navbar-toggler");
            toggle[0].setAttribute("id", "delete");
            document.getElementById("delete").click();
            return;
        }
    };

    useEffect(() => {
        if (show) {
            document.addEventListener("mousedown", deleteClick);
            return () => {
                document.removeEventListener("mousedown", deleteClick);
            };
        }
    }, [show]);

    return (
        <Navbar expand="md" className="bg-body-tertiary o7_navbar" >
            <Container fluid className="md-0" ref={ref}>
                <Navbar.Brand as={Link} to="/">
                    <Logo />
                </Navbar.Brand>
                <Navbar.Toggle className="pe-0" onClick={() => handleClick()} />
                <Navbar.Collapse>
                    {/* <SearchBox /> */}
                    <Nav className="m10_nav my-2 my-lg-0">
                        {accountData.data != null ?
                            <>
                                <Nav.Link className="d-flex align-items-center column-gap-2 column-gap-md-0" as={Link} to={"/project/create"}>
                                    <AddProject />
                                    <p className="m-0">プロジェクトを作成</p>
                                </Nav.Link>
                                {/* <Nav.Link className="d-flex align-items-center position-relative column-gap-2 column-gap-md-0" as={Link} to="#">
                                    <Mail />
                                </Nav.Link>
                                <Nav.Link className="d-flex align-items-center position-relative column-gap-2 column-gap-md-0" as={Link} to="#">
                                    <Bell />
                                </Nav.Link> */}
                                < UserIcon />
                            </>
                            :
                            <>
                                <Button className="o7_button d-flex align-items-center column-gap-2 column-gap-md-0" onClick={() => setOpenModal(true)}>
                                    <AddProject />
                                    <p className="m-0">プロジェクトを作成</p>
                                </Button>
                                <GuestCreateProjectModal
                                    open={openModal}
                                    setOpen={setOpenModal}
                                />
                                <Nav.Link className="d-flex" as={Link} to={"/register"}>
                                    アカウント登録
                                </Nav.Link>
                                <Nav.Link as={Link} to={"/login"}>
                                    ログイン
                                </Nav.Link>
                            </>
                        }
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}
