//コンポーネントID : o40
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import ProjectListModalBody from "../../molecules/modalBody/ProjectListModalBody";
import SelectAddTypeModalBody from "../../molecules/modalBody/SelectAddTypeModalBody";
import ProjectCreateForm from "../../page/project/ProjectCreateForm";
import ModalTemplate from "./ModalTemplate";

export const modalBody = {
    addType: 1,
    projectList: 2,
    createProject:3
};
export default function AddProjectManagementModal({ open, setOpen }) {

    const [activeModal, setActiveModal] = useState(modalBody.addType);

    useEffect(() => {
        if (open) setActiveModal(modalBody.addType);
    }, [open])
    
    // activeModalに基づいてModalTemplateのsizeを動的に決定
    const modalSize = (activeModal === modalBody.projectList || activeModal === modalBody.createProject) ? 'xl' : 'lg';

    function renderModalContent() {
        switch (activeModal) {
            case 1:
                return <SelectAddTypeModalBody setCreateModal={() => setActiveModal(modalBody.createProject)} Listopen={() => setActiveModal(modalBody.projectList)} />;
            case 2:
                return <ProjectListModalBody />;
            case 3:
                return (
                    <Modal.Body >
                        <ProjectCreateForm />
                    </Modal.Body>
                );
            default:
                return <div></div>;
        }
    };

    return (
        <>
            <ModalTemplate
                size={modalSize}
                open={open}
                setOpen={setOpen}
                title={activeModal === modalBody.projectList ? "追加するプロジェクトを選んでください" : false}
                backClick={activeModal === modalBody.projectList ? () => setActiveModal(modalBody.addType) : null}
                backdrop={activeModal === modalBody.createProject ? true : false}
            >
                {renderModalContent()}
            </ModalTemplate >
        </>
    );
};

