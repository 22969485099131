import React, { useContext, useEffect, useState } from "react";
import RegisterMain from "../organisms/register/RegisterMain";
import AccountNickName from "../organisms/register/AccountNickName";
import AccountScout from "../organisms/register/AccountScout";
import RegisterSendEmail from "../organisms/register/RegisterSendEmail";
import { ContainerSettings } from "./layout/ContainerLayout";
import account from "../../reducers/accountSlice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import RegisterSuccess from "../organisms/register/RegisterSuccess";
import { Helmet } from "react-helmet-async";

export default function Register() {
	const { setProgress, setProgressBarSize, page } = useContext(ContainerSettings);
	const navigate = useNavigate();
	const accountData = useSelector(account.state).data;
	const [ navigateFlag, setNavigateFlag ] = useState(true);

    useEffect(() => {
		setProgressBarSize(2);
        setProgress(0);
    }, []);

	useEffect(() => {
        console.log(page);
    }, [page])

	useEffect(() => {
		if (accountData != null && page == 0 && navigateFlag) {
			navigate("/");
		}
	}, [accountData]);

	function getPage() {
		switch (page) {
			case 0: //アカウント登録
				return <RegisterMain setNavigateFlag={ setNavigateFlag } />;
			case 1: //ニックネーム登録
				return <AccountNickName />;
			case 2: //企業からのスカウトを受信しますか？
				// return <AccountScout />;
                return <RegisterSuccess />;
			case 3: //メールを送信しました
				// return <RegisterSendEmail />;
			default:
				return null;
		}
	}

	return (
		<>
			<Helmet>
				<title>新規作成｜Faro</title>
			</Helmet>
			{getPage()}
		</>
	);
}
