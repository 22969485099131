//コンポーネントID : p17
// マークアップ担当者：鶴岡

//ページのアクセストークン期限切れ＆エラー

import React from "react";
import { ReactComponent as ErrorFace } from "../../../assets/error_face.svg";
import { Helmet } from "react-helmet-async";

export default function Error({message,messageJa}) {

  return (
    <>
      <Helmet>
        <title>エラーが発生しました｜Faro</title>
      </Helmet>
      <div className="p17_container">
        <section className="p17_error_box">
          <ErrorFace className="p17_error_logo" />
          <div className="p17_text_box">
            <h3>{message}</h3>
            <p>{messageJa}</p>
          </div>
        </section>
      </div>
    </>
  );
}

