//コンポーネントID : p16

import React from "react";
import Layout from "./Layout";
import { Outlet } from "react-router-dom";

export default function DefaultLayout() {
	return (
		<Layout maxWidthSet>
			<Outlet />
		</Layout>
	);
} 