import {
	$createParagraphNode, $insertNodes, COMMAND_PRIORITY_EDITOR
} from 'lexical';
import { useEffect } from 'react';

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $wrapNodeInElement, mergeRegister } from '@lexical/utils';

import { $createImageNode, ImageNode } from './ImageNode';

import type { FC } from 'react';
import { INSERT_IMAGE_COMMAND, InsertImagePayload } from './InsertImageCommand';

export const ImageRegister: FC = () => {
	const [editor] = useLexicalComposerContext();

	useEffect(() => {
		if (!editor.hasNodes([ImageNode])) {
			throw new Error('ImageRegister: ImageNode not registered on editor');
		}
		return mergeRegister(
			editor.registerCommand<InsertImagePayload>(
				INSERT_IMAGE_COMMAND,
				(payload) => {
					const imageNode = $createImageNode(payload);
					$insertNodes([imageNode]);
					$wrapNodeInElement(imageNode, $createParagraphNode).selectEnd();
					return true;
				},
				COMMAND_PRIORITY_EDITOR,
			),
		);
	}, [editor]);

	return null;
};