//コンポーネントID : p28
// マークアップ担当者：鶴岡

import React, { createContext, useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import SingleMenu from "../../atom/template/SingleMenu";
import Layout from "./Layout";
import { Outlet, useLocation } from "react-router-dom";
import BackImage from "../../atom/BackImage";
import StepProgressBar from "../../atom/StepProgressBar";

export const ContainerSettings = createContext();
export default function ContainerLayout() {

    const location = useLocation();

    const [title, setTitle] = useState("");
    const [progress, setProgress] = useState(0);
    const [progressBarSize, setProgressBarSize] = useState(null);
    const [image, setImage] = useState("");
    const [imageAlt, setImageAlt] = useState("");
    const [page, setPage] = useState(0);

    //url変更時にpageをリセット
    useEffect(() => {
        setPage(0);
    }, [location]);

    function prevPage() {
        setPage(page - 1);
    }

    function nextPage() {
        setPage(page + 1);
    }

    return (
        <Layout>
            <BackImage />
            <Container className="p28_container">
                <Row className="p28_row mx-auto align-content-around">
                    {progressBarSize == null || progressBarSize === undefined || progressBarSize == 0 ? null : (
                        //ProgressBarに置き換え
                        <StepProgressBar progress={progress} size={progressBarSize} />
                    )}
                    {title == null || title === undefined ? null : (
                        <SingleMenu text={title} imagePath={image} imageAlt={imageAlt} />
                    )}
                    <ContainerSettings.Provider
                        value={{
                            setTitle,
                            progress,
                            setProgress,
                            setProgressBarSize,
                            setImage,
                            setImageAlt,
                            page,
                            prevPage,
                            nextPage,
                            setPage,
                        }}>
                        <Outlet />
                    </ContainerSettings.Provider>
                </Row>
            </Container>
        </Layout>
    );
}
