//コンポーネントID : o35
import React, { useContext, useEffect } from "react";
import { Button } from "react-bootstrap";
import { ReactComponent as ContactMail } from "../../../assets/contact_mail.svg";
import { ContainerSettings } from "../../page/layout/ContainerLayout";
import axios from "../../../lib/axios";
import SingleMenu from "../../atom/template/SingleMenu";
import { AppSettings } from "../../../App";
import ReSendButton from "../../molecules/ReSendButton";

export default function SendPasswordURL( { isSend, email, setEmail, time, setTime, isAutoSend, setIsAutoSend } ) {

    const { progress, setProgress, setTitle, setImage, setImageAlt, setProgressBarSize, nextPage } = useContext(ContainerSettings);
    const { setPopWindow } = useContext(AppSettings);

    useEffect(() => {
        setTitle("パスワード再設定用メール送信");
        setImage(<ContactMail />);
        setImageAlt("メールアイコン");
        setProgressBarSize(null);
    }, [setTitle, setImage, setImageAlt, setProgressBarSize]);

    async function onSubmit(e) {
        e.preventDefault();
        try {
            const response = await axios.post("/auth/password/forgot", {
                "email": email
            });
            setTime(response.data.created_at);
            setIsAutoSend(response.data.is_auto_send == 1);
            setProgress(progress + 1);
            if (isSend) {
                return;
            }
            nextPage();
        } catch (error) {
			if (error.response == null) {
				setPopWindow("サーバーから応答がありません。", true);
			} else {
				setPopWindow(error.response.data.error, true);
			}
		}
    }

    return (
        isSend == false ?
            <form onSubmit={onSubmit}>
                <p className="text-left mb-0 o35_p">
                    送信ボタンから登録しているアカウントのメールアドレスにパスワード再設定用のメールを送信してください
                </p>
                <SingleMenu required text="メールアドレス" />
                <input type="email" onChange={(e) => setEmail(e.target.value)}/>
                <Button type="submit">送信</Button>
            </form>
        :
            <section>
                <p>認証用のメールを</p>
                {email ?
                    <p>({email})</p>
                    : <p>入力されたメールアドレス</p>
                }
                <p>に送信しました</p>
                <div>
                    <p className="text-center fw-bold">メールが届かない場合</p>
                    <p>認証確認メールが届かない場合、以下をご確認ください</p>
                    <ul>
                        <li>
                            <p>
                                認証メールは迷惑メールフォルダに振り分けられていたり、フィルター等設定により受信ボックス以外の場所に保管されていないかご確認ください
                            </p>
                        </li>
                        <li>
                            <p>お使いのメールアドレスが正しいことを確認してください</p>
                        </li>
                        <li>
                            <p>
                                メールの配信に時間がかかる場合がございます、数分程度待った上で再度ご確認ください
                            </p>
                        </li>
                    </ul>
                    <p>&#8251;Faroに登録されていないメールアドレスにはメールが送信されません。Faroに登録したアカウントと同一のメールアドレスかご確認ください。</p>
                </div>
                <ReSendButton
                    time={time}
                    onClick={onSubmit}
                    enableMessage="再送信する場合はこちら"
                    disableMessage={(t) => { return t + "後に再送信できます。" }}
                    reSend
                    isAutoSend={isAutoSend}
                />
            </section>
    );
}
