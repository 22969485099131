//コンポーネントID : p9

import { createContext, useContext, useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AppSettings } from "../../App";
// import { ReactComponent as Share } from "../../assets/share.svg";
import axios from "../../lib/axios";
import account from "../../reducers/accountSlice";
import AccountSNSList from "../molecules/AccountSNSList";
import Tabs from "../molecules/Tabs";
// import PortfolioShareModal from "../organisms/modal/PortfolioShareModal";
import Portfolio from "../organisms/userpage/Portfolio";
import Project from "../organisms/userpage/Project";
import Error from "./error/Error";

export const UserPageItems = createContext();
export default function UserPage({ tab }) {
    //モーダルの開閉状態管理
    // const [openModal, setOpenModal] = useState(false);
    const navigate = useNavigate();
    const { id } = useParams();
    const printRef = useRef();
    const { setPopWindow } = useContext(AppSettings);
    const accountData = useSelector(account.state);
    const [userIcon, setUserIcon] = useState(null);
    const tabs = ["プロジェクト一覧", "ポートフォリオ"];

    const [userPageData, setUserPageData] = useState(null);

    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {

        // ユーザーページのAPIに関する関数
        async function fetchData() {
            try {
                const userData = await axios.get(`/api/user/${id}`);
                const projectDatas = await axios.post(`/api/projects`, userData.data.projects);
                const projectsTagsData = await axios.post(`/api/projects/tags`, userData.data.projects);
                projectDatas.data.forEach(project => {
                    // projectsTagsData.dataから対応するプロジェクトのタグ情報を検索
                    const tagData = projectsTagsData.data.find(tag => tag.id === project.id);
                    // 対応するタグデータが見つかった場合、languagesとtechnologiesをプロジェクトオブジェクトに追加
                    if (tagData) {
                        project.languages = tagData.languages;
                        project.technologies = tagData.technologies;
                    }
                });
                // 結果の確認
                userData.data.projects = projectDatas.data;
                setUserPageData(userData.data);
                const icon = userData.data.icon == null ? "/DefaultImage/DefaultProfile.png" : process.env.REACT_APP_PUBLIC_BACKEND_URL + userData.data.icon
                setUserIcon(icon);
            } catch (error) {
                // エラー処理
                setPopWindow("読み込みに失敗しました", true);
                return <Error message={"404 Not Found"} messageJa={"お探しのページは見つかりません"} />;
            }
        }
        fetchData();
    }, [id, setPopWindow]);

    // プロジェクト一覧とポートフォリオを切り替えるタブの関数
    function setActiveTab(tabId) {
        if (tab === tabId) return;
        if (tabId === 0) {
            navigate(`/user/${id}`);
        } else if (tabId === 1) {
            setIsEdit(false);
            navigate(`/user/${id}/portfolio`);
        }
    }

    // 編集ボタンの表示関数
    function showEditButton() {
        if (!accountData.Loading && accountData.data != null
            && accountData.data.id === parseInt(id)
            && tab === 1) {
            return (
                <>
                    <div className="d-flex flex-row-reverse mb-4">
                        <Button onClick={() => setIsEdit(!isEdit)} className="p9_edit_button">
                            {isEdit ? "保存する" : "ポートフォリオ編集"}
                        </Button>
                    </div>
                </>
            );
        } else {
            return null;
        }
    }

    return (
        <div className="p9_main_container" ref={printRef}>
            {userPageData != null ?
                <>
                    <div className="back_img">
                        <img src={userIcon} alt="user icon" />
                    </div>
                    <div className="p9_user_info d-flex align-items-start justify-content-between">
                        <div className="p9_user_mypage d-flex">
                            <img src={userIcon} alt="user icon" />
                            <div className="p9_name_info">
                                <div className="name_list">
                                    {/* ユーザー名 */}
                                    <p>{userPageData.tag_name}</p>
                                    {/* ユーザー職業 */}
                                    <h4>{userPageData.nickname}</h4>
                                </div>
                                {/* <Button className="share_button" onClick={() => setOpenModal(true)}>
                                    共有
                                    <Share />
                                </Button>  */}
                            </div>
                        </div>
                        <AccountSNSList sns={userPageData.sns} />
                    </div>
                </>
                :
                null
            }
            {/* 編集ボタンの表示関数 */}
            {showEditButton()}
            <Tabs tabs={tabs} activeTab={tab} setActiveTab={setActiveTab} />
            <UserPageItems.Provider
                value={{
                    id,
                    isEdit,
                    setIsEdit,
                    userPageData,
                    setUserPageData,
                    accountData
                }}>
                {tab === 0 ? <Project /> : <Portfolio />}
            </UserPageItems.Provider>
        </div>
    );
}
