//コンポーネントID : o37
// マークアップ担当者：鶴岡

//ユーザーマイページ
//ポートフォリオ

import { createContext, useContext, useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { AppSettings } from "../../../App";
import axios from "../../../lib/axios";
import account from "../../../reducers/accountSlice";
import SingleMenu from "../../atom/template/SingleMenu";
import ItemNone from "../../molecules/ItemNone";
import UserKnowledge from "../../molecules/UserKnowledge";
import ProjectCardList from "../../molecules/list/ProjectCardList";
import { UserPageItems } from "../../page/UserPage";
import useUnloadBlocker from "../UnloadBlocker";
import AddProjectManagementModal from "../modal/AddProjectManagementModal";
import { Helmet } from "react-helmet-async";

export const PortfolioItems = createContext();
export default function Portfolio() {

    const accountData = useSelector(account.state);

    const { id, isEdit, userPageData, setUserPageData } = useContext(UserPageItems);

    const [openModal, setOpenModal] = useState(false);
    const [projectList, setProjectList] = useState(null);
    const [updateMyPageData, setUpdateMyPageData] = useState(null);
    useEffect(() => {
        if (userPageData == null || userPageData.projects == null) return;
        const filteredPortfolioProjects = userPageData.projects.filter((project) => project.position_index > -1).sort((a, b) => a.position_index - b.position_index);
        setProjectList(userPageData.projects);
        setUpdateMyPageData({ ...userPageData, projects: filteredPortfolioProjects });
    }, [userPageData]);
    const { setPopWindow } = useContext(AppSettings);

    function hasDataChanged(source, target) {
        return JSON.stringify(source) !== JSON.stringify(target);
    }
    function useConditionalEffect(isEdit, callback) {
        const isFirstRender = useRef(true);
        const prevIsEdit = useRef(isEdit);

        useEffect(() => {
            if (isFirstRender.current) {
                isFirstRender.current = false;
            } else if (prevIsEdit.current !== isEdit) {
                callback();
            }
            prevIsEdit.current = isEdit;
        }, [isEdit, callback]);
    }

    async function edit() {
        if (!isEdit && updateMyPageData !== null) {
            const filteredPortfolioProjects = userPageData.projects.filter((project) => project.position_index > -1).sort((a, b) => a.position_index - b.position_index);
            if (hasDataChanged(updateMyPageData.qualifications, userPageData.qualifications) ||
                hasDataChanged(updateMyPageData.languages, userPageData.languages) ||
                hasDataChanged(updateMyPageData.technologies, userPageData.technologies) ||
                hasDataChanged(updateMyPageData.public_relations, userPageData.public_relations) ||
                hasDataChanged(updateMyPageData.projects, filteredPortfolioProjects)) {

                if (updateMyPageData.public_relations != null && updateMyPageData.public_relations.length > 300) {
                    alert("自己PRが規定の文字数を超えています");
                    return false;
                }
                // 新しい状態を一時的な変数に保持
                const newQualifications = updateMyPageData.qualifications.filter((item) => item.name !== "");
                const newLanguages = updateMyPageData.languages.filter((item) => item.name !== "");
                const newTechnologies = updateMyPageData.technologies.filter((item) => item.name !== "");
                const newPublicRelation = updateMyPageData.public_relations;

                // 状態を更新
                setUpdateMyPageData({
                    ...updateMyPageData,
                    public_relations: newPublicRelation,
                    qualifications: newQualifications,
                    languages: newLanguages,
                    technologies: newTechnologies
                });
                // ポートフォリオを編集するAPI叩く
                try {
                    await axios.post("/api/mypage/edit", {
                        "project_ids": updateMyPageData.projects.map(item => item.id),
                        "public_relations": newPublicRelation,
                        "qualifications": newQualifications.map(item => item.name),
                        "languages": newLanguages.map(item => item.name),
                        "technologies": newTechnologies.map(item => item.name)
                    });
                    setPopWindow("保存に成功しました！");
                    setUserPageData({
                        ...userPageData,
                        projects: [...projectList],
                        public_relations: newPublicRelation,
                        qualifications: newQualifications,
                        languages: newLanguages,
                        technologies: newTechnologies
                    });
                } catch (error) {
                    setPopWindow(error.response.data.error, true);
                    return false;
                }
            }
        }
    }

    useConditionalEffect(isEdit, () => {
        edit();
    });


    function activeModal() {
        setOpenModal(true);
    }

    // 自己PRの編集関数
    function handlePRUpdate(skill) {
        setUpdateMyPageData({ ...updateMyPageData, public_relations: skill });
    };

    function PRTextLength() {
        if (updateMyPageData.public_relations != null) {
            return (
                <span style={updateMyPageData.public_relations.length > 300 ? { color: 'red' } : null}>
                    {updateMyPageData.public_relations.length}
                </span>
            );
        } else {
            return 0;
        }
    }

    function onPortfolioDelete(deleteProject) {
        //portfolioにないことを表すPosition_index
        const positionIndex = -1;
        const updatedPositionIndex = projectList.map((project) => {
            if (project.id === deleteProject.id) {
                return { ...project, position_index: positionIndex };
            }
            return project;
        });
        setProjectList(updatedPositionIndex);
        // projectIndexで送られてきたプロジェクトを配列から除外した定数
        const updatedProjects = updateMyPageData.projects.filter(
            (project) => project.id !== deleteProject.id
        );
        setUpdateMyPageData({
            ...updateMyPageData,
            projects: updatedProjects
        });
    }

    function onPortfolioDrag(event) {

        // ドラッグイベントからアクティブなアイテムとオーバーされているアイテムのIDを取得
        // active:ドラッグされたアイテム
        // over:ドラッグされたアイテムが越えようとしているアイテム
        const { active, over } = event;

        if (active.id !== over.id) {
            const oldIndex = updateMyPageData.projects.findIndex(
                (item) => item.id === active.id
            );
            const newIndex = updateMyPageData.projects.findIndex(
                (item) => item.id === over.id
            );
            const newItems = updateMyPageData.projects.map(item => ({ ...item }));
            const [movedItem] = newItems.splice(oldIndex, 1);
            // アイテムの位置を更新
            // oldIndex の位置からアイテムを削除し、削除されたアイテムを取得
            // newIndex の位置にアイテムを挿入
            newItems.splice(newIndex, 0, movedItem);
            // position_index の更新
            newItems.forEach((item, index) => {
                item.position_index = index;  // 新しいインデックスを position_index として設定
            });
            setUpdateMyPageData({
                ...updateMyPageData,
                projects: [...newItems]
            });
            handleUpdatePosition(newItems);
        }
    }

    function updateProjectListPosition(newItems, projectList) {
        // newItemsに基づいてprojectListのposition_indexを更新
        const updatedProjectList = projectList.map(project => {
            // newItemsの中で現在のプロジェクトのidを探し、そのインデックスを取得
            const newIndex = newItems.findIndex(item => item.id === project.id);
            // newIndexが見つかった場合、その値でposition_indexを更新
            // 見つからない場合は-1を設定
            return { ...project, position_index: newIndex };
        });

        return updatedProjectList;
    }

    function handleUpdatePosition(newItems) {
        const newProjectList = updateProjectListPosition(newItems, projectList);
        setProjectList(newProjectList);
    }


    useUnloadBlocker(() => {
        const filteredPortfolioProjects = userPageData.projects.filter((project) => project.position_index > -1).sort((a, b) => a.position_index - b.position_index);
        return (hasDataChanged(updateMyPageData.qualifications, userPageData.qualifications) ||
            hasDataChanged(updateMyPageData.languages, userPageData.languages) ||
            hasDataChanged(updateMyPageData.technologies, userPageData.technologies) ||
            hasDataChanged(updateMyPageData.public_relations, userPageData.public_relations) ||
            hasDataChanged(updateMyPageData.projects, filteredPortfolioProjects));
    });

    return (
        <>
            <Helmet>
                <title>マイページ - ポートフォリオ｜Faro</title>
            </Helmet>
            <div className="o37_info_container">
                {!updateMyPageData ? (
                    <>Loading...</> // ローディング表示
                ) : (
                    <PortfolioItems.Provider
                        value={{
                            updateMyPageData,
                            setUpdateMyPageData,
                            projectList,
                            setProjectList
                        }}>
                        <div className="justify-content-between o37_top_content">
                            {/* 自己PR */}
                            <div className="o37_public_relations">
                                <SingleMenu text="自己PR" />
                                {isEdit ? (
                                    <>
                                        <Form.Control
                                            as="textarea"
                                            name="content"
                                            value={updateMyPageData.public_relations == null ? '' : updateMyPageData.public_relations}
                                            onChange={(e) =>
                                                handlePRUpdate(e.target.value)
                                            }
                                            className="o37_PR_text"
                                            rows="5"
                                        />
                                        <p className="text-end mt-2">
                                            {PRTextLength()}/
                                            300
                                        </p>
                                    </>
                                ) : (
                                    <p className="o37_PR_text">{updateMyPageData.public_relations}</p>
                                )}
                            </div>
                            {/* 資格情報など */}
                            <UserKnowledge />
                        </div>
                        <div className="o37_item_lists">
                            <SingleMenu text="アイテム一覧" />
                            {projectList == null || projectList.length !== 0 ?
                                <ProjectCardList
                                    createButton={accountData.data != null && accountData.data.id === parseInt(id)}
                                    isEdit={isEdit}
                                    projects={updateMyPageData.projects}
                                    onPortfolioDelete={onPortfolioDelete}
                                    onPortfolioDrag={onPortfolioDrag}
                                    activeModal={activeModal}
                                /> :
                                <ItemNone id={id} activeModal={activeModal} />
                            }
                            <AddProjectManagementModal
                                open={openModal}
                                setOpen={setOpenModal}
                            />
                        </div>
                    </PortfolioItems.Provider>
                )}
            </div>
        </>
    );
}
