//コンポーネントID : o31
// マークアップ作成者：鶴岡

import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as UserCircle } from "../../../assets/user_circle.svg";
// import Provider from "../../molecules/Provider";
import SingleMenu from "../../atom/template/SingleMenu";
import { ContainerSettings } from "../../page/layout/ContainerLayout";
import { Button } from "react-bootstrap";
import axios from "../../../lib/axios";
import { useState } from "react";
import Validation from "../../molecules/Validation";
import { AppSettings } from "../../../App";
import { useDispatch } from "react-redux";
import account from "../../../reducers/accountSlice";

export default function RegisterMain({ setNavigateFlag }) {

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [agree, setAgree] = useState(false);
	const dispatch = useDispatch();

	const validation0 = /[A-Z]/;
	const validation1 = /[a-z]/;
	const validation2 = /[0-9]/;
	const validation3 = /.{8,}/;

	const {
		progress,
		setProgress,
		setTitle,
		setImage,
		setImageAlt,
		nextPage,
	} = useContext(ContainerSettings);

	const {
		setPopWindow,
	} = useContext(AppSettings);

	useEffect(() => {
		setTitle("アカウント登録");
		setImage(<UserCircle />);
		setImageAlt("アカウント登録");
	}, [setTitle, setImage, setImageAlt]);

	async function onSubmit(e) {
		e.preventDefault();
		if(password !== confirmPassword) {
			setPopWindow("パスワードと確認用パスワードが一致していません。",true);
			return;
		}
		if(agree !== true) {
			setPopWindow("利用規約、プライバシーポリシーに同意する必要があります。",true);
			return;
		}
		try {
			setNavigateFlag(false);
			const response = await axios.post("/auth/register", {
				"email": email,
				"password": password,
			});
			dispatch(account.slice.actions.setData(response.data));
			setProgress(progress + 1);
			nextPage();
			setNavigateFlag(true);
		} catch (error) {
			setPopWindow(error.response.data.error,true);
		}
	}

	return (
		<form className="o32_container" onSubmit={onSubmit}>
			<SingleMenu required text="メールアドレス" />
			<input type="email" onChange={(e) => setEmail(e.target.value)} />
			<SingleMenu required text="パスワード" />
			<input type="password" onChange={(e) => setPassword(e.target.value)} />
			<div className="validation">
				<Validation text="大文字1文字以上" check={validation0.test(password)} />
				<Validation text="小文字1文字以上" check={validation1.test(password)} />
				<Validation text="数字1文字以上" check={validation2.test(password)} />
				<Validation text="8文字以上" check={validation3.test(password)} />
			</div>
			<SingleMenu required text="確認用パスワード" />
			<input type="password" onChange={(e) => setConfirmPassword(e.target.value)} />
			<Link to="/Login">すでにアカウントをお持ちの方はこちら{">>"}</Link>
			<div className="agree_box">
				<input type="checkbox" onChange={(e)=>setAgree(e.target.checked)}/>
				<p>
					<a href="/terms" target="_blank">利用規約</a>、<a href="/privacypolicy" target="_blank">プライバシーポリシー</a>
					に同意する
				</p>
			</div>
			<Button type="submit">次へ</Button>
			{/* <Provider
				register
				orBar
			/> */}
		</form>
	);
}
