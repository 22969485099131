//コンポーネントID : o6
// マークアップ担当者：鶴岡

//フッター(コンポーネントID：o6)

import React from "react";
import { Nav } from "react-bootstrap";
import { Link } from 'react-router-dom';

export default function Footer() {
    return (
        <footer className="o6_footer w-100 h-auto d-flex align-items-center flex-column position-sticky top-100">
            <div className="d-flex pt-5 align-items-center justify-content-center flex-column flex-sm-row">
                <img src={"/icon_256x256.png"} alt="フッターの画像" />
                <Nav className="o6_nav d-flex justify-content-center">
                    <Nav.Link className="position-relative" as={Link} to={"/terms"}>利用規約</Nav.Link>
                    <Nav.Link className="position-relative" as={Link} to={"/guide"}>ご利用ガイド</Nav.Link>
                    <Nav.Link className="position-relative" as={Link} to={"/faq"}>よくある質問</Nav.Link>
                    <Nav.Link className="position-relative" as={Link} to={"/privacypolicy"}>プライバシーポリシー</Nav.Link>
                    <Nav.Link className="position-relative" as={Link} to={"/contact"}>お問い合わせ</Nav.Link>
                </Nav>
            </div>
            <p>&copy; Dreamcareer Co.,Ltd.</p>
        </footer>
    );
}
