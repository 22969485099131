//コンポーネントID : o33

import React, { useContext, useEffect } from "react";
import { ContainerSettings } from "../../page/layout/ContainerLayout";
import CompleteAnimation from "../CompleteAnimation";
import { Link } from "react-router-dom";

export default function RegisterSendAuthSuccess() {
	const {
		setTitle,
		setImage,
		setImageAlt,
		setProgressBarSize,
	} = useContext(ContainerSettings);

	useEffect(() => {
		setProgressBarSize(null);
		setTitle("メールアドレスの認証");
		setImage(<CompleteAnimation />);
		setImageAlt("メールアドレスの認証");
	}, [setTitle, setImage, setImageAlt,setProgressBarSize]);

	return (
		<section className="o33_container">
			<p>
				メールアドレス認証が完了しました
			</p>
			<Link to="/">
				メインページへ
			</Link>
		</section>
	);
}
