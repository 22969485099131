//コンポーネントID : a10
import React from "react";
import { ProgressBar, Step } from "react-step-progress-bar";
import { ReactComponent as Check } from "../../assets/check.svg";

export default function StepProgressBar({ progress, size }) {
    var oneStepSize = 100 % size == 0 ? 100 / size : (100 / size) + 1;
    var stepPercentage = progress * oneStepSize;

    return (
        <ProgressBar percent={stepPercentage}>
            <Step>
                {() => (
                    <div
                        className={`indexedStep ${stepPercentage !== 0 ? "accomplished" : null
                            }`}>
                        {stepPercentage !== 0 ? <Check /> : <>{"•"}</>}
                    </div>
                )}
            </Step>
            {Array.from({ length: size - 1 }, (_, i) => (
                <Step>
                    {({ accomplished }) => (
                        <div
                            className={`indexedStep ${accomplished ? "accomplished" : null}`}>
                            {accomplished ? <Check /> : <>{"•"}</>}
                        </div>
                    )}
                </Step>
            ))}
        </ProgressBar>
    );
}
