//コンポーネントID : o1
// マークアップ担当者：鶴岡

//完了画面
//メールアドレス、パスワードの変更、アカウント登録などに使用する
//テキストの内容とリンクと変えて再利用できるようにする

import React from "react";

export default function CompleteAnimation() {
	return (
		<div className="o1_wapper">
			<div className="box"></div>
		</div>
	);
}
