//コンポーネントID : m43
import React, { useId } from "react";
import { Form } from "react-bootstrap";

export default function RadioButton({ defaultValue, options, onChange, name, error }) {
    const id = useId();

    return (
        <>
            {options.map((data, index) => (
                <Form.Check
                    onChange={(e) => { onChange(e, data.id) }}
                    className={"m43_radio" + (error ? " error" : "")}
                    key={index}
                    inline
                    label={data.name}
                    name={name}
                    type="radio"
                    defaultChecked={data.id === defaultValue}
                    id={`m43_id_${id}_${index}`}
                />
            ))}
        </>
    );
}
