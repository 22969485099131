//コンポーネントID : m33
import { useEffect, useRef } from "react";
import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/pagination';
import { Grid, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import ProjectCard from "../card/ProjectCard";

export default function SwiperProjectCardList({ projectDatum, newProject }) {
    const swiperRef = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            if (swiperRef.current) {
                swiperRef.current.swiper.update();
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            {projectDatum &&
                <Swiper
                    slidesPerView={1}
                    grid={{
                        rows: 4,
                    }}
                    spaceBetween={32}
                    pagination={{
                        clickable: true,
                    }}
                    navigation={true}
                    modules={[Navigation, Grid, Pagination]}
                    breakpoints={newProject ?
                        {
                            880: {
                                slidesPerView: 3,
                                grid: { rows: 1 },
                            },
                            1200: {
                                slidesPerView: 4,
                                grid: { rows: 1 }
                            }
                        } : {
                            880: {
                                slidesPerView: 2,
                                grid: { rows: 1 },
                            },
                            1200: {
                                slidesPerView: 3,
                                grid: { rows: 1 }
                            }
                        }}
                        className={`m33_swiper m-auto ${!newProject ? "m33_swiper_genre" : ""}`}
                    ref={swiperRef}
                >
                    {projectDatum.map((project, index) => (
                        <SwiperSlide key={index}>
                            <ProjectCard project={project} />
                        </SwiperSlide>
                    ))}
                </Swiper>
            }
        </>
    );
}
