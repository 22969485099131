//コンポーネントID : m32
import { useState } from "react";
import { Dropdown, ListGroup, Tab } from "react-bootstrap";
import SwiperProjectCardList from "./SwiperProjectCardList";
// import { ReactComponent as ArrowRightTriangle } from '../../../assets/arrow_right_triangle.svg';
import { useEffect } from "react";

export default function SwiperGenreProjectCardList({ projectDatum, genreDatum }) {
    const [selectedGenre, setSelectedGenre] = useState(null);

    useEffect(() => {
        if (genreDatum && genreDatum.length > 0) {
            setSelectedGenre(genreDatum[0].name);
        }
    }, [genreDatum]);

    if (!projectDatum || !genreDatum || genreDatum.length === 0) {
        return null;
    }

    const filteredGenres = genreDatum.filter(genre => projectDatum[`genre_id:${genre.id}`] && projectDatum[`genre_id:${genre.id}`].length > 0);

    return (
        <>
            <Tab.Container mountOnEnter={true} unmountOnExit={true} id="list-group-tabs-example" defaultActiveKey={`#link1`}>
                <ListGroup className="m32_category_list overflow-hidden">
                    <ListGroup.Item disabled className="m32_category_label text-center">
                        人気カテゴリー
                    </ListGroup.Item>
                    {filteredGenres.map((genre, index) => (
                        <ListGroup.Item key={genre.id} eventKey={`#link${index + 1}`} className="text-center">
                            {genre.name}
                        </ListGroup.Item>
                    ))}
                </ListGroup>
                <Dropdown className="m32_category_list_sp bg-transparent shadow-none">
                    <Dropdown.Toggle className="m-auto border-0">
                        カテゴリー - {selectedGenre}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="overflow-hidden">
                        {filteredGenres.map((genre, index) => (
                            <Dropdown.Item key={genre.id} eventKey={`#link${index + 1}`} onClick={() => setSelectedGenre(genre.name)}>
                                {genre.name}
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
                <Tab.Content className="m32_category_content position-relative">
                    {filteredGenres.map((genre, index) => (
                        <Tab.Pane key={genre.id} eventKey={`#link${index + 1}`}>
                            <h3 className="m32_category_title w-100 text-center mb-4">カテゴリー - {genre.name}</h3>
                            <SwiperProjectCardList projectDatum={projectDatum[`genre_id:${genre.id}`]} />
                        </Tab.Pane>
                    ))}
                </Tab.Content>
            </Tab.Container>
        </>
    );
}