import { useCallback, useState } from "react";
import { useBeforeUnload, useBlocker } from "react-router-dom";

export function useUnloadBlocker(checker: () => boolean) {
    const [blocker, setBlocker] = useState<boolean>(true);

    useBlocker(
        ({ currentLocation, nextLocation }) => {
            if (!blocker) return false;
            if (currentLocation === nextLocation) return false;
            if (checker()) {
                return !window.confirm("変更が保存されていません。ページを離れますか？");
            } else {
                setBlocker(false);
                return false;
            }
        }
    );
    useBeforeUnload(useCallback((e) => {
        if (!blocker) return;
        if (checker()) {
            e.preventDefault();
            e.returnValue = '';
        } else {
            setBlocker(false);
        }
    }, [blocker, checker]));
}
export default useUnloadBlocker;