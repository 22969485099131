//コンポーネントID : m14
//マークアップ担当アミン
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { ListItemNode, ListNode } from "@lexical/list";
import { TRANSFORMERS } from "@lexical/markdown";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { useCallback, useState } from "react";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import AutoLinkPlugin from "./editor/AutoLinkPlugin";
import CodeHighlightPlugin from "./editor/CodeHighlightPlugin";
import DraggableBlockPlugin from "./editor/DraggableBlockPlugin";
import ImageInsertPlugin from "./editor/ImageInsertPlugin";
import { ImageNode } from "./editor/ImageNode";
import { ImageRegister } from "./editor/ImageRegister";
import ListMaxIndentLevelPlugin from "./editor/ListMaxIndentLevelPlugin";
import MoveSelectedNodes from "./editor/MoveSelectedNodes";
import ToolbarPlugin from "./editor/ToolbarPlugin";
import DeleteProjectModal from "../organisms/modal/DeleteProjectModal";

export default function ProjectExplain({ body, setBody, onSend, isEdit }) {

    const [openModal, setOpenModal] = useState(false);
    const [editorState, setEditorState] = useState(body.overview);
    const [floatingAnchorElem, setFloatingAnchorElem] = useState(null);
    const onRef = (_floatingAnchorElem) => {
        if (_floatingAnchorElem !== null) {
            setFloatingAnchorElem(_floatingAnchorElem);
        }
    };

    function saveOverview(editorState) {
        setEditorState(editorState);
        handleSelectChange("overview", JSON.stringify(editorState));
    }

    const handleSelectChange = useCallback((name, value) => {
        setBody(status => ({
            ...status,
            [name]: value
        }));
    }, [setBody]);

    const exampleTheme = {
        ltr: "ltr",
        rtl: "rtl",
        placeholder: "editor-placeholder",
        paragraph: "editor-paragraph",
        quote: "editor-quote",
        heading: {
            h1: "editor-heading-h1",
            h2: "editor-heading-h2",
            h3: "editor-heading-h3",
            h4: "editor-heading-h4",
            h5: "editor-heading-h5"
        },
        list: {
            nested: {
                listitem: "editor-nested-listitem"
            },
            ol: "editor-list-ol",
            ul: "editor-list-ul",
            listitem: "editor-listitem"
        },
        image: "editor-image",
        link: "editor-link",
        text: {
            bold: "editor-text-bold",
            italic: "editor-text-italic",
            overflowed: "editor-text-overflowed",
            hashtag: "editor-text-hashtag",
            underline: "editor-text-underline",
            strikethrough: "editor-text-strikethrough",
            underlineStrikethrough: "editor-text-underlineStrikethrough",
            code: "editor-text-code"
        },
        code: "editor-code",
        codeHighlight: {
            atrule: "editor-tokenAttr",
            attr: "editor-tokenAttr",
            boolean: "editor-tokenProperty",
            builtin: "editor-tokenSelector",
            cdata: "editor-tokenComment",
            char: "editor-tokenSelector",
            class: "editor-tokenFunction",
            "class-name": "editor-tokenFunction",
            comment: "editor-tokenComment",
            constant: "editor-tokenProperty",
            deleted: "editor-tokenProperty",
            doctype: "editor-tokenComment",
            entity: "editor-tokenOperator",
            function: "editor-tokenFunction",
            important: "editor-tokenVariable",
            inserted: "editor-tokenSelector",
            keyword: "editor-tokenAttr",
            namespace: "editor-tokenVariable",
            number: "editor-tokenProperty",
            operator: "editor-tokenOperator",
            prolog: "editor-tokenComment",
            property: "editor-tokenProperty",
            punctuation: "editor-tokenPunctuation",
            regex: "editor-tokenVariable",
            selector: "editor-tokenSelector",
            string: "editor-tokenSelector",
            symbol: "editor-tokenProperty",
            tag: "editor-tokenProperty",
            url: "editor-tokenOperator",
            variable: "editor-tokenVariable"
        }
    };

    const editorConfig = {
        editorState: editorState,
        theme: exampleTheme,
        onError(error) {
            throw error;
        },
        nodes: [
            HeadingNode,
            ListNode,
            ListItemNode,
            QuoteNode,
            CodeNode,
            CodeHighlightNode,
            TableNode,
            TableCellNode,
            TableRowNode,
            AutoLinkNode,
            LinkNode,
            ImageNode,
        ]
    };

    function scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    return (
        <Form className="m14_wrapper" onSubmit={(e) => { e.preventDefault() }}>
            <LexicalComposer
                initialConfig={editorConfig}
            >
                <div className="editor-container m14_editor_container d-flex flex-column aline-item-center">
                    {/* <p className="m14_text_counter fw-bold w-100 d-flex justify-content-end">*文字数カウント/5000</p> */}
                    <ToolbarPlugin />
                    <div className="editor-inner" ref={onRef}>
                        <RichTextPlugin
                            contentEditable={<ContentEditable className="editor-input" />}
                            placeholder={<div className="editor-placeholder">入力してください</div>}
                            ErrorBoundary={LexicalErrorBoundary}
                        />
                        <AutoFocusPlugin />
                        <HistoryPlugin />
                        <CodeHighlightPlugin />
                        <ListPlugin />
                        <LinkPlugin />
                        <AutoLinkPlugin />
                        <ListMaxIndentLevelPlugin maxDepth={7} />
                        <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
                        <ImageInsertPlugin />
                        <ImageRegister />
                        <MoveSelectedNodes />
                        {floatingAnchorElem && (
                            <DraggableBlockPlugin anchorElem={floatingAnchorElem} />
                        )}
                    </div>
                </div>
                <OnChangePlugin onChange={saveOverview} />
            </LexicalComposer>
            <div className={isEdit ? "d-flex justify-content-evenly" : ""}>
                {/* <Button className="m14_button" onClick={() => { onNextTab(2); }}>次へ</Button> */}
                {isEdit ?
                    <>
                        <Button variant="outline-danger" className=" m14_button_edit m14_button_del" onClick={() => setOpenModal(true)}>削除</Button>
                        <DeleteProjectModal open={openModal} setOpen={setOpenModal} onSend={onSend} />
                    </>
                    : null}
                <Button className={isEdit ? "m14_button m14_button_edit" : "m14_button"} onClick={() => { onSend() }}>保存</Button>
            </div>

            <Button className={`m14_to_top_button position-fixed p-0 border-0 ${"m14_to_top_button_show"}`} onClick={() => scrollToTop()}></Button>
        </Form>
    );
}