import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
// import { ReactComponent as Like } from '../../../assets/like.svg';
// import { ReactComponent as Bookmark } from '../../../assets/bookmark.svg';
// import { ReactComponent as Share } from '../../../assets/share.svg';
// import { ReactComponent as MoreHoriz } from '../../../assets/more_horiz.svg';
import { Link } from "react-router-dom";

export default function SwiperHeroProjectCardList({ projectDatum }) {
    // const [openAction, setOpenAction] = useState(Array(5).fill(false));

    // const handleOpenAction = (index, e) => {
    //     e.preventDefault();
    //     const updatedAction = [...openAction];
    //     updatedAction[index] = !updatedAction[index];
    //     setOpenAction(updatedAction);
    // }
    const slides = projectDatum.length < 4 && projectDatum.length > 1 ? projectDatum.concat(projectDatum) : projectDatum;

    return (
        <>
            {slides.length >= 1 &&
                <Swiper
                    slidesPerView={1}
                    centeredSlides={true}
                    loop={slides.length > 1}
                    navigation={true}
                    autoplay={
                        {
                            delay: 3500,
                            disableOnInteraction: false,
                            pauseOnMouseEnter: true,
                        }
                    }
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Autoplay, Navigation, Pagination]}
                    breakpoints={{
                        480: {
                            spaceBetween: 0,
                            slidesPerView: 'auto',
                        },
                        768: {
                            spaceBetween: 16,
                            slidesPerView: 'auto',
                        },
                        1024: {
                            spaceBetween: 30,
                            slidesPerView: 'auto',
                        }
                    }}
                    className='m35_swiper w-100 h-100 my-5'>
                    {slides.map((project, index) => (
                        <SwiperSlide key={index} className='m35_hero_project_card position-relative d-flex justify-content-center align-items-center'>
                            <Link to={`/project/${project.id}`} className='d-block w-100 h-100'>
                                <img src={project.thumbnail ? project.thumbnail : "/DefaultImage/DefaultProjectThumbnail.png"} alt="プロジェクトサムネイル" className='m35_hero_img w-100 h-100' />
                                <span className='m35_card_shadow w-100 h-100 position-absolute d-block top-0'></span>
                                {/* <div className='m35_action w-100 px-4 position-absolute text-end'>
                                                <button className='m35_bookmark_btn border-0 bg-transparent'><Bookmark /></button>
                                                <button className='m35_share_btn border-0 bg-transparent'><Share /></button>
                                            </div>
                                            <div className='m35_action_sp w-100 px-4 position-absolute text-end'>
                                                <button className={`m35_bookmark_btn border-0 bg-transparent ${openAction[index] ? '' : 'm35_hidden'}`}><Bookmark /></button>
                                                <button className={`m35_share_btn border-0 bg-transparent ${openAction[index] ? '' : 'm35_hidden'}`}><Share /></button>
                                                <button onClick={(e) => handleOpenAction(index, e)} className='m35_more_horiz_btn border-0 bg-transparent'><MoreHoriz /></button>
                                            </div> */}
                                <div className='m35_project_info w-100 px-4 position-absolute'>
                                    <h3 className='m35_project_title mb-3 text-left'>{project.project_name}</h3>
                                    <div className='d-flex justify-content-between'>
                                        <div className='d-flex align-items-center'>
                                            <img src={project.icon ? project.icon : "/DefaultImage/DefaultProfile.png"} alt="ユーザーアイコン" className='m35_icon rounded-circle' />
                                            <p className='m35_nickname m-0 ms-2'>{project.nickname}</p>
                                        </div>
                                        {/* <button className='m35_like_btn border-0 bg-transparent'><Like /></button> */}
                                    </div>
                                </div>
                            </Link>
                        </SwiperSlide>
                    ))}
                </Swiper>
            }
        </>
    )
}
