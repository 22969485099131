// コンポーネントID : m31
import { DndContext, KeyboardSensor as LibKeyboardSensor, MouseSensor as LibMouseSensor, closestCenter, useSensor, useSensors } from "@dnd-kit/core";
import { SortableContext, rectSortingStrategy, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import ProjectCard from "../card/ProjectCard";

//ドラッグ＆ドロップで並び替えをするために使用
function SortableItem({ id, children }) {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <div key={id.toString()} ref={setNodeRef} style={style} {...attributes} {...listeners}>
            {children}
        </div>
    );
}
// data-dndkit-disabled-dnd-flag="true" が指定されている要素はドラッグ無効にする関数
function shouldHandleEvent(element) {
    let cur = element;

    while (cur) {
        if (cur.dataset && cur.dataset.dndkitDisabledDndFlag) {
            return false;
        }
        cur = cur.parentElement;
    }

    return true;
}
// LibMouseSensor を override してドラッグ無効にする
class MouseSensor extends LibMouseSensor {
    static activators = [
        {
            eventName: "onMouseDown",
            handler: ({ nativeEvent: event }) => {
                return shouldHandleEvent(event.target);
            },
        },
    ];
}
// LibKeyboardSensor を override してドラッグ無効にする
class KeyboardSensor extends LibKeyboardSensor {
    static activators = [
        {
            eventName: "onKeyDown",
            handler: ({ nativeEvent: event }) => {
                return shouldHandleEvent(event.target);
            },
        },
    ];
}
export default function ProjectCardList({ projects, createButton, isEdit, onPortfolioDrag, onPortfolioDelete, onClickProject, activeModal }) {

    // ドラッグ&ドロップする時に許可する入力
    const sensors = useSensors(useSensor(MouseSensor), useSensor(KeyboardSensor));

    return (
        <div className={isEdit ? null : "m31_grid"}>
            {!projects ? (
                <div>Loading...</div> // ローディング表示
            ) : (
                <>
                    {isEdit ? (
                        <DndContext
                            sensors={sensors}
                            collisionDetection={closestCenter}
                                onDragEnd={onPortfolioDrag}
                        >
                            <SortableContext
                                strategy={rectSortingStrategy}
                                items={projects.map((item) => item.id)}>
                                <div className="m31_dnd_lists">
                                    {projects.map((item) => (
                                        <SortableItem key={item.id} id={item.id}>
                                            <ProjectCard
                                                isEdit={isEdit}
                                                project={item}
                                                onPortfolioDelete={() => onPortfolioDelete(item)}
                                            />
                                        </SortableItem>
                                    ))}
                                    {createButton && <button className="m31_create_button" onClick={() => activeModal()} />}
                                </div>
                            </SortableContext>
                        </DndContext>
                    ) : (
                        <>
                            {
                                projects.map((project) => (
                                    <ProjectCard key={project.id} project={project} onClickProject={onClickProject ? onClickProject : false} />
                                ))
                            }
                            {createButton && <button className="m31_create_button" onClick={() => activeModal()} />}
                        </>
                    )}
                </>
            )}
        </div>
    );
}
