//コンポーネントID : m46
import React from "react";
import { InputGroup, Form, Button } from "react-bootstrap";
import { ReactComponent as Delete } from "../../assets/delete.svg";

const EditableList = ({
    items,
    isEdit,
    handleUpdate,
    handleDelete,
    newItemName,
    setNewItemName,
    handleAddItemName,
}) => {
    return (
        <div className="m24_status_box">
            {isEdit ? (
                items.map((item, index) => (
                    <InputGroup key={index} className="mb-1">
                        <Form.Control
                            type="search"
                            value={item.name}
                            onChange={(e) => handleUpdate(index, e.target.value)}
                        />
                        <Button onClick={() => handleDelete(index)}>
                            <Delete />
                        </Button>
                    </InputGroup>
                ))
            ) : (
                <p>
                    {/* "/"区切りの一つのPタグにしたいからmapの処理をまとめていない */}
                    {items.map((item, index) =>
                        index === items.length - 1 ? item.name : item.name + " / "
                    )}
                </p>
            )}
            {isEdit && ( //新しい項目の追加
                <InputGroup className="mb-1">
                    <Form.Control
                        type="text"
                        value={newItemName}
                        onKeyDown={(e) => handleAddItemName(e)}
                        onChange={(e) => setNewItemName(e.target.value)}
                        placeholder="新しい項目"
                    />
                    <Button
                        onClick={(e) => handleAddItemName(e)}
                        className="m24_new_button">
                        {"+"}
                    </Button>
                </InputGroup>
            )}
        </div>
    );
};

export default EditableList;
