//コンポーネントID : p1
// マークアップ担当者：鶴岡

//アカウント設定画面
//タブで各種設定、スカウト、アカウント

import React, { useEffect, useState } from "react";
import Tabs from "../molecules/Tabs";
import UserSetting from "../molecules/UserSetting";
// import ScoutSetting from "../molecules/ScoutSetting";
import OtherSetting from "../molecules/OtherSetting";
import { useLocation, useNavigate } from "react-router-dom";
import account from "../../reducers/accountSlice";
import { useSelector } from "react-redux";
import listing from "../../reducers/listingSlice";

export default function AccountSetting({ tab }) {
    const [loaded, setLoaded] = useState(false);
    const accountData = useSelector(account.state);
    const listingData = useSelector(listing.state);
    const navigate = useNavigate();
    const location = useLocation();

    // const tabs = ["各種設定", "スカウト設定", "アカウント"];
    // const tabPages = [<UserSetting />, <ScoutSetting />, <OtherSetting />];
    // function setActiveTab(id) {
    //     if (tab === id) return;
    //     if (id === 0) {
    //         navigate("/settings/user");
    //     } else if (id === 1) {
    //         navigate("/settings/scout");
    //     } else if (id === 2) {
    //         navigate("/settings/account");
    //     }
    // }

    const tabs = ["各種設定", "アカウント"];
    const tabPages = [<UserSetting />, <OtherSetting />];
    function setActiveTab(id) {
        if (tab === id) return;
        if (id === 0) {
            navigate("/settings/user");
        } else if (id === 1) {
            navigate("/settings/account");
        }
    }

    useEffect(() => {
        if (!accountData.loading) {
            if (accountData.data != null) {
                setLoaded(!accountData.loading && !listingData.loading);
            } else {
                navigate(`/login?redirect=${encodeURIComponent(location.pathname)}`);
            }
        }
    }, [accountData, listingData, location.pathname, navigate]);

    return (
        <div className="p1_main">
            <h1>設定</h1>
            {loaded &&
                (
                    <>
                        <Tabs tabs={tabs} activeTab={tab} setActiveTab={setActiveTab} />
                        <div className="content">
                            {tabPages[tab]}
                        </div>
                    </>
                )
            }
        </div>
    );
}
