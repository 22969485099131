//コンポーネントID : m34
//マークアップ担当アミン
import { Link } from "react-router-dom";
import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/pagination';
import { Autoplay, Grid } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
// import { ReactComponent as ArrowRightTriangle } from '../../../assets/arrow_right_triangle.svg';

export default function SwiperProfileList({ userDatum }) {

    //userDatumが20未満の場合20に複製
    if (userDatum.length < 20) {
        const originalLength = userDatum.length;
        for (let i = originalLength; i < 20; i++) {
            const indexToCopy = i % originalLength;
            const copiedItem = { ...userDatum[indexToCopy] };
            userDatum.push(copiedItem);
        }
    }

    return (
        <>
            {userDatum && userDatum.length > 0 &&
                <section className="m34_profile_wrapper">
                    <p className="text-center m-0">ポートフォリオを公開しているユーザー</p>
                    <Swiper
                        slidesPerView={"auto"}
                        loop={true}
                        grid={{ rows: 2 }}
                        autoplay={{
                            delay: 0,
                            disableOnInteraction: false,
                        }}
                        speed={5000}
                        spaceBetween={16}
                        modules={[Grid, Autoplay]}
                        className={`m34_swiper w-100 ${userDatum.length > 6 ? null : "m34_one_liner"}`}
                    >
                        {userDatum.map((user, index) => (
                            <SwiperSlide key={index}>
                                <Link to={`/user/${user.id}`} className="m34_profile h-100 d-flex align-items-center">
                                    <img src={user.icon ? user.icon : "/DefaultImage/DefaultProfile.png"} alt="ユーザーアイコン" className="m34_icon rounded-circle" />
                                    <div className="m34_profile_info ps-2 d-flex flex-column ">
                                        <p className="m34_tag_name m-0">{user.tag_name}</p>
                                        <p className="m34_nickname m-0">{user.nickname}</p>
                                    </div>
                                </Link>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    {/* <div className="m34_more_btn_wrapper w-100 m-auto"><Link to={"/"} className="m34_more_btn px-4 py-2 rounded-pill d-flex justify-content-center align-items-center">もっと見る<ArrowRightTriangle /></Link></div> */}
                </section>
            }
        </>
    )
}
