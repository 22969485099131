//コンポーネントID : m10
// マークアップ担当者：鶴岡

//アカウント設定のアカウント設定ページ
//ログアウトとアカウント削除のみ

import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import SingleMenu from "../atom/template/SingleMenu";
// import DeleteModal from "../organisms/modal/DeleteModal";
import LogoutModal from "../organisms/modal/LogoutModal";
import { Helmet } from "react-helmet-async";

export default function OtherSetting() {
    const [openModal, setOpenModal] = useState(false);
    // const [openDelete, setOpenDelete] = useState(false);

    return (
        <>
            <Helmet>
                <title>アカウント設定｜Faro</title>
            </Helmet>
            <Container className="m10_container">
                <Row className="mb-5 m10_first_row">
                    <Col md={3} className="my-auto">
                        <SingleMenu text="ログアウト" />
                    </Col>
                    <Col className="m10_second_col">
                        <Button variant="primary" onClick={() => setOpenModal(true)}>
                            ログアウト
                        </Button >
                        <LogoutModal
                            open={openModal}
                            setOpen={setOpenModal}
                        />
                    </Col>
                </Row>
                {/* <Row className="m10_second_row">
                    <Col md={3} className="m10_third_col">
                        <SingleMenu text="アカウント削除" />
                    </Col>
                    <Col className="m10_fourth_col">
                        <Button
                            onClick={() => setOpenDelete(true)}
                            variant="danger"
                            className="shadow-sm"
                        >
                            アカウントを削除
                        </Button>
                        <p className="attention">
                            ※一度アカウントを削除したら復元することはできません。
                        </p>
                        <DeleteModal
                            open={openDelete}
                            setOpen={setOpenDelete}
                        />
                    </Col>
                </Row> */}
            </Container>
        </>
    );
};
