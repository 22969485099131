import type {
    EditorConfig,
    LexicalNode,
    NodeKey,
    SerializedLexicalNode,
    Spread
} from 'lexical';

import { $applyNodeReplacement, DecoratorNode } from 'lexical';
import ImagePreview from './ImagePreview';

export interface ImagePayload {
    altText: string;
    height?: 'inherit' | number | string;
    key?: NodeKey;
    src: string;
    width?: 'inherit' | number | string;
    maxWidth: string;
}

export type SerializedImageNode = Spread<
    {
        altText: string;
        height?: 'inherit' | number | string;
        src: string;
        width?: 'inherit' | number | string;
        type: 'customImage';
        maxWidth: string;
        version: 1;
    },
    SerializedLexicalNode
>;

export class ImageNode extends DecoratorNode<JSX.Element> {
    __src: string;
    __altText: string;
    __width?: 'inherit' | number | string;
    __height?: 'inherit' | number | string;
    __maxWidth: string;

    static getType(): string {
        return 'customImage';
    }

    static clone(node: ImageNode): ImageNode {
        return new ImageNode(
            node.__src,
            node.__altText,
            node.__maxWidth,
            node.__width,
            node.__height,
        );
    }

    constructor(
        src: string,
        altText: string,
        maxWidth: string,
        width?: 'inherit' | number | string,
        height?: 'inherit' | number | string,
        key?: NodeKey,
    ) {
        super(key);
        this.__src = src;
        this.__altText = altText;
        this.__maxWidth = maxWidth;
        this.__width = width || 'inherit';
        this.__height = height || 'inherit';
    }

    createDOM(config: EditorConfig): HTMLElement {
        const span = document.createElement('span');
        const theme = config.theme;
        const className = theme.image;
        if (className !== undefined) {
            span.className = className;
        }
        return span;
    }
    exportJSON(): SerializedImageNode {
        return {
            altText: this.__altText,
            height: this.__height,
            src: this.__src,
            width: this.__width,
            type: "customImage",
            maxWidth: this.__maxWidth,
            version: 1, // 管理のためのバージョン番号を含めることができます
        };
    }

    static importJSON(serializedNode: SerializedImageNode): ImageNode {
        const { altText, height, width, maxWidth, src } =
            serializedNode;
        const node = $createImageNode({
            altText,
            height,
            src,
            width,
            maxWidth,
        });
        return node;
    }

    updateDOM(): false {
        return false;
    }

    decorate(): JSX.Element {
        return (
            <ImagePreview
                src={this.__src}
                height={this.__height}
                width={this.__width}
                alt={this.__altText}
                nodeKey={this.__key}
                resizable={true}
                maxWidth={this.__maxWidth}
            />
        );
    }
    // ImageNodeクラスの定義内
    setWidthAndHeight(width?: 'inherit' | number | string, height?: 'inherit' | number | string): void {
        const writable = this.getWritable();
        writable.__width = width;
        writable.__height = height;
    }
}

export function $createImageNode({
    altText,
    height,
    src,
    width,
    key,
    maxWidth
}: ImagePayload): ImageNode {
    return $applyNodeReplacement(
        new ImageNode(
            src,
            altText,
            maxWidth,
            width,
            height,
            key,
        ),
    );
}

export function $isImageNode(
    node: LexicalNode | null | undefined,
): node is ImageNode {
    return node instanceof ImageNode;
}
