import { createSlice } from '@reduxjs/toolkit';
import axios from '../lib/axios';

const name = 'listing';

export const slice = createSlice({
    name: name,
    initialState: {
        data: null,
        loading: true,
        error: null,
    },
    reducers: {
        setData: (state, action) => {
            state.data = action.payload;
            state.error = null;
            state.loading = false;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        },
    },
});

const initData = () => async (dispatch) => {
    try {
        const response = await axios.get("/api/listing");
        console.log(response.data);
        dispatch(slice.actions.setData(response.data));
    } catch (error) {
        dispatch(slice.actions.setError(error.code));
    }
};

export const listing = {
    name: name,
    slice: slice,
    initData: initData,
    state: (state) => state.listing,
}
export default listing;