//コンポーネントID : m25
// マークアップ担当者：鶴岡

//アカウント設定の各種設定ページ

import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import URL from "../../assets/link.svg";
import { Button, Form } from "react-bootstrap";
import SettingSNSGroup from "../atom/SettingSNSGroup";
import { useDispatch, useSelector } from "react-redux";
import SNSData from "../../app/SNSData";
import SingleMenu from "../atom/template/SingleMenu";
import Key from "../../assets/key.svg";
import Mail from "../../assets/mail.svg";
// import { ReactComponent as Tab } from "../../assets/new_tab.svg";
import { ReactComponent as Delete } from "../../assets/delete.svg";
import SquareEdit from "../../assets/square_edit.svg";
import UserRectangle from "../../assets/user_rectangle.svg";
import DropImage from "./DropImage";
import { AppSettings } from "../../App";
import account from "../../reducers/accountSlice";
import axios from "../../lib/axios";
// import { useNavigate } from "react-router-dom";
import useUnloadBlocker from "../organisms/UnloadBlocker";
import listing from "../../reducers/listingSlice";
import { Helmet } from "react-helmet-async";

export default function UserSetting() {
    const [icon, setIcon] = useState(null);
    const { setPopWindow } = useContext(AppSettings);
    const accountData = useSelector(account.state).data;
    const listingData = useSelector(listing.state).data;
    const ref = useRef(null);
    const dispatch = useDispatch();
    const [prevSettingsData, setPrevSettingsData] = useState(null);

    const [settingsData, setSettingsData] = useState({
        "nickname": accountData.nickname,
        "tag_name": accountData.tag_name,
        "sns": accountData.sns,
    });

    useEffect(() => {
        if (prevSettingsData == null) {
            setPrevSettingsData(settingsData);
        }
    }, [settingsData, prevSettingsData]);

    // const navigate = useNavigate();

    function getSNSInput(sns, index, web) {
        let snsData = null;
        if (web) {
            snsData = settingsData.sns[index];
        } else {
            for (const data of settingsData.sns) {
                if (sns.id === data.id) {
                    snsData = data;
                }
            }
        }
        if (snsData == null) {
            snsData = {
                "url": "",
                "is_public": false,
            }
            setSettingsData(status => ({
                ...status,
                "sns": [
                    ...status.sns,
                    {
                        "id": sns.id,
                        "url": "",
                        "is_public": false,
                    }
                ]
            }));
        }
        function onChange(value) {
            setSettingsData(currentSettings => {
                let newSns;
                if (web) {
                    newSns = [...currentSettings.sns];
                    if (newSns[index]) {
                        newSns[index] = { ...newSns[index], url: value, is_public: value === "" ? false : newSns[index].is_public };
                    }
                } else {
                    newSns = currentSettings.sns.map(s => {
                        if (s.id === sns.id) {
                            return { ...s, url: value, is_public: value === "" ? false : s.is_public };
                        }
                        return s;
                    });
                }
                return { ...currentSettings, sns: newSns };
            });
        }
        function onPublicChange(checked) {
            setSettingsData(currentSettings => {
                let newSns;
                if (web) {
                    newSns = [...currentSettings.sns];
                    if (newSns[index]) {
                        newSns[index] = { ...newSns[index], is_public: checked };
                    }
                } else {
                    newSns = currentSettings.sns.map(s => {
                        if (s.id === sns.id) {
                            return { ...s, is_public: checked };
                        }
                        return s;
                    });
                }
                return { ...currentSettings, sns: newSns };
            });
        }
        return (
            <SettingSNSGroup
                name={sns.name}
                snsIcon={web ? null : {
                    icon: SNSData[sns.name].icon,
                    alt: sns.name
                }}
                userAccount={snsData.url}
                placeholder={web ? "urlを入力してください" : "アカウントIdを入力"}
                snsURLPrefix={web ? null : sns.url}
                isPublic={snsData.is_public}
                onChange={(e) => onChange(e.target.value)}
                onPublicChange={(e) => onPublicChange(e.target.checked)}
            />
        );
    }

    function hasErrorClass() {
        const children = ref.current.querySelectorAll("*");
        for (let i = 0; i < children.length; i++) {
            const child = children[i];
            if (child.classList.contains("error")) {
                return true;
            }
        }
        return false;
    }

    async function save() {
        const newSettingsData = {
            "nickname": "",
            "tag_name": "",
            ...settingsData,
            "sns": settingsData.sns.filter(sns => sns.url && sns.url.length !== 0)
        };
        setSettingsData(newSettingsData);
        if (hasErrorClass()) {
            setPopWindow("正しく入力して再試行してください。", true);
            return;
        }
        const formData = new FormData();
        if (icon != null) {
            formData.append("icon", icon === "" ? null : icon);
        }
        formData.append("body", JSON.stringify(newSettingsData));
        try {
            const response = await axios.post("/api/user/edit", formData, { withCredentials: true });
            dispatch(account.slice.actions.setData(response.data));
            setPopWindow("保存しました。");
            setPrevSettingsData(newSettingsData);
        } catch (error) {
            setPopWindow("保存できませんでした。", true);
        }
    }

    function getIcon() {
        if (icon != null) {
            return icon;
        }
        if (accountData.icon != null) {
            return process.env.REACT_APP_PUBLIC_BACKEND_URL + accountData.icon;
        }
        return null;
    }

    function normalizeObject({ id, url, is_public }) {
        return { id, url, is_public };
    }

    const countObjects = useCallback((arr) => {
        return arr.reduce((acc, obj) => {
            const key = JSON.stringify(normalizeObject(obj));
            acc[key] = (acc[key] || 0) + 1;
            return acc;
        }, {});
    }, []);


    const arraysMatch = useCallback((arr1, arr2) => {
        arr1 = arr1.filter(item => item.url !== "");
        arr2 = arr2.filter(item => item.url !== "");

        const count1 = countObjects(arr1);
        const count2 = countObjects(arr2);

        const keys1 = Object.keys(count1);
        const keys2 = Object.keys(count2);
        if (keys1.length !== keys2.length) return false;

        for (const key of keys1) {
            if (count1[key] !== count2[key]) return false;
        }

        return true;
    }, [countObjects]);

    const isEquivalent = useCallback((o1, o2) => {
        const oc1 = JSON.parse(JSON.stringify(o1));
        const oc1Keys = Object.keys(oc1);
        const oc2 = JSON.parse(JSON.stringify(o2));
        if (oc1Keys.length !== Object.keys(oc2).length) return false;

        for (const key of oc1Keys) {
            if (oc1[key] !== oc2[key]) {
                if (key === "sns") continue;
                return false;
            }
        }

        return arraysMatch(oc1.sns, oc2.sns);
    }, [arraysMatch]);

    useUnloadBlocker(() => {
        return !isEquivalent(settingsData, prevSettingsData);
    });

    if (!accountData || !listingData) {
        return;
    }


    return (
        <>
            <Helmet>
                <title>各種設定｜Faro</title>
            </Helmet>
            <Form className="m25_container" ref={ref}>
                <div className="user">
                    <div className="item">
                        <SingleMenu text="ユーザーアイコン" />
                        <div className="change_icon">
                            <DropImage type="icon" image={getIcon()} setImage={setIcon} />
                        </div>
                        <Button className="m25_col_butoon icon_delete" onClick={() => setIcon("")}>
                            <Delete />
                            <span>削除する</span>
                        </Button>
                    </div>
                    <hr />
                    <div className="item">
                        <SingleMenu
                            imagePath={SquareEdit}
                            imageAlt="アイコン"
                            text="ニックネーム"
                        />
                        <Form.Control
                            className={"m25_text"}
                            defaultValue={accountData.nickname}
                            onChange={(e) =>
                                setSettingsData(status => ({
                                    ...status,
                                    "nickname": e.target.value
                                }))
                            }
                        />
                    </div>
                    <hr />
                    <div className="item">
                        <SingleMenu
                            imagePath={UserRectangle}
                            imageAlt="アイコン"
                            text="肩書き（任意）"
                        />
                        <Form.Control
                            className="input_text"
                            defaultValue={accountData.tag_name}
                            onChange={(e) =>
                                setSettingsData(status => ({
                                    ...status,
                                    "tag_name": e.target.value
                                }))
                            }
                        />
                    </div>
                    <hr />
                    <div className="item">
                        <SingleMenu
                            imagePath={Mail}
                            imageAlt="アイコン"
                            text="メールアドレス"
                        />
                        <p className="my-auto col-md-4">{accountData.email}</p>
                        {/* <Button className="m25_col_butoon" onClick={() => navigate("/email/reset")}>
                            <Tab />
                            <span>変更する</span>
                        </Button> */}
                    </div>
                    <hr />
                    <div className="item">
                        <SingleMenu imagePath={Key} imageAlt="アイコン" text="パスワード" />
                        <p className="my-auto col-md-4">********</p>
                        {/* <Button className="m25_col_butoon" onClick={() => navigate("/password/update")}>
                            <Tab />
                            <span>変更する</span>
                        </Button> */}
                    </div>
                    <hr />
                </div>
                <p className="m25_title mt-5">SNS連携</p>
                <div className="m25_sns_container">
                    <div className="m25_sns_group mb-5">
                        {listingData.sns_types.map((sns, index) =>
                            <React.Fragment key={index}>
                                {sns.id !== 1 && getSNSInput(sns, index)}
                            </React.Fragment>
                        )}
                        {settingsData.sns.map((sns, index) =>
                            <React.Fragment key={index}>
                                {sns.id === 1 && getSNSInput(sns, index, true)}
                            </React.Fragment>
                        )}
                    </div>
    
                    <div className="m25_buttons d-grid gap-2">
                        <Button
                            onClick={() => {
                                setSettingsData(status => ({
                                    ...status,
                                    "sns": [
                                        ...status.sns,
                                        {
                                            "id": 1,
                                            "url": "",
                                            "is_public": false,
                                        }
                                    ]
                                }));
                            }}
                            variant="light"
                            className="m25_url_button p-2 mb-5 mx-auto">
                            <img src={URL} alt="URL" />
                            ＵＲＬを追加する
                        </Button>
                        <Button
                            variant="primary"
                            size="lg"
                            className="m25_save_button p-2 mb-5 mx-auto position-relative"
                            onClick={save}
                        >
                            保存
                        </Button>
                    </div>
                </div>
            </Form>
        </>
    );
}