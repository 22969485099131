//コンポーネントID : p32
import { useState } from 'react'
import RegisterSendEmail from '../organisms/register/RegisterSendEmail';
import EmailUpdate from "../organisms/EmailUpdate";
import { Helmet } from 'react-helmet-async';

export default function ResetEmail() {
    const [page, setPage] = useState(0);
    const [email, setEmail] = useState(null);
    function nextPage() {
        setPage(page === 2 ? 0 : page + 1)
    }

    function getPage() {
        switch (page) {
            // クリックごとにページ移動
            case 0: //新しいemail入力
                return <EmailUpdate email={email} setEmail={setEmail} nextPage={nextPage} />;
            case 1: //再設定用のメール送信
                return <RegisterSendEmail isReset={true} email={email} nextPage={nextPage} />;
            case 2: //再設定用のメール送信完了
                return <RegisterSendEmail />;
            default:
                return null;
        }
    }
    return (
        <>
            <Helmet>
                <title>メールアドレス変更｜Faro</title>
            </Helmet>
            {getPage()}
        </>
    );
}
