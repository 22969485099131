//コンポーネントID : o15
// マークアップ担当者：鶴岡

// スタートページ
// import Button from "react-bootstrap/Button";
import SiteImage from "../../assets/site_image.png";
import ExampleImage from "../../assets/example_image.png";
import ExampleImage05 from "../../assets/example05.png";
import PreviewImageRight from "../../assets/preview_image_right.png";
import PreviewImageLeft from "../../assets/preview_image_left.png";
import { ReactComponent as FaroLogo } from "../../assets/faro_logo.svg";
import { ReactComponent as Check } from "../../assets/check.svg";
import { ReactComponent as AddNote } from "../../assets/add_notes.svg";
import { ReactComponent as Global } from "../../assets/global.svg";
import { ReactComponent as Light } from "../../assets/light.svg";
import { ReactComponent as HandShake } from "../../assets/handshake.svg";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";

export default function Landing() {
    // アニメーションのコード
        useEffect(() => {
            // ターゲットとなるクラス名と追加するクラス名をマッピング
            const classMappings = [
                {
                    targetClasses: ["p3_target_element", "p3_target_element_second"],
                    animationClass: "p3_slide_animation_Left",
                },
                {
                    targetClasses: ["p3_target_element_right"],
                    animationClass: "p3_slide_animation_right",
                },
                {
                    targetClasses: ["p3_preview_image_animation_left"],
                    animationClass: "p3_preview_image_left",
                },
                {
                    targetClasses: ["p3_preview_image_animation_right"],
                    animationClass: "p3_preview_image_right",
                },
                {
                    targetClasses: ["p3_fade_animation"],
                    animationClass: "p3_slide_animation",
                },
                {
                    targetClasses: ["p3_start_up"],
                    animationClass: "p3_start_up_animation",
                },
            ];
            // 各ターゲットグループに対して要素を取得し、スクロール時の処理を設定
            const elementsToAnimate = classMappings
                .flatMap((mapping) =>
                    mapping.targetClasses.flatMap((className) =>
                        Array.from(document.querySelectorAll(`.${className}`)).map((el) => ({
                            element: el,
                            animationClass: mapping.animationClass,
                        }))
                    )
                )
                .filter((item) => item.element !== null);

            const updateVisibilityOnScroll = () => {
                elementsToAnimate.forEach(({ element, animationClass }) => {
                    if (
                        window.scrollY + window.innerHeight >=
                        element.getBoundingClientRect().top + window.scrollY &&
                        !element.classList.contains(animationClass)
                    ) {
                        element.classList.add(animationClass);
                    }
                });
            };

            window.addEventListener("scroll", updateVisibilityOnScroll);

            return () => {
                window.removeEventListener("scroll", updateVisibilityOnScroll);
            };
        }, []);
    return (
        <>
            <Helmet>
                <title>Faro｜プロフェッショナルなポートフォリオを簡単に</title>
            </Helmet>
            <div className="p3_lp_container">
                <section className="p3_hero">
                    <div className="p3_left_content">
                        <div className="p3_faro_logo">
                            <FaroLogo />
                        </div>
                        <h1>
                            誰でも簡単
                            <br />
                            ポートフォリオ作成ツール
                        </h1>
                        <ul>
                            <li>
                                <Check />
                                <p>手軽に作成</p>
                            </li>
                            <li>
                                <Check />
                                <p>いろんなユーザーのインスピレーション</p>
                            </li>
                            <li>
                                <Check />
                                <p>あなたの作品をグローバルに公開</p>
                            </li>
                        </ul>

                        <Link className="p3_common_link" to={"/Register"}>アカウントを登録</Link>
                    </div>
                    <div className="p3_right_content">
                        <div className="p3_loop_animation">
                            <div className="p3_infinite_rise">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="p3_infinite_rise">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                        <div className="p3_loop_animation">
                            <div className="p3_infinite">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="p3_infinite">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="p3_step">
                    <div>
                        <h1 className="text-center mb-5">
                            <span>Faro</span>なら3ステップで<span>作成・公開</span>ができる！
                        </h1>
                        <ul>
                            <li className="p3_fade_animation d-flex flex-column align-items-center">
                                <h2 className="text-center mb-3">Step1</h2>
                                <AddNote />
                                <h3 className="mt-3">プロジェクトを作成</h3>
                                <p className="mt-3">
                                    サムネイル画像やタイトル、プロジェクトの<span>詳細情報</span>
                                    を入力しましょう
                                </p>
                            </li>
                            <li className="p3_fade_animation d-flex flex-column align-items-center">
                                <h2 className="text-center mb-3">Step2</h2>
                                <Light className="p3_light_svg" />
                                <h3 className="mt-3">アピールポイント</h3>
                                <p className="mt-3">
                                    自己PRをはじめ経験した<span>言語や得意な技術</span>、取得した
                                    <span>資格</span>を入力してアピールしましょう
                                </p>
                            </li>
                            <li className="p3_fade_animation d-flex flex-column align-items-center">
                                <h2 className="text-center mb-3">Step3</h2>
                                <Global />
                                <h3 className="mt-3">ポートフォリオを公開</h3>
                                <p className="mt-3">
                                    ポートフォリオ公開設定をオンにして、
                                    <span>自分のポートフォリオを公開</span>しましょう
                                </p>
                            </li>
                        </ul>
                    </div>
                </section>
                {/* プロジェクト作成紹介セクション */}
                <section className="p3_make_project_introduction">
                    <div className="p3_left_project_introduction">
                        <h1>プロジェクトの作成を手軽に</h1>
                        <img src={SiteImage} alt="イメージ画像" />
                        <p>
                            <span>直感的</span> に操作できるエディターを使用しており、
                            <br />
                            誰でも <span>簡単</span>{" "}
                            にプロジェクトの詳細情報を入力することができます
                        </p>
                        <Link className="p3_common_link" to={"/Register"}>プロジェクトを使ってみる</Link>
                    </div>
                    <div className="p3_right_project_introduction">
                        {/* 仮置きで画像を表示させるが、ここは本来動画が表示されます
                            動画再生にエラーが出た場合に、画像に置き換える事も考えている。
                        */}
                        <img src={SiteImage} alt="イメージ画像" />
                    </div>
                </section>
                {/* Faroで出来ることを３ポイントに紹介するセクション */}
                <section className="p3_faro_introduction">
                    <h1>
                        <span>Faro</span>で、出来る3つのこと
                    </h1>
                    <section className="p3_introduction_idea">
                        <div>
                            <img src={ExampleImage} alt="サイト紹介画像" />
                        </div>
                        <div>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        <div>
                            <div className="p3_target_element">
                                <h1>Point 1</h1>
                                <h2>新しいアイディアの発見</h2>
                                <p>
                                    ほかのユーザーが公開しているプロジェクトを見ることができ
                                    <br />
                                    インスピレーションを受けることができます
                                </p>
                            </div>
                            <span className="p3_target_element"></span>
                        </div>
                    </section>
                    <section className="p3_introduction_portfolio">
                        <div>
                            <img src={SiteImage} alt="イメージ画像" />
                        </div>
                        <div>
                            <div className="p3_target_element_right">
                                <h1>Point 2</h1>
                                <h2>あっという間にポートフォリオができる</h2>
                                <p>
                                    直感的なデザインで簡単にポートフォリオを作成。
                                    <br />
                                    また、自分のプロジェクトをポートフォリオに変換することも可能
                                </p>
                            </div>
                            <span className="p3_target_element"></span>
                        </div>
                    </section>
                    <section className="p3_introduction_share">
                        <div>
                            <span></span>
                            <img src={ExampleImage05} alt="" />
                        </div>
                        <div>
                            <div className="p3_target_element_second">
                                <h1>Point 3</h1>
                                <h2>シェア率の高いSNSでの共有も可能</h2>
                                <p>
                                    ほかのユーザーが公開しているプロジェクトを見ることができ
                                    <br />
                                    インスピレーションを受けることができます
                                </p>
                            </div>
                            <span className="p3_target_element"></span>
                        </div>
                    </section>
                </section>
                {/* 新機能予告セクション */}
                <section className="p3_coming_soon">
                    <div>
                        <span></span>
                        <img
                            className="p3_preview_image_animation_left"
                            src={PreviewImageLeft}
                            alt=""
                        />
                        <img
                            className="p3_preview_image_animation_right"
                            src={PreviewImageRight}
                            alt=""
                        />
                    </div>
                    <div>
                        <HandShake />
                        <h1 className="mb-5">coming soon</h1>
                        <h3 className="fw-bold mb-3">
                            ポートフォリオを作成して企業からスカウトされるチャンス
                        </h3>
                        <p>
                            Faroを通してポートフォリオを作成し、あなたのポートフォリオに興味を持った企業からスカウトが送られる機能を実装予定です
                        </p>
                    </div>
                </section>
                {/* 登録誘導セクション */}
                <section className="p3_start_up d-flex flex-column align-items-center justify-content-center">
                    <h1 className="fw-bold mb-3">今すぐ始めてみましょう</h1>
                    <p className="mb_5">
                        Faroを始めてプロジェクトを作成し、ポートフォリオとして公開してみましょう
                    </p>
                    <Link className="p3_common_link" to={"/Register"}>今すぐ始める</Link>
                </section>
            </div>
        </>
    );
}
