//コンポーネントID : o41
import React from "react";
import { Button, CloseButton, Modal } from "react-bootstrap";
import { ReactComponent as Arrow } from "../../../assets/arrowleft.svg";

export default function ModalTemplate({ size, open, setOpen, className, children, title, backClick, backdrop }) {

    function onHide() {
        setOpen(false);
    }

    return (
        <Modal
            size={size}
            className="o41_modal"
            show={open}
            onHide={onHide}
            backdrop={backdrop ? "static": true}
            keyboard={false}>
            <Modal.Header className={className}>
                <Button className={"back_btn" + (!backClick ? "_hidden" : "")} onClick={backClick}><Arrow /></Button>
                <p>{title}</p>
                <div className="modal_close_div" onClick={()=>onHide()} >
                    <CloseButton variant="white" className="close_btn m-0" />
                </div>
            </Modal.Header>
            {children}
        </Modal >
    );
}
