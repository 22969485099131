//コンポーネントID : o38
// マークアップ担当者：鶴岡

//ユーザーマイページ
//プロジェクト一覧とポートフォリオの2種類のタブがある
//初期画面は基本プロジェクト一覧から

import React, { useContext, useEffect, useState } from "react";
import SingleMenu from "../../atom/template/SingleMenu";
import ProjectCardList from "../../molecules/list/ProjectCardList";
import { UserPageItems } from "../../page/UserPage";
import ItemNone from "../../molecules/ItemNone";
import { Helmet } from "react-helmet-async";

export default function Project() {

    const { id, accountData, userPageData } = useContext(UserPageItems);
    const [projectList, setProjectList] = useState(null);

    useEffect(() => {
        if (userPageData == null) {
            return;
        } else {
            //公開設定のプロジェクトをフィルタリングする関数
            function filteredProjectList() {
                if (!accountData.Loading && accountData.data != null && accountData.data.id === parseInt(id)) {
                    return userPageData.projects.sort((a, b) => b.id - a.id);
                } else {
                    return userPageData.projects.filter((project) => project.is_public === true).sort((a, b) => b.id - a.id);
                }
            }
            setProjectList(filteredProjectList());
        }

    }, [userPageData, accountData.Loading, accountData.data, id]);
    return (
        <>
            <Helmet>
                <title>マイページ - プロジェクト｜Faro</title>
            </Helmet>
            <div className="o38_item_lists">
                <SingleMenu text="プロジェクト一覧" />
                {projectList != null && projectList.length !== 0 ? <ProjectCardList projects={projectList} /> : <ItemNone isProject id={id} />}
            </div>
        </>
    );
}
