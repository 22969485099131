import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { createContext, useCallback, useState } from "react";
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import "./App.scss";
import Init from "./app/Init.jsx";
import AccountSetting from "./components/page/AccountSetting.jsx";
import ForgetPassword from "./components/page/ForgetPassword.jsx";
import Login from "./components/page/Login";
import Register from "./components/page/Register";
import UserPage from "./components/page/UserPage.jsx";
import Error from "./components/page/error/Error.jsx";
import Contact from "./components/page/footer/Contact.jsx";
import Faq from "./components/page/footer/Faq.jsx";
import Guide from "./components/page/footer/Guide.jsx";
import TermsAndPrivacy from "./components/page/footer/TermsAndPrivacy.jsx";
import ContainerLayout from "./components/page/layout/ContainerLayout.jsx";
import DefaultLayout from "./components/page/layout/DefaultLayout.jsx";
import FullSizeLayout from "./components/page/layout/FullSizeLayout.jsx";
import Project from "./components/page/project/Project.jsx";
import PrejectCreateForm from "./components/page/project/ProjectCreateForm.jsx";
// import PasswordUpdate from "./components/organisms/password/PasswordUpdate.jsx";
import PopWindow from "./components/organisms/PopWindow.jsx";
import ResetPassword from "./components/organisms/password/ResetPassword.jsx";
import EmailVerification from "./components/page/EmailVerification.jsx";
import ResetEmail from "./components/page/ResetEmail.jsx";
// import RegisterEmailAuth from "./components/organisms/register/RegisterEmailAuth.jsx";
import { HelmetProvider } from "react-helmet-async";
import Landing from "./components/page/Landing";
import MainContent from "./components/page/MainContent";
import ProjectEditForm from "./components/page/project/ProjectEditForm";

export const AppSettings = createContext();
export default function App() {

    const [loading, setLoading] = useState(false);
    const [pops, setPops] = useState([]);

    function onPopWindowClose(id) {
        setPops(prevPops => prevPops.filter(pop => { return pop.props.id !== id }));
    }
    const setPopWindow = useCallback((msg, isError) => {
        setPops(prevPops => [...prevPops, <PopWindow key={prevPops.length} id={prevPops.length} message={msg} isError={isError} onClose={onPopWindowClose} />]);
    }, []);

    const router = createBrowserRouter(createRoutesFromElements(
        <>
            <Route element={<FullSizeLayout />}>
                <Route path="/" element={<MainContent />} />
                <Route path="/about" element={<Landing />} />
            </Route>
            <Route element={<ContainerLayout />}>
                <Route path="/register" element={<Register />} />
                <Route path="/email/reset" element={<ResetEmail />} />
                <Route path="/email/reset/verification/:token" element={<EmailVerification isReset />} />
                {/* <Route path="/email/authentication" element={<RegisterEmailAuth />} /> */}
                {/* <Route path="/email/verification/:token" element={<EmailVerification />} /> */}
                <Route path="/password/forget" element={<ForgetPassword />} />
                <Route path="/password/reset/:token" element={<ResetPassword />} />
                {/* <Route path="/password/update" element={<PasswordUpdate />} /> */}
                <Route path="/login" element={<Login />} />
            </Route>
            <Route element={<DefaultLayout />}>
                <Route path="/settings" element={<AccountSetting tab={0} />} />
                <Route path="/settings/user" element={<AccountSetting tab={0} />} />
                {/* <Route path="/settings/scout" element={<AccountSetting tab={1} />} /> */}
                <Route path="/settings/account" element={<AccountSetting tab={1} />} />
                <Route path="/user/:id" element={<UserPage tab={0} />} />
                <Route path="/user/:id/portfolio" element={<UserPage tab={1} />} />
                {/* <Route path="/user/:id/likes" element={<UserPage tab={2} />} /> */}
                {/* <Route path="/user/:id/bookmarks" element={<UserPage tab={3} />} /> */}
                <Route path="/project/create" element={<PrejectCreateForm />} />
                <Route path="/project/:id" element={<Project />} />
                <Route path="/project/:id/edit" element={<ProjectEditForm />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/guide" element={<Guide />} />
                <Route path="/faq" element={<Faq />} />
                <Route path="/privacypolicy" element={<TermsAndPrivacy />} />
                <Route path="/terms" element={<TermsAndPrivacy terms />} />
                <Route path="/error" element={<Error message={"500 Internal Server Error"} messageJa={"予期せぬエラーが発生しました"} />} />
                <Route path="/*" element={<Error message={"404 Not Found"} messageJa={"お探しのページは見つかりません"} />} />
            </Route>
        </>
    ));

    return (
        <HelmetProvider>
            <AppSettings.Provider value={{ setPopWindow }}>
                <RouterProvider router={router} />
                {pops.map((ele) => ele)}
                <Init loading={loading} setLoading={setLoading} />
            </AppSettings.Provider>
        </HelmetProvider>
    );
}
