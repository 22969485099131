import GitHub from "../assets/sns/github.png";
import Instagram from "../assets/sns/instagram.png";
import X from "../assets/sns/x.png";
import YouTube from "../assets/sns/youtube.png";
import Web from "../assets/deploy.svg";

const SNSData = {
	"GitHub": {
		"icon": GitHub,
	},
	"Instagram": {
		"icon": Instagram,
	},
	"X": {
		"icon": X,
	},
	"YouTube": {
		"icon": YouTube,
	},
	"Web": {
		"icon": Web,
	},
}
export default SNSData;
