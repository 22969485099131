import React, { useContext, useEffect, useRef, useState } from "react";
import RegisterSendAuthSuccess from "../organisms/register/RegisterSendAuthSuccess";
import axios from "../../lib/axios";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import account from "../../reducers/accountSlice";
import RegisterSendAuthError from "../organisms/register/RegisterSendAuthError";
import { ContainerSettings } from "./layout/ContainerLayout";
import { Helmet } from "react-helmet-async";

export default function EmailVerification() {

	const {token} = useParams();
	const [result,setResult] = useState(0);
    const isEmailVerifyApiCalledRef = useRef(false);

    const dispatch = useDispatch();

    const {
		setTitle,
		setImage,
		setImageAlt,
		setProgressBarSize,
	} = useContext(ContainerSettings);

	useEffect(() => {
		setProgressBarSize(null);
		setTitle("メールアドレスの認証");
		setImage(null);
		setImageAlt("メールアドレスの認証");
	}, [setTitle, setImage, setImageAlt,setProgressBarSize]);

	async function verification(uuid) {
		try {
			const response = await axios.get("/auth/email/verify/"+uuid);
            dispatch(account.slice.actions.setData(response.data));
			setResult(1);
		} catch (error) {
			setResult(2);
		}
	}

	useEffect(()=> {
        if (token != null && !isEmailVerifyApiCalledRef.current) {
            isEmailVerifyApiCalledRef.current = true;
            verification(token);
        }
	},[token]);

    function loadingPage() {
        return (
            <section className="o33_container">
                <p>
                    サーバーの応答を待機しています...
                </p>
            </section>
        );
    }

	function getPage() {
		switch (result) {
			case 0: //Loading
				return loadingPage();
			case 1: //メールアドレス認証が完了しました
				return <RegisterSendAuthSuccess />;
			case 2: //メールアドレス認証に失敗しました
				return <RegisterSendAuthError />;
			default:
				return null;
		}
	}

	return (
		<>
			<Helmet>
				<title>メールアドレス変更｜Faro</title>
			</Helmet>
			{getPage()}
		</>
	);
}
