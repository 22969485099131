//コンポーネントID : a7
//アイコンとタイトルをまとめたテンプレート
//作成者：橋本

import React from "react";

export default function SingleMenu({ required, text, imagePath, imageAlt }) {
	function require(required) {
		if (required == null || required === undefined) return null;
		return required ? (
			<span className="badge a7_required">必須</span>
		) : (
			<span className="badge a7_any">任意</span>
		);
	}
	function image(imagePath, imageAlt) {
		if (imagePath == null || imagePath === undefined) return null;
		if(React.isValidElement(imagePath)) {
			return imagePath;
		}
		return <img src={imagePath} alt={imageAlt} className="a7_img" />;
	}
	return (
		<div className="a7_box d-flex">
			{require(required)}
			{image(imagePath, imageAlt)}
			<label className="a7_label">
				{text}
			</label>
		</div>
	);
};
