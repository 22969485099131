import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { ReactComponent as Sync } from "../../assets/sync.svg";

export default function ReSendButton({ time, onClick, enableMessage, disableMessage, reSend, isAutoSend }) {

	const [visible, setVisible] = useState(false);
	const [buttonText, setButtonText] = useState(null);

	useEffect(() => {
		if (time == null || isAutoSend) {
            setVisible(true);
            return;
        }
		const hour = 60 * 60 * 1000;
		const emailVerifyLastRequest = new Date(time).getTime();
		setText(hour,emailVerifyLastRequest);
		setVisible(true);
		const countDown = setInterval(() => {
			setText(hour,emailVerifyLastRequest);
		}, 1000);
		return () => {
			clearInterval(countDown);
		};
	}, [time, setButtonText, setVisible]);

	function setText(hour,emailVerifyLastRequest) {
		const now = new Date().getTime();
		const diffTime = hour - (now - emailVerifyLastRequest);
		if (diffTime < 0) {
			setButtonText(null);
			return;
		}
		const timezoneOffset = new Date(hour - (now - emailVerifyLastRequest)).getTimezoneOffset();
		const diff = new Date((hour - (now - emailVerifyLastRequest)) + timezoneOffset * 60 * 1000);
		setButtonText(
			(diff.getHours() === 0 ? "" : diff.getHours() + "時間") +
			(diff.getMinutes() === 0 && diff.getMinutes() === 0 ? "" : diff.getMinutes() + "分") +
			diff.getSeconds() + "秒"
		);
	}

	return (
		<Button className={"m11" + (visible? " show":"")} onClick={onClick} disabled={buttonText != null}>
			{reSend&&<Sync />}
			{buttonText == null ? enableMessage : disableMessage(buttonText)}
		</Button>
	);
}
