//コンポーネントID : p31
// マークアップ担当者：鶴岡

//基本のレイアウト
//ヘッダーとフッターを合わせた状態のもの
//他のページコンポーネントと組み合わせて使用する

import React from "react";
import Footer from "../../organisms/Footer";
import Header from "../../organisms/Header";
import { ScrollRestoration } from "react-router-dom";

export default function Layout({maxWidthSet,children}) {
	return (
		<div className="p31_container">
			<Header />
			<ScrollRestoration />
			<div className={"main"+(maxWidthSet ? " adjust" : "")}>{children}</div>
			<Footer />
		</div>
	);
}
