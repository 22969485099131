import React from 'react'
import ModalTemplate from './ModalTemplate'
import { Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export default function GuestCreateProjectModal({ open, setOpen }) {

    return (
        <ModalTemplate
            open={open}
            setOpen={setOpen}
            title={"プロジェクトを作成"}
            className={"o44_modal_title"}
        >
            <Modal.Body className="o44_modal_body">
                <p>プロジェクトを作成するには<br /><span>アカウント登録</span>するか<span>ログイン</span>してください</p>
                <div className='o44_modal_link_content d-flex flex-column align-items-center'>
                    <Link className="" to={"/login"} onClick={()=>setOpen(false)}>
                        ログイン
                    </Link>
                    <Link className="" to={"/register"} onClick={() => setOpen(false)}>
                        アカウント登録
                    </Link>
                </div>
            </Modal.Body>
        </ModalTemplate>
    )
}
