//コンポーネントID : o27
// マークアップ：鶴岡

import React, { useContext, useEffect, useState } from "react";
import { ReactComponent as UserBox } from "../../../assets/user_box.svg";
import SingleMenu from "../../atom/template/SingleMenu";
import { ContainerSettings } from "../../page/layout/ContainerLayout";
import { Button } from "react-bootstrap";
import { AppSettings } from "../../../App";
import axios from "../../../lib/axios";
import account from "../../../reducers/accountSlice";
import { useDispatch, useSelector } from "react-redux";

export default function AccountNickName() {

	const AccountNickName = useSelector(state => state.account)?.data?.nickname;
	const [nickname, setNickName] = useState(AccountNickName == null ? "no name" : AccountNickName);
	const dispatch = useDispatch();

	const {
		progress,
		setProgress,
		setTitle,
		setImage,
		setImageAlt,
		nextPage,
	} = useContext(ContainerSettings);

	useEffect(() => {
		// setProgressBarSize();
		setTitle("ニックネーム登録");
		setImage(<UserBox />);
		setImageAlt("ニックネーム登録");
	}, [setTitle, setImage, setImageAlt]);

	const {
		setPopWindow,
	} = useContext(AppSettings);

	async function onSubmit(e) {
		e.preventDefault()
		try {
			const formData = new FormData();
			formData.append("body",JSON.stringify({"nickname": nickname}));
			const response = await axios.post("/api/user/edit", formData);
			dispatch(account.slice.actions.setData(response.data));
			setProgress(progress + 1);
			nextPage();
		} catch (error) {
			setPopWindow(error.response.data.error, true);
		}
	}

	return (
		<form className="o27_container" onSubmit={onSubmit}>
			<p>
				※ニックネームは企業関係者も閲覧することがあります<br />
				攻撃的な言葉や不適切な表現は使用しないでください
			</p>

			<SingleMenu text="ニックネーム" />
			<input type="text" value={nickname} onChange={(e) => setNickName(e.target.value)} />
			<Button type="submit">次へ</Button>
		</form>
	);
}
