import React from "react";

export default function Tabs({ tabs, activeTab, setActiveTab }) {
	return (
		<div className="o2_tabs d-flex justify-content-center">
			{tabs.map((tab, index) => (
				<div key={index}>
					<button
						className={activeTab === index ? "active" : ""}
						onClick={() => setActiveTab(index)}
					>
						{tab}
					</button>
				</div>
			))}
		</div>
	);
}
