//コンポーネントID : o2
//マークアップ作成者：アミン

import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AppSettings } from "../../../App";
import axios from "../../../lib/axios";
import CreateProject from "../../molecules/CreateProject";
import ProjectExplain from "../../molecules/ProjectExplain";
import Tabs from "../../molecules/Tabs";
import { useSelector } from "react-redux";
import account from "../../../reducers/accountSlice";
import { Helmet } from "react-helmet-async";

export default function ProjectCreateForm() {
    const navigate = useNavigate();
    const location = useLocation();
    const [activeTab, setActiveTab] = useState(0);//表示する子コンポーネントを管理する
    const [thumbnail, setThumbnail] = useState(null);
    const [body, setBody] = useState({
        project_name: null,
        genre_name: "",
        created_at_project: null,
        progress: 2,
        responsibility: "",
        developers: 1,
        github_link: null,
        deploy_link: null,
        is_project: true,
        is_published: true,
        is_portfolio: true,
        technologies: null,
        languages: null,
        overview: '{"root":{"children":[{"children":[],"direction":null,"format":"","indent":0,"type":"paragraph","version":1}],"direction":null,"format":"","indent":0,"type":"root","version":1}}',
    });
    const { setPopWindow } = useContext(AppSettings);

    function handleNextTab(nextTab) {
        if (body.project_name === null || body.project_name.trim() === "") {
            setBody({ ...body, project_name: "" })
        }
        setActiveTab(nextTab);
    }

    const dataSend = async () => {
        if (body.project_name === null || body.project_name.trim() === "") {
            setBody({ ...body, project_name: "" })
            setPopWindow("プロジェクトの名前を記入してください。", true);
        } else {
            //APIを叩く
            const createProjectData = new FormData();
            if (thumbnail != null) {
                createProjectData.append("thumbnail", thumbnail === "" ? null : thumbnail);
            }
            createProjectData.append("body", JSON.stringify(body));
            try {
                const response = await axios.post("/api/project/create", createProjectData);
                const createdProjectId = response.data.projectId;
                navigate(`/project/${createdProjectId}`);
                setPopWindow("保存しました。");
            } catch (error) {
                setPopWindow("保存できませんでした。", true);
            }
        }
    }

    const accountData = useSelector(account.state);

    useEffect(() => {
        if (!accountData.loading) {
            if (accountData.data == null) {
                navigate(`/login?redirect=${encodeURIComponent(location.pathname)}`);
            }
        }
    }, [accountData.data, accountData.loading, location.pathname, navigate]);

    const tabs = ["詳細", "概要"];

    return (
        <>
            <Helmet>
                <title>プロジェクト作成｜Faro</title>
            </Helmet>
            <h1 className="o2_title">プロジェクト作成</h1>
            <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
            <div className="o2_content">
                {activeTab === 0 && <CreateProject body={body} setBody={setBody} img={{ thumbnail, setThumbnail }} onNextTab={handleNextTab} />}
                {activeTab === 1 && <ProjectExplain body={body} setBody={setBody} onSend={dataSend} />}
                {/* {activeTab === 2 && <ProjectPublish body={body} setBody={setBody} onSend={dataSend} />} */}
            </div>
        </>
    );
}