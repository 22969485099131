//コンポーネントID : p20
// マークアップ担当者：鶴岡

//Q&A

import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { Helmet } from "react-helmet-async";

export default function Faq() {
    const faqItems = [
        {
            question: "Faroとはどんなアプリですか？",
            answer: "Faroとはユーザーが手軽にポートフォリオを作成・共有することができるアプリケーションです。",
        },
        {
            question: "ニックネームは本名でなければいけませんか？",
            answer: "ニックネームは本名でなくても構いません。ただし、公序良俗に反するものや他人に誤解や不快感を与えるニックネームは使用しないでください。反するニックネームが確認された場合、個別にご連絡させていただく可能性があります。",
        },
        {
            question: "肩書きとはなんですか？",
            answer: "肩書きはニックネームとは別の貴方を表す呼称です。「新米エンジニア」や「駆け出しSE」等、個性のある肩書きにしてみましょう。ですがニックネームと同じく、公序良俗に反するものや不快感を与えるものは使用しないでください。",
        },
        {
            question: "プロジェクト一覧とポートフォリオの違いを教えてください。",
            answer: "プロジェクト一覧はユーザーが作成したプロジェクトのみを閲覧できるもので、ポートフォリオは企業等に厳選したプロジェクトや自己PRを見てもらう前提で作成されているものです。",
        },
        {
            question: "プロジェクトを削除したいのですが、どこから削除することができますか？",
            answer: "プロジェクトの詳細画面の下部にある削除ボタンをクリックすることでプロジェクトを削除することができます。プロジェクトを一度削除してしまうと復元することはできません。",
        },
        {
            question: "プロジェクトを非公開にして、自分だけしか見れないようにする方法はありますか？",
            answer: "新たに公開設定機能を追加予定しております。",
        },
        {
            question: "ポートフォリオに追加したプロジェクトを非公開する方法はありますか？",
            answer: "新たに公開設定機能を追加予定しております。",
        },
        {
            question: "SNS連携の方法がわかりません。",
            answer: "ヘッダーのユーザーアイコン > アカウント設定 > 各種設定よりSNS連携が可能です。ユーザーとは関係のないSNSのリンクを設定していたり、悪意のあるデプロイ先であることが発覚した場合、個別にご連絡させていただく可能性があります。最悪の場合、アカウント停止措置をとらせていただく場合がございます。",
        },
        {
            question: "パスワードを忘れてしまいました。",
            answer: "パスワードを忘れてしまった場合はログイン画面から【パスワードを忘れた方はこちら >>】をクリックしてください。パスワード再設定用のメールを入力されたメールアドレスへ送付いたします。送信ボタンを押す前に、登録されたメールアドレスであるかどうかを確認してから送信ボタンをクリックしてください。また、パスワードは新規登録時と同じく、８文字以上大文字小文字英数字で登録してください。",
        },
        {
            question: "メールが届きません。",
            answer: "Faroからメールが届かない場合、メールアドレスが間違っているか、「ドメイン拒否設定」や「迷惑メールフィルター」の影響を受けている可能性があります。今一度ご確認ください。",
        },
        {
            question: "作成したポートフォリオを外部に自分のポートフォリオとして提出することは可能ですか？",
            answer: "可能です。新たにPDF作成機能を追加予定ですので、そちらをご活用ください。",
        },
    ];

    return (
        <>
            <Helmet>
                <title>よくある質問｜Faro</title>
            </Helmet>
            <h1 className="p20_title w-100 mb-3 fw-bold text-center">Q&A</h1>
            <Accordion className="p20_accordion_wrapper w-75 m-auto bg-transparent">
                {faqItems.map((item, index) => (
                    <Accordion.Item
                        className="bg-transparent"
                        key={index}
                        eventKey={index.toString()}
                    >
                        <Accordion.Header>{item.question}</Accordion.Header>
                        <Accordion.Body className="pt-0">{item.answer}</Accordion.Body>
                    </Accordion.Item>
                ))}
            </Accordion>
        </>
    );
}
