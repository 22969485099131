//コンポーネントID : m1
//マークアップ作成者アミン
import React from "react";
import { ListGroup } from "react-bootstrap";
import SNSData from "../../app/SNSData";

export default function AccountSNSList({sns}) {

    function showSNS(snsArray) {

        snsArray = snsArray.sort((a, b) => b.id - a.id);
        return snsArray.map(sns => {
            let snsName, url;

            switch (sns.id) {
                case 1:
                    snsName = "Web";
                    url = sns.name;
                    break;
                case 2:
                    snsName = "GitHub";
                    url = `https://github.com/${sns.name}`;
                    break;
                case 3:
                    snsName = "Instagram";
                    url = `https://www.instagram.com/${sns.name}`;
                    break;
                case 4:
                    snsName = "X";
                    url = `https://x.com/${sns.name}`;
                    break;
                case 5:
                    snsName = "YouTube";
                    url = `https://www.youtube.com/@${sns.name}`;
                    break;
                default:
                    snsName = "Web";
                    url = "";
            }

            return { snsName, url };
        });
    }
    return (
        <ListGroup horizontal className="m1_ListGroup d-flex flex-wrap">
            {showSNS(sns).map((sns, index) => (
                <ListGroup.Item key={index} className="my-2">
                    <a target="_blank" href={sns.url} rel="noopener noreferrer">
                        <img
                            className=""
                            src={SNSData[sns.snsName].icon}
                            alt={SNSData[sns.snsName]}
                        />
                    </a>
                </ListGroup.Item>
            ))}
        </ListGroup>
    );
}
